import React from "react";
import { Link } from "react-router-dom";

const ProductionDocumentsTableRow = ({
	el,
	setDocumentToEdit,
	setDocumentToDelete,
	openModal1,
	openModal2,
	deleteDocument,
}) => {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const buttonAction = (el) => {
		setDocumentToEdit(el);
		openModal1();
	};
	const buttonAction3 = (el) => {
		// console.log(">>>>el:", el);
		setDocumentToEdit(el);
		openModal2();
	};

	const buttonAction2 = (el) => {
		setDocumentToDelete(el);
		deleteDocument(el);
	};

	return (
		<tr className="fila-tabla-cuenstas">
			<td className="colTableDocuments1">{el.name}</td>
			<td className="colTableDocuments1">{el.description}</td>
			<td className="colTableDocuments1">{el.date}</td>
			<td className="colTableDocuments2">
				<Link to={"/bpw/document-production-view/" + el.id} target="_blank">
					<button className="btnViewDocuments">Ver</button>
				</Link>
			</td>

			{localUser.type === "2" && (
				<td className="colTableDocuments2">
					<button onClick={() => buttonAction(el)} className="btnEditDocuments">
						Editar
					</button>
					<button
						onClick={() => buttonAction3(el)}
						className="btnEditDocuments"
					>
						Cambio Archivo
					</button>
				</td>
			)}
			{localUser.type === "2" && (
				<td className="colTableDocuments2">
					<button
						onClick={() => buttonAction2(el)}
						className="btnDeleteDocuments"
					>
						Borrar
					</button>
				</td>
			)}
		</tr>
	);
	// } else {
	// 	const newRoute = "/bpw/document-view/" + el.id;
	// 	return (
	// 		<div>
	// 			<Redirect to={newRoute} />
	// 		</div>
	// 	);
	// }
};

export default ProductionDocumentsTableRow;
