import React, { useEffect, useState } from "react";
import Loader from "../../generalComponents/Loader";
import { storage } from "../../../config/firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { askDataKeyValue2Simple } from "../../../utils/actions/firebase/firebaseActions";
import ProgressBar from "../../generalComponents/ProgressBar";

export const PresentationsForm = ({
	// dataBase2,
	createDocument,
	updateDocument,
	documentToEdit,
	setDocumentToEdit,
	company,
	closeModal,
	setError,
	isOpenModal1,
}) => {
	const initialForm = {
		id: null,
		area: "",
		name: "",
		description: "",
		date: "",
		presentationsResource: "",
		type: "material",
		duration: null,
	};
	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	// const [areas, setAreas] = useState(dataBase2);
	const [progressLoad, setProgressLoad] = useState(-1);

	useEffect(() => {
		if (documentToEdit) {
			setForm(documentToEdit);
		} else {
			setForm(initialForm);
		}
		// setAreas(dataBase2);
	}, [documentToEdit, isOpenModal1]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeFile = (e) => {
		setLoading(true);
		const file = e.target.files[0];

		const storageRef = ref(
			storage,
			`${e.target.name}` + "/" + form.company + "/" + file.name
		);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.floor(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgressLoad(progress);

				switch (snapshot.state) {
					case "paused":
						console.log("Upload is paused");
						break;
					case "running":
						console.log("Upload is running");
						break;
				}
			},
			(error) => {
				setError(error);
				console.log(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					console.log("File available at", downloadURL);
					setForm({
						...form,
						[e.target.name]: downloadURL,
					});
				});
			}
		);
		setLoading(false);
	};

	const handleSubmit = async (e) => {
		console.log("---1--- inicia el submit -----");
		e.preventDefault();

		if (!form.name) {
			alert("Debe colocar un nombre de documento...");
			return;
		}
		if (!form.description) {
			alert("Debe llenar el cambpo de descripción del documento...");
			return;
		}

		if (!form.presentationsResource) {
			alert("Debe seleccionar un archivo para subir..");
			return;
		}
		if (!form.duration) {
			alert("Debe determinar la duración de la presentación..");
			return;
		}
		console.log("--2--- va a empezar comprobar coursesMaterial");

		const nameCollection = "coursesMaterial-" + form.company;
		const key2 = "area";
		const value2 = form.area;
		const key3 = "name";
		const value3 = form.name;
		const response = await askDataKeyValue2Simple(
			nameCollection,
			key2,
			value2,
			key3,
			value3
		);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				setError("Este Documento ya existe");
			} else {
				setLoading(true);
				const fechita = new Date();
				const actualDate = fechita.toLocaleDateString();
				form.date = actualDate;
				if (form.id === null) {
					createDocument(form);
				} else {
					updateDocument(form);
				}
			}
		}

		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = () => {
		setForm(initialForm);
		setDocumentToEdit(null);
	};

	const handleClose = () => {
		console.log("está en el close");
		handleReset();
		closeModal();
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo1">
					{documentToEdit
						? "Editar Presentación"
						: "Agregar Nueva Presentación"}
				</h3>
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="title2-account">Empresa: {company}</h2>
					{loading ? (
						<Loader />
					) : (
						<div>
							<input
								type="text"
								name="name"
								placeholder="Nombre del Documento"
								onChange={handleChange}
								value={form.name}
								className="input0"
							/>
							<input
								type="text"
								name="description"
								placeholder="Descripción del Documento"
								onChange={handleChange}
								value={form.description}
								className="input0"
							/>
							<input
								type="number"
								name="duration"
								placeholder="Duracion (Minutos)"
								onChange={handleChange}
								value={form.duration}
								className="input0"
							/>

							{documentToEdit ? (
								<div>{/* <p>{form.resource}</p> */}</div>
							) : (
								<div>
									{/* {(progressLoad === -1 || progressLoad === 100) && ( */}
									<input
										type="file"
										// accept=".pdf"
										accept=".pdf, .mp4"
										name="presentationsResource"
										id=""
										value={form.resource}
										onChange={handleChangeFile}
										className="btnLoadFile"
									/>
									{/* )} */}
									{(progressLoad > 0) & (progressLoad < 100) && (
										<ProgressBar
											bgcolor={"green"}
											fontcolor={"white"}
											progress={progressLoad}
											height={"30px"}
										/>
									)}
									{progressLoad !== 100 ? (
										progressLoad >= 0 && (
											<p className="loadingFile">Cargando...{progressLoad} %</p>
										)
									) : (
										<p className="loadedFile">Archivo Cargado</p>
									)}
								</div>
							)}
							<hr />
							{(progressLoad === -1 || progressLoad === 100) && (
								<div>
									<input
										type="submit"
										value="Enviar"
										className="btn-send-reg-asign"
									/>
									<input
										type="reset"
										value="Limpiar"
										onClick={handleReset}
										className="btn-clean-reg-asign"
									/>
									<input
										type="reset"
										value="Cerrar"
										onClick={handleClose}
										// onClick={closeModal}
										className="btn-close-reg-asign"
									/>
								</div>
							)}
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
