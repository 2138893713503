import React, { useEffect, useState } from "react";
import Loader from "../../generalComponents/Loader";
import { updateDocumentById } from "../../../utils/actions/firebase/firebaseActions";
import { ViewEvaluationQuestion } from "./ViewEvaluationQuestion";

export const ViewEvaluationForm = ({
	questionsList,
	answersList,
	setSelectedCourse,
	quizToEdit,
	selectedCourse,
}) => {
	const company = localStorage.getItem("usuariosEmpresa");
	const [loading, setLoading] = useState(false);
	const [totalQuestions, setTotalQuestions] = useState(null);
	const fechita = new Date();
	const actualDate = fechita.toLocaleDateString();

	const [form, setForm] = useState(quizToEdit);
	const [answersQuiz, setAnswersQuiz] = useState([]);
	const [calificationQuiz, setCalificationQuiz] = useState([]);
	const [finalButtonVisible, setFinalButtonVisible] = useState(false);

	useEffect(() => {
		console.log("questionsList", questionsList);
		setTotalQuestions(questionsList.length);
		setForm(quizToEdit);
		form.questions = extractQuestions();
	}, [questionsList, selectedCourse]);

	const extractQuestions = () => {
		let extract = [];
		questionsList.map((quest) => extract.push(quest.question));
		return extract;
	};

	const changeAnswers = (answer, calification) => {
		answersQuiz.push(answer);
		calificationQuiz.push(calification);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		form.answers = answersQuiz;
		form.califications = calificationQuiz;
		let sumCalifications = 0;
		form.califications.map(
			(nota) => (sumCalifications = sumCalifications + nota)
		);

		if (totalQuestions !== 0) {
			form.totalCalification = (sumCalifications * 100) / totalQuestions;
		}
		await updateDocumentById("coursesQuizzes-" + company, quizToEdit.id, form);
		let newSelectedCourse = selectedCourse;
		if (form.totalCalification > newSelectedCourse.calification) {
			newSelectedCourse.calification = form.totalCalification;
			newSelectedCourse.califiactionDAte = actualDate;
			await updateDocumentById(
				"coursesAsignment-" + company,
				selectedCourse.id,
				newSelectedCourse
			);
		}
		setSelectedCourse(null);
		setLoading(false);
	};

	return (
		<div>
			<div className="navbar">
				<h2>Evaluación del curso</h2>
			</div>
			<div>
				<form onSubmit={handleSubmit}>
					{loading ? (
						<Loader />
					) : (
						<div>
							<div className="containerEvaluation">
								{totalQuestions && (
									<div>
										<ViewEvaluationQuestion
											totalQuestions={totalQuestions}
											questionsList={questionsList}
											answersList={answersList}
											answersQuiz={answersQuiz}
											changeAnswers={changeAnswers}
											setFinalButtonVisible={setFinalButtonVisible}
										/>
									</div>
								)}
							</div>
							{console.log("finalButtonVisible:", finalButtonVisible)}
							{finalButtonVisible && (
								<div>
									<input
										type="submit"
										value="Terminar"
										className="btn-send-reg-asign"
									/>
								</div>
							)}
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
