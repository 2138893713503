import React, { useEffect, useState } from "react";
import { read, utils, writeFile } from "xlsx";
import Message from "../../../../generalComponents/Message";
import { LoadMasterInventoryTable } from "./loadMasterInventoryTable";

export const LoadMasterInventoryApp = ({ setLoadInventory }) => {
	const [inventoryToRec, setInventoryToRec] = useState([]);
	const [error, setError] = useState(null);
	const goBack = () => {
		setLoadInventory(false);
	};

	// useEffect(() => {
	// 	(async () => {
	// 		/* Download file */
	// 		const f = await (
	// 			await fetch("https://sheetjs.com/inventoryToRec.xlsx")
	// 		).arrayBuffer();
	// 		console.log("f:", f);
	// 		const wb = read(f); // parse the array buffer
	// 		console.log("wb: ", wb);
	// 		const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
	// 		console.log("ws:", ws);
	// 		const data = utils.sheet_to_json(ws); // generate objects
	// 		setInventoryToRec(data); // update state
	// 	})();
	// }, []);

	const handleChangeFile = async (e) => {
		const file = e.target.files[0];
		console.log("file: ", file);
		const f = await file.arrayBuffer();
		console.log("cargado f: ", f);
		const wb = read(f); // parse the array buffer
		console.log("wb: ", wb);
		const ws = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
		console.log("ws:", ws);
		const data = utils.sheet_to_json(ws); // generate objects
		let dataToRec = [];
		let listRef = [];
		data.map((dataLine) => {
			let refInventory = "";
			let description = "";
			let features = "";
			let measureUnit = "";

			if (dataLine.refInventory) {
				refInventory = dataLine.refInventory;
				if (listRef.includes(refInventory)) {
					setError(
						"Por favor corregir el archivo, Existen Referencias repetidas..."
					);

					return;
				} else {
					listRef.push(refInventory);
				}
			} else {
				setError("Por favor corregir el archivo, hay referencias inválidas");

				return;
			}
			if (dataLine.description) {
				description = dataLine.description;
			} else {
				setError("Por favor corregir el archivo, hay descripciones inválidas");

				return;
			}

			if (dataLine.features) {
				features = dataLine.features;
			}
			if (dataLine.measureUnit) {
				measureUnit = dataLine.measureUnit;
			}
			let lineToRec = {
				refInventory: refInventory,
				description: description,
				features: features,
				measureUnit: measureUnit,
			};
			dataToRec.push(lineToRec);
		});
		setInventoryToRec(dataToRec); // update state
	};

	const handleSubmit = (e) => {
		e.preventDefault();
	};

	return (
		<div>
			<button onClick={goBack} className="btn-create-document">
				Regresar
			</button>
			{/* {inventoryToRec && console.log("inventoryToRec: ", inventoryToRec)} */}
			{error ? (
				<Message mensaje={error} bgColor="#dc3545" />
			) : !inventoryToRec.length > 0 ? (
				<form onSubmit={handleSubmit} className="form-procesos">
					<input
						type="file"
						accept=".xlsx"
						name="resource"
						id=""
						// value={form.resource}
						onChange={handleChangeFile}
						className="input0"
					/>
				</form>
			) : (
				<LoadMasterInventoryTable inventoryToRec={inventoryToRec} />
			)}
		</div>
	);
};
