import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { useModal } from "../../generalComponents/hooks/useModal";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import { PresentationsTable } from "./PresentationsTable";
import Modal from "../../generalComponents/Modal";
import { PresentationsForm } from "./PresentationsForm";
import {
	addPresentation,
	deleteDocumentById,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import { firebaseConfig } from "../../../config/firebaseConfig";
import PresentationsForm2 from "./PresentationsFrom2";

export const PresentationsApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [dataBase, setDataBase] = useState(null);
	const [documentToEdit, setDocumentToEdit] = useState(null);
	const [documentToDelete, setDocumentToDelete] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const askDocs = async () => {
		setLoading(true);
		const q = query(collection(db, "coursesMaterial-" + company));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askDocs();
		// askAreas();
	}, []);

	const createDocument = async (data) => {
		data.company = company;
		console.log("ingresó a crear el documento con data: ", data);
		setLoading(true);
		const response = await addPresentation(data);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		setLoading(false);
	};

	const updateDocument = async (data) => {
		setLoading(true);
		const nameCollection = "coursesMaterial-" + company;
		const docId = data.id;
		const changes = data;
		await updateDocumentById(nameCollection, docId, changes);
		setLoading(false);
	};

	const deleteDocument = async (data) => {
		const nameCollection = "coursesMaterial-" + company;
		const docId = data.id;
		let isDelete = window.confirm(`¿Estás seguro de eliminar este Documento?`);
		if (isDelete) {
			setLoading(true);
			setError("");
			await deleteDocumentById(nameCollection, docId)
				.then((res) => {
					console.log("respuesta", res);
				})
				.catch((error) => {
					console.log("error", error);
					setError(error);
				});
			setError(null);
			setLoading(false);
		} else {
			return;
		}
	};

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);
	const [isOpenModal2, openModal2, closeModal2] = useModal(false);

	const clickCrear = () => {
		// setDocumentToEdit(null);
		openModal1();
	};
	return (
		<div>
			<article>
				<h2 className="titleProcess">Material de Cursos </h2>
				<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
					<PresentationsForm
						// dataBase2={dataBase2}
						createDocument={createDocument}
						updateDocument={updateDocument}
						documentToEdit={documentToEdit}
						setDocumentToEdit={setDocumentToEdit}
						company={company}
						closeModal={closeModal1}
						setError={setError}
						isOpenModal1={isOpenModal1}
					/>
				</Modal>
				<Modal isOpen={isOpenModal2} closeModal={closeModal2}>
					<PresentationsForm2
						updateDocument={updateDocument}
						documentToEdit={documentToEdit}
						setDocumentToEdit={setDocumentToEdit}
						company={company}
						closeModal={closeModal2}
						setError={setError}
					/>
				</Modal>
				{loading && <Loader />}
				{error && <Message mensaje={error} bgColor="#dc3545" />}
				<button onClick={clickCrear} className="btn-create-document">
					Nuevo Material
				</button>
				{dataBase ? (
					<div>
						<PresentationsTable
							documents={dataBase}
							setDocumentToEdit={setDocumentToEdit}
							setDocumentToDelete={setDocumentToDelete}
							deleteDocument={deleteDocument}
							openModal1={openModal1}
							openModal2={openModal2}
						/>
					</div>
				) : (
					<h3 className="alertEmpty">No hay Documentos para mostrar</h3>
				)}
			</article>
		</div>
	);
};
