import React, { useEffect, useState } from "react";
import { useModal } from "../../generalComponents/hooks/useModal";
import Modal from "../../generalComponents/Modal";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import {
	addDataKey2,
	askDataKeyValue,
	askDataKeyValue2Simple,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import { QuestionsForm } from "./QuestionsForm";
import { ViewQuestions } from "./ViewQuestions";

export const ViewEvaluation = ({ setViewEvaluation }) => {
	const [db, setDb] = useState(null); //preguntas de evaluación
	const [questionToEdit, setQuestionToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [irActivity, setIrActivity] = useState(false);
	const [refresh, setRefresh] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const nameEvaluation = localStorage.getItem("nameEvaluation");

	const goEvaluation = () => {
		setViewEvaluation(false);
	};

	const askQuestions = async () => {
		setLoading(true);
		const nameCollecion = "coursesEvaluationQuestions-" + company;
		const key2 = "nameEvaluation";
		const value2 = nameEvaluation;
		const response = await askDataKeyValue(nameCollecion, key2, value2);
		if (response.statusResponse === true) {
			setDb(response.data);
			setLoading(false);
		} else {
			setDb(null);
			setError(response.error);
			setLoading(false);
		}
	};

	useEffect(() => {
		setDb(null);
		askQuestions();
		setRefresh(false);
	}, [refresh]);

	const createQuestion = async (data) => {
		setLoading(true);
		const nameCollection = "coursesEvaluationQuestions-" + company;
		const key2 = "nameEvaluation";
		const key3 = "question";
		const value2 = nameEvaluation;
		const value3 = data.question;
		const response = await addDataKey2(
			nameCollection,
			data,
			key2,
			value2,
			key3,
			value3
		);
		// const response = await addEtapa(data);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		askQuestions();
		setLoading(false);
	};

	const updateQuestion = async (data) => {
		setLoading(true);
		const nameCollection = "coursesEvaluationQuestions-" + company;
		const docId = data.id;
		const changes = data;
		const key2 = "nameEvaluation";
		const value2 = data.nameEvaluation;
		const key3 = "question";
		const value3 = data.question;
		const response = await askDataKeyValue2Simple(
			nameCollection,
			key2,
			value2,
			key3,
			value3
		);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				setError("Esta pregunta ya existe");
				setLoading(false);
			} else {
				const questionName01 = questionToEdit.question;

				if (questionName01 !== value3) {
					const response2 = await askDataKeyValue2Simple(
						"coursesEvaluationAnswers-" + company,
						key2,
						value2,
						key3,
						questionName01
					);
					if (response2.statusResponse === true) {
						const answersTomodify = response2.data;
						answersTomodify.map(async (activity) => {
							activity.question = value3;
							await updateDocumentById(
								"coursesEvaluationAnswers-" + company,
								activity.id,
								activity
							);
						});
					}
				}
				await updateDocumentById(nameCollection, docId, changes);
				askQuestions();
				setLoading(false);
			}
		} else {
			askQuestions();
			setLoading(false);
		}
	};

	const clickCreate = () => {
		setQuestionToEdit(null);
		openModal1();
	};

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);
	return (
		<div>
			<hr />
			<button className="btnReturnEvaluations" onClick={() => goEvaluation()}>
				👈 Regresar a Evaluaciones
			</button>
			<hr />
			<h3 className="titulo1">EVALUACION: {nameEvaluation}</h3>

			<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
				<QuestionsForm
					createQuestion={createQuestion}
					updateQuestion={updateQuestion}
					setQuestionToEdit={setQuestionToEdit}
					questionToEdit={questionToEdit}
					company={company}
					nameEvaluation={nameEvaluation}
					closeModal={closeModal1}
				/>
			</Modal>
			{loading ? (
				<Loader />
			) : (
				<div>
					{error && <Message mensaje={error} bgColor="#dc3545" />}
					{db ? (
						<div>
							<button onClick={clickCreate} className="btn-crear-proceso">
								Crear Pregunta
							</button>
							<ViewQuestions
								questions={db}
								setQuestionToEdit={setQuestionToEdit}
								openModal1={openModal1}
								// setIrActivity={setIrActivity}
							/>
						</div>
					) : (
						<h3>No hay preguntas para mostrar</h3>
					)}
				</div>
			)}
		</div>
	);
};
