import React, { useState, useEffect } from "react";
import Loader from "../../generalComponents/Loader";

export const QuestionsForm = ({
	createQuestion,
	updateQuestion,
	setQuestionToEdit,
	questionToEdit,
	company,
	nameEvaluation,
	closeModal,
}) => {
	// let long = 1;
	// if (db != null) {
	// 	long = db.length + 1;
	// }
	const initialForm = {
		id: null,
		nameEvaluation: nameEvaluation,
		question: "",
		// position: long,
	};

	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (questionToEdit) {
			setForm(questionToEdit);
		} else {
			setForm(initialForm);
		}
	}, [questionToEdit]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!form.question) {
			alert("Debe colocar una Pregunta...");
			return;
		}

		setLoading(true);
		if (form.id === null) {
			createQuestion(form);
		} else {
			updateQuestion(form);
		}

		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setQuestionToEdit(null);
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo1">
					{questionToEdit
						? "Editar Pregunta"
						: "Agregar Nueva Pregunta a la Evaluación"}
				</h3>
				<h3 className="titulo2">
					{questionToEdit &&
						"ALERTA: Solo se recomienda editar una Pregunta mientras se está creando, de lo contrario estaría creando una nueva Pregunta."}
				</h3>
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="title2-account">{company}</h2>
					<h2 className="title2-account">Evaluación: {nameEvaluation}</h2>
					{loading ? (
						<Loader />
					) : (
						<div>
							<input
								type="text"
								name="question"
								placeholder="Pregunta"
								onChange={handleChange}
								value={form.question}
								className="input0"
							/>

							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={closeModal}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
