import React, { useState } from "react";
import { RequestsTableRow } from "./requestsTableRow";

export const RequestsTable = ({
	data,
	adminRequests,
	localUser,
	openModal2,
	setDocumentToEdit,
}) => {
	const [filters, setFilters] = useState({
		aprovalStatus: "",
		userUpdate: "",
		dateCreation: "",
		request: "",
		employeeDocument: "",
		employeeName: "",
		commentAproval: "",
	});

	let newData = data;
	if (!adminRequests) {
		newData = data.filter((line) => line.userCreation === localUser.email);
	}

	function handleSearch(e, columnName) {
		const { value } = e.target;
		setFilters((prevFilters) => ({
			...prevFilters,
			[columnName]: value.toLowerCase(),
		}));
	}
	const filteredData = newData.filter((item) =>
		Object.entries(filters).every(([key, value]) =>
			item[key].toLowerCase().includes(value)
		)
	);
	return (
		<div>
			<h3 className="titulo1">Lista de Solicitudes</h3>
			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						<th className="titleSearchColumn01">
							<label className="titulo14">Fecha</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.dateCreation}
								onChange={(e) => handleSearch(e, "dateCreation")}
							/>
						</th>
						{adminRequests && (
							<th className="titleSearchColumn01">
								<label className="titulo14">Solicitante</label>
								<input
									className="searchColumn"
									type="text"
									value={filters.employeeName}
									onChange={(e) => handleSearch(e, "employeeName")}
								/>
							</th>
						)}
						<th className="titleSearchColumn01">
							<label className="titulo14">Solicitud</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.request}
								onChange={(e) => handleSearch(e, "request")}
							/>
						</th>
						<th className="titleSearchColumn01">
							<label className="titulo14">Respuesta</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.commentAproval}
								onChange={(e) => handleSearch(e, "commentAproval")}
							/>
						</th>
						<th className="titleSearchColumn01">
							<label className="titulo14">Estado</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.aprovalStatus}
								onChange={(e) => handleSearch(e, "aprovalStatus")}
							/>
						</th>
						{adminRequests && <th className="titleTableDocuments03">Acción</th>}
					</tr>
				</thead>
				<tbody>
					{newData.length > 0 ? (
						filteredData.map((el) => (
							<RequestsTableRow
								key={el.id}
								el={el}
								openModal2={openModal2}
								setDocumentToEdit={setDocumentToEdit}
								adminRequests={adminRequests}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
