import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import Select from "react-select";
import {
	query,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../../../config/firebaseConfig";
import Loader from "../../../../generalComponents/Loader";
import { InventoryReceiptTable } from "./inventoryReceiptTable";
import { receiptInventory } from "../../../../../utils/actions/firebase/firebaseActions";
import Message from "../../../../generalComponents/Message";

export const InventoryReceiptApp = ({ setOption }) => {
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const initialForm = {
		id: null,
		recievedProducts: [],
		date: null,
		user: localUser.email,
		docNumber: null,
		provider: null,
		totalValue: 0,
	};

	const initialRecievedProduct = {
		product: null,
		amount: 0,
		unitCost: 0,
		position: 0,
	};

	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [form, setForm] = useState(initialForm);
	const [productToAdd, setProductToAdd] = useState(initialRecievedProduct);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [masterInventory, setMasterInventory] = useState(null);
	const [selectInventory, setSelectInventory] = useState(null);

	const askMasterInventory = async () => {
		setLoading(true);
		const nameCollection = "inventoryMaster-" + company;
		const q = query(collection(db, nameCollection));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setMasterInventory(dataList);
				convertSelect(dataList);
				setLoading(false);
			},
			(error) => {
				setMasterInventory(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askMasterInventory();
		setProductToAdd(initialRecievedProduct);
	}, []);

	const convertSelect = (inventory) => {
		let selectInventoryProv = [];
		inventory.map((item) => {
			const lineInventory = {
				value: item.refInventory,
				label: item.refInventory + " - " + item.description,
				id: item.id,
				measureUnit: item.measureUnit,
				description: item.description,
				generalFeatures: item.features,
			};
			selectInventoryProv.push(lineInventory);
		});
		setSelectInventory(selectInventoryProv);
	};

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (form.recievedProducts.length === 0) {
			alert("No ha ingresado ningún producto...");
			return;
		}
		if (form.totalValue === 0) {
			alert("El costo total no puede ser  $0....");
			return;
		}
		let totalInvoice = 0;

		form.recievedProducts.map((product) => {
			totalInvoice = totalInvoice + product.amount * product.unitCost;
		});
		const recReceiptInventory = async () => {
			setLoading(true);
			const response = await receiptInventory(form, company);
			if (response.statusResponse) {
				handleReset();
				setOption(null);
				alert("Se actualizó el inventario del Almacén General.....");
			} else {
				setError(response.error);
			}
			setLoading(false);
		};
		if (totalInvoice != form.totalValue) {
			let askContinue = window.confirm(
				`El valor total de la Factura o Remisión es diferente al total de productos ingresados. Desea garabar así el ingreso?`
			);
			if (askContinue) {
				recReceiptInventory();
			} else {
				return;
			}
		} else {
			recReceiptInventory();
		}
	};

	const handleReset = (e) => {
		setForm(initialForm);
	};

	const handleSelectChange = (e) => {
		setProductToAdd({
			...productToAdd,
			product: e,
			position: form.recievedProducts.length,
		});
	};

	const handleChangeProductAdd = (e) => {
		setProductToAdd({
			...productToAdd,
			[e.target.name]: parseFloat(e.target.value),
		});

		// if (e.target.name === "amount") {
		// 	setProductToAdd({
		// 		...productToAdd,
		// 		[e.target.name]: parseInt(e.target.value),
		// 	});
		// } else {
		// 	setProductToAdd({
		// 		...productToAdd,
		// 		[e.target.name]: parseFloat(e.target.value),
		// 	});
		// }
	};

	const addProduct = () => {
		if (productToAdd.product === null) {
			alert("debe seleccionar un producto...");
			return;
		}
		if (productToAdd.amount === 0) {
			alert("debe colocar una cantidad de producto...");
			return;
		}
		if (productToAdd.unitCost === 0) {
			alert("dewbe ingresar un costo unitario...");
			return;
		}
		form.recievedProducts.push(productToAdd);
		setProductToAdd(initialRecievedProduct);
	};

	const eraseProduct = (index) => {
		if (form.recievedProducts.length === 1) {
			setForm({
				...form,
				recievedProducts: [],
			});
		} else {
			const res = form.recievedProducts.filter(
				(item) => item.position != index
			);
			setForm({
				...form,
				recievedProducts: res,
			});
		}
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo11">Ingreso de Almacén</h3>
				{error && <Message />}

				<form onSubmit={handleSubmit} className="form-inventory">
					{loading ? (
						<Loader />
					) : (
						<div>
							<label className="titulo10">
								Datos de de la Factura ó Remisión
							</label>
							<table className="tabla-inventory">
								<thead>
									<tr>
										<th className="tabla-column-etiqueta">Proveedor</th>
										<th className="tabla-column-etiqueta">
											Factura ó Remisión
										</th>
										<th className="tabla-column-etiqueta">
											Total de la Factura ó Remisión [$]
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											<input
												required
												type="text"
												name="provider"
												placeholder="Identificación del Proveedor"
												onChange={handleChange}
												value={form.provider}
												className="input0"
											/>
										</td>
										<td>
											<input
												required
												type="text"
												name="docNumber"
												placeholder="Documento"
												onChange={handleChange}
												value={form.docNumber}
												className="input0"
											/>
										</td>
										<td>
											<input
												required
												type="number"
												step="0.1"
												min="0"
												name="totalValue"
												placeholder="Total"
												onChange={handleChange}
												value={form.totalValue}
												className="input0"
											/>
										</td>
									</tr>
								</tbody>
							</table>

							<hr className="hrWhite" />
							<label className="titulo10">Productos a ingresar</label>
							{form.recievedProducts.length <= 0 ? (
								<article className="titulo11">
									...No hay productos Adicionados...
								</article>
							) : (
								<InventoryReceiptTable
									productsList={form.recievedProducts}
									eraseProduct={eraseProduct}
								/>
							)}
							<hr className="hrWhite" />

							<label className="titulo10">Adicionar productos</label>
							<table className="tabla-inventory">
								<thead>
									<tr>
										<th className="tabla-column-etiqueta">Referencia</th>
										<th className="tabla-column-etiqueta">Cantidad</th>
										<th className="tabla-column-etiqueta">Valor</th>
										<th className="tabla-column-etiqueta"></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="col-tabla-procesos1-">
											<Select
												className="input1_3"
												options={selectInventory}
												name="refProductionProduct"
												onChange={handleSelectChange}
												placeholder="Seleccione un producto"
											/>
										</td>
										<td className="col-tabla-procesos1-">
											<input
												required
												type="number"
												min="0"
												name="amount"
												placeholder="Cantidad"
												onChange={handleChangeProductAdd}
												value={productToAdd.amount}
												className="input0"
											/>
										</td>
										<td className="col-tabla-procesos2-">
											<input
												required
												type="number"
												step="0.1"
												min="0"
												name="unitCost"
												placeholder="Costo Unitario"
												onChange={handleChangeProductAdd}
												value={productToAdd.unitCost}
												className="input0"
											/>
										</td>
										<td>
											<input
												// type="submit"
												value="Adicionar"
												className="btnShowPermisions"
												onClick={addProduct}
											/>
										</td>
									</tr>
								</tbody>
							</table>
							<hr className="hrWhite" />
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
