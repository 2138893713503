import React from "react";

export const AuthorizationsTableRow = ({
	el,
	openModal1,
	setDocumentToEdit,
	setShowEmploye,
	setNewAprovalSataus,
}) => {
	const buttonActionAutorizer = (el) => {
		setNewAprovalSataus("Autorizado");
		setDocumentToEdit(el);
		openModal1();
	};
	const buttonActionReject = (el) => {
		setNewAprovalSataus("Rechazado");
		setDocumentToEdit(el);
		openModal1();
	};

	return (
		<tr className="fila-tabla-cuenstas">
			<td className="colTableDocuments0">{el.dateInitial}</td>
			<td className="colTableDocuments2">{el.dateFinal}</td>
			<td className="colTableDocuments1">{el.employeeName}</td>
			<td className="colTableDocuments1">{el.reason}</td>

			<td className="colTableDocuments2">
				<a href={el.Doc_Novedad} target="_blank" rel="noreferrer">
					<button className="btnViewDocuments">Ver</button>
				</a>
			</td>
			<td className="colTableDocuments2">
				<button
					onClick={() => buttonActionAutorizer(el)}
					className="btnViewDocuments"
				>
					Autorizar
				</button>
				<button
					onClick={() => buttonActionReject(el)}
					className="btnDeleteDocuments"
				>
					Negar
				</button>
				{/* <a href={el.Doc_Novedad} target="_blank" rel="noreferrer">
					<button className="btnViewDocuments">Ver</button>
				</a> */}
			</td>
		</tr>
	);
};
