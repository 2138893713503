import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../../config/firebaseConfig";
import Loader from "../../../generalComponents/Loader";
import Message from "../../../generalComponents/Message";

import { TimeClocksTable } from "./timeClocksTable";

export const TimeClocksApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [dataBase, setDataBase] = useState(null); //listado relojes activos
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const askActiveClocks = async () => {
		setLoading(true);
		const nameCollection = "assignments-" + company;
		let q = null;
		if (localUser.type === "2") {
			q = query(
				collection(db, nameCollection),
				where("statusActingTime", "==", "si")
			);
		}
		if (localUser.type === "3") {
			q = query(
				collection(db, nameCollection),
				where("statusActingTime", "==", "si"),
				where("agency", "==", localUser.agency),
				where("area", "==", localUser.area)
			);
		}

		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askActiveClocks();
	}, []);

	// async function updateInventory(data) {
	// 	setLoading(true);
	// 	const docId = data.id;
	// 	const changes = data;
	// 	const key = "refInventory";
	// 	const value = data.refInventory;
	// 	const response = await askDataKeyValue(nameCollection, key, value);
	// 	if (response.statusResponse === true) {
	// 		if (response.data.length > 0) {
	// 			if (response.data[0].id != docId) {
	// 				setError("Esta Referencia ya existe en el inventario");
	// 			} else {
	// 				await updateDocumentById(nameCollection, docId, changes);
	// 			}
	// 		}
	// 	}
	// 	setLoading(false);
	// }

	// async function deleteInventory(data) {
	// 	const docId = data.id;
	// 	let isDelete = window.confirm(
	// 		`¿Estás seguro de eliminar este Item del inventario ?`
	// 	);
	// 	if (isDelete) {
	// 		setLoading(true);
	// 		setError("");
	// 		await deleteDocumentById(nameCollection, docId)
	// 			.then((res) => {
	// 				console.log("respuesta", res);
	// 			})
	// 			.catch((error) => {
	// 				console.log("error", error);
	// 				setError(error);
	// 			});
	// 		setError(null);
	// 		setLoading(false);
	// 	} else {
	// 		return;
	// 	}
	// }

	return (
		<div>
			<article>
				{/* <p>Aquí se gestionan listas de Inventario</p> */}
				<article className="titulo11">Relojes Activos ({company})</article>

				{loading ? (
					<Loader />
				) : (
					<div>
						{error && <Message mensaje={error} bgColor="#dc3545" />}
						{dataBase ? (
							dataBase.length >= 0 ? (
								<div>
									{/* <button onClick={clickCrear} className="btn-create-document">
										Nuevo Item de Inventario
									</button> */}
									<TimeClocksTable
										data={dataBase}
										setLoading={setLoading}
										setError={setError}
										user={localUser.email}
										company={company}
									/>
								</div>
							) : (
								<h3 className="alertEmpty">
									No hay referencia creadas para mostrar
								</h3>
							)
						) : (
							<h3 className="alertEmpty">Buscando...</h3>
						)}
					</div>
				)}
			</article>
		</div>
	);
};
