import React, { useState } from "react";
import {
	addData,
	askData,
	askDataSimple,
} from "../../../../../utils/actions/firebase/firebaseActions";
import Message from "../../../../generalComponents/Message";
import { LoadMasterInventoryTableRow } from "./loadMasterInventoryTableRow";

export const LoadMasterInventoryTable = ({ inventoryToRec }) => {
	const company = localStorage.getItem("usuariosEmpresa");
	const [finishRec, setFinishRec] = useState(false);

	const [finalMessage, setFinalMessage] = useState("");

	const recInventory = async () => {
		console.log("**** grabar: ", inventoryToRec);
		const nameCollection = "inventoryMaster-" + company;
		let inventoryInDB = await askDataSimple(nameCollection);
		let refsInDB = [];
		let repeated = 0;
		inventoryInDB.data.map((lineInventory) => {
			refsInDB.push(lineInventory.refInventory);
		});
		await Promise.all(
			inventoryToRec.map(async (lineInventoryToRec) => {
				if (refsInDB.includes(lineInventoryToRec.refInventory)) {
					repeated = repeated + 1;
				} else {
					await addData(nameCollection, lineInventoryToRec);
				}
			})
		);
		let finalMessage =
			"Terminó de grabar en la Base de datos, habían " +
			repeated +
			" referencias repetidas";
		setFinalMessage(finalMessage);
		setFinishRec(true);
	};

	return (
		<div>
			{!finishRec ? (
				<div>
					<button onClick={recInventory} className="btn-create-document">
						Grabar
					</button>
					<article className="titulo2">
						Por favor verificar que el inventario está bien y enviar para la
						base de datos
					</article>
					<table className="tabla-procesos">
						<thead>
							<tr className="fila-tabla-procesos">
								<th className="titleTableDocuments03">Referencia</th>
								<th className="titleTableDocuments02">Descripción</th>
								<th className="titleTableDocuments02">Características</th>
								<th className="titleTableDocuments03">Unidad</th>
								{/* <th className="titleTableDocuments03">Acción</th> */}
							</tr>
						</thead>
						<tbody>
							{inventoryToRec.length > 0 ? (
								inventoryToRec.map((el) => (
									<LoadMasterInventoryTableRow key={el.id} el={el} />
								))
							) : (
								<tr>
									<td colSpan="3">Sin datos</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
			) : (
				<div>
					<Message mensaje={finalMessage} bgColor="#79b4d9" />
				</div>
			)}
		</div>
	);
};
