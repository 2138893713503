import React from "react";
import imagen from "../../generalComponents/images/logoBPW.png";

export const ProductionManagementInitial = () => {
	return (
		<div>
			<div className="containerIcon">
				<h2> ADMINISTRACIÓN DE PROCESOS </h2>
				<br />
				<br />
				<br />
				<img src={imagen} className="imgStart" />
			</div>
		</div>
	);
};
