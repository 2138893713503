import React, { useEffect, useState } from "react";
import { AsignationTableRow } from "./AsignationTableRow";

export const AsignationTable = ({
	studentCourses,
	setCourseAsignmentToEdit,
	openModal2,
	deleteAsignment,
}) => {
	const [data, setData] = useState(studentCourses);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	useEffect(() => {
		setData(studentCourses);
	}, [studentCourses]);

	function handleSearch(e) {
		if (e.target.value === "") {
			return setData(studentCourses);
		}
		try {
			setData(
				studentCourses.filter(
					(item) =>
						item.course.toLowerCase().includes(e.target.value) ||
						item.status.toLowerCase().includes(e.target.value) ||
						item.nameStudent.toLowerCase().includes(e.target.value) ||
						item.student.toLowerCase().includes(e.target.value)
				)
			);
		} catch (error) {}
	}

	return (
		<div>
			{/* {console.log("data", data)} */}
			<div className="document-search">
				<h2 className="title-search">Buscar</h2>
				<input
					type="search"
					name=""
					id=""
					onChange={handleSearch}
					className="search-box"
				/>
			</div>
			<h3 className="titulo1">LISTA DE CURSOS</h3>
			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						{/* <th className="titleTableDocuments01">Area</th> */}
						<th className="titleTableDocuments02">Curso</th>
						<th className="titleTableDocuments02">Nombre</th>
						<th className="titleTableDocuments02">Correo</th>
						<th className="titleTableDocuments02">Fecha1</th>
						<th className="titleTableDocuments02">Fecha2</th>
						<th className="titleTableDocuments02">Nota</th>
						<th className="titleTableDocuments02">Intentos</th>
						<th className="titleTableDocuments02">Elaborado</th>
						<th className="titleTableDocuments02">Estado</th>
						<th className="titleTableDocuments03">Acción</th>
					</tr>
				</thead>
				<tbody>
					{data.length > 0 ? (
						data.map((el) => (
							<AsignationTableRow
								key={el.id}
								el={el}
								setCourseAsignmentToEdit={setCourseAsignmentToEdit}
								openModal2={openModal2}
								deleteAsignment={deleteAsignment}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
