import React, { useState } from "react";
import { Link } from "react-router-dom";
import imagen from "./images/logoBPW.png";

export default function NavbarBpw() {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const ModuleKeyProcess = localStorage.getItem("ModuleKeyProcess");
	const ModuleKeyDocuments = localStorage.getItem("ModuleKeyDocuments");
	const ModuleKeyCapacitation = localStorage.getItem("ModuleKeyCapacitation");
	const ModuleKeyEvaluation = localStorage.getItem("ModuleKeyEvaluation");

	const [visibleProcess, setVisibleProcess] = useState(false);

	function openProcess() {
		setVisibleProcess(true);
	}

	function closeProcess() {
		setVisibleProcess(false);
	}

	return (
		<div className="navbar">
			<div className="div01Navbar">
				<img src={imagen} className="icono" />
			</div>
			<div className="div01Navbar01">
				<Link to="/accounts" className="linkNavbar">
					Cuentas
				</Link>
			</div>
			<div className="div01Navbar01">
				<Link to="/users" className="linkNavbar">
					Usuarios
				</Link>
			</div>

			{ModuleKeyProcess === "si" && (
				<div onMouseLeave={closeProcess}>
					<div className="linkNavbar" onMouseEnter={openProcess}>
						Procesos
					</div>
					{visibleProcess === true && (
						<div className="dropdownMenu">
							<div className="div01Navbar01">
								<Link to="/processAdmin" className="linkNavbar">
									Administración
								</Link>
							</div>

							<div className="div01Navbar01">
								<Link to="/register-processAdmin" className="linkNavbar">
									Registros
								</Link>
							</div>
							<div className="div01Navbar01">
								<Link
									to="/register-process/manageArtisano01"
									className="linkNavbar"
								>
									Registros Artisano01
								</Link>
							</div>
							<div className="div01Navbar01">
								<Link to="/dataBase-Reorganizated" className="linkNavbar">
									Organizar DataBase
								</Link>
							</div>
							<div className="div01Navbar01">
								<Link to="/process-replied" className="linkNavbar">
									Copiar Proceso
								</Link>
							</div>
							<div className="div01Navbar01">
								<Link to="/process-migration-Artisano" className="linkNavbar">
									Migracion Artisano FEB 2024
								</Link>
							</div>
							<div className="div01Navbar01">
								<Link to="/bpw-version" className="linkNavbar">
									Versión
								</Link>
							</div>
						</div>
					)}
				</div>
			)}

			{ModuleKeyCapacitation === "si" && (
				<div className="div01Navbar01">
					<Link to="/admin_training_main" className="linkNavbar">
						Cursos
					</Link>
				</div>
			)}

			<div className="div01Navbar01">
				<Link to="/admin_user" className="linkNavbar">
					{/* {localUser.email} */}
					Usuario
				</Link>
			</div>

			<div className="div01Navbar01">
				<Link to="/" className="closeButton">
					Cerrar
				</Link>
			</div>
		</div>
	);
}
