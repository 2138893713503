import React from "react";
import imagen from "../../generalComponents/images/logoBPW.png";

export const GeneralAreasManagementInitial = ({ area }) => {
	return (
		<div>
			<div className="containerIcon">
				<h2> ADMINISTRACIÓN - {area} </h2>
				<br />
				<br />
				<br />
				<img src={imagen} className="imgStart" />
			</div>
		</div>
	);
};
