import React from "react";
// import imagen from "../../generalComponents/images/logoBPW.png";
import imagen from "../../generalComponents/images/logoBPW.png";

export const ListEntitiesInitial = () => {
	return (
		<div>
			<div className="containerIcon">
				<h2> Seleccione una entidad de la lista...</h2>
				<br />
				<br />
				<br />
				<img src={imagen} className="imgStart" />
				{/* <img src={imagen} /> */}
			</div>
		</div>
	);
};
