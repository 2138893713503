import React, { useState, useEffect } from "react";
import Loader from "../../../generalComponents/Loader";
import Select from "react-select";

export const RegProcessOrderForm = ({
	createRegProcess,
	company,
	agency,
	creator,
	area,
	closeModal,
	options,
	productionOrder,
	optionProducts,
}) => {
	const initialForm = {
		date: "",
		id: null,
		date1: "",
		hour1: "",
		agency: agency,
		area: area,
		creator: creator,
		processNumber: "",
		process: "",
		status: "abierto",
		dateClose: "",
		hourClose: "",
		complianceProcess: 0,
		questions: 0,
		responses: 0,
		productionOrder: productionOrder,
		refProductionProduct: null,
		quantity: 0,
		timeControl: "",
		inventoryControl: "",
		productionProduct: [],
		newOnProcess: 0,
		finishProduct: 0,
	};
	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const [selectedOption, setSelectedOption] = useState(null);
	const [selectedProduct, setSelectedProduct] = useState(null);

	useEffect(() => {
		setForm(initialForm);
	}, []);

	const handleChange = (e) => {
		if (e.target.name === "quantity") {
			setForm({
				...form,
				[e.target.name]: parseInt(e.target.value),
				newOnProcess:
					parseInt(e.target.value) + parseInt(form.productionProduct.onProcess),
			});
		} else {
			setForm({
				...form,
				[e.target.name]: e.target.value,
			});
		}
	};

	const handleNewSelect = (selOption) => {
		// console.log("opcion: ", selOption);
		setSelectedOption(selOption);
		setForm({
			...form,
			process: selOption.value,
		});
	};
	const handleNewSelect2 = (selOption) => {
		console.log("opcion: ", selOption);
		setSelectedProduct(selOption);
		setForm({
			...form,
			refProductionProduct: selOption.value,
			productionProduct: selOption.productionProduct,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		//prevenir que se recargue la página y quede incompleta la creación del proceso
		window.onbeforeunload = function () {
			return "¿Desea recargar la página web?, el proceso no terminará correctamente...";
		};

		console.log(">>> form:", form);

		if (!form.processNumber) {
			alert("Debe colocar la identificación del Proceso...");
			return;
		}
		if (form.process === "" || form.process === "Proceso") {
			alert("Debe seleccionar Proceso...");

			return;
		}
		if (
			form.refProductionProduct === "" ||
			form.refProductionProduct === "Proceso"
		) {
			alert("Debe seleccionar Producto...");

			return;
		}

		if (form.quantity <= 0) {
			alert("Cantidad de producto a elaborar debe ser mayor a cero (0)");
			return;
		}

		if (
			form.quantity >
			form.productionProduct.amount -
				form.productionProduct.finishedProduct -
				form.productionProduct.onProcess
		) {
			alert(
				"No se puede ordenar produir cantidades por encima de las cantidades orenadas descontando lo ya producido y en proceso..."
			);
			return;
		}

		setLoading(true);
		await createRegProcess(form);

		clearForm();
		setForm(initialForm);
		handleReset();
		setLoading(false);
		closeModal();

		//window.location.replace(""); //REFRESCA LA PAGINA
	};

	const clearForm = () => {
		// console.log("entró a cerrar");
		setForm(initialForm);
		setSelectedProduct(null);
		setSelectedOption(null);
		closeModal();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setSelectedOption(null);
	};

	return (
		<div>
			<div className="cont1">
				<h3 className="titulo1">Agregar un nuevo Proceso</h3>
				<form onSubmit={(e) => handleSubmit(e)} className="form-cuentas">
					<h2 className="titulo1">{company}</h2>
					<h2 className="titulo3">Creador: {creator}</h2>
					{loading ? (
						<Loader />
					) : (
						<div>
							<label className="titulo1">Número de Proceso</label>
							<input
								type="text"
								name="processNumber"
								placeholder="# de Proceso"
								onChange={handleChange}
								value={form.processNumber}
								className="input1"
							/>

							<div className="input1_2">
								<label className="titulo1">Nombre del proceso</label>
								<Select
									name="process"
									options={options}
									className="input1_3"
									onChange={handleNewSelect}
									placeholder="Seleccione Proceso..."
									value={selectedOption}
								/>
							</div>
							<div className="input1_2">
								<label className="titulo1">Producto</label>
								<Select
									name="refProductionProduct"
									options={optionProducts}
									className="input1_3"
									onChange={handleNewSelect2}
									placeholder="Seleccione el Producto..."
									value={selectedProduct}
								/>
							</div>
							{form.refProductionProduct && (
								<div>
									<label className="titulo1">
										Cantidad de producto a elaborar
									</label>
									<input
										type="number"
										name="quantity"
										placeholder="Cantidad de Producto"
										onChange={handleChange}
										value={form.quantity}
										className="input1"
									/>
								</div>
							)}
							<hr />

							<div>
								<label className="nameCheckbox">
									Hacer control de Tiempos ?
								</label>
								<select
									className="input3"
									name="timeControl"
									onChange={handleChange}
									value={form.timeControl}
								>
									<option defaultValue>NO</option>
									<option>SI</option>
								</select>
							</div>
							<div>
								<label className="nameCheckbox">
									Hacer control de Inventarios ?
								</label>
								<select
									className="input3"
									name="inventoryControl"
									onChange={handleChange}
									value={form.inventoryControl}
								>
									<option defaultValue value>
										NO
									</option>
									<option>SI</option>
								</select>
							</div>
							<hr />
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={clearForm}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
