import React, { useEffect, useState } from "react";
import Loader from "../generalComponents/Loader";
import "../styles/MenuProcesos.css";
import { storage } from "../../config/firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { delFile } from "../../utils/actions/firebase/firebaseActions";

const CrudDocumentsForm2 = ({
	updateDocument,
	documentToEdit,
	setDocumentToEdit,
	company,
	closeModal,
	setError,
}) => {
	const [form, setForm] = useState(documentToEdit);
	const [loading, setLoading] = useState(false);
	const [storageRef, setStorageRef] = useState(null);
	const [fileToRec, setFileToRec] = useState(null);

	useEffect(() => {
		setForm(documentToEdit);
	}, [documentToEdit]);

	const handleChangeFile = async (e) => {
		const file = e.target.files[0];

		const storageRef = ref(
			storage,
			`${e.target.name}` + "/" + company + "/" + file.name
		);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.floor(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);

				switch (snapshot.state) {
					case "paused":
						console.log("Upload is paused");
						break;
					case "running":
						console.log("Upload is running");
						break;
				}
			},
			(error) => {
				setError(error);
				console.log(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					console.log("File available at", downloadURL);
					setForm({
						...form,
						[e.target.name]: downloadURL,
					});
				});
			}
		);
	};

	const handleSelectFile = (e) => {
		setLoading(true);
		const file = e.target.files[0];
		// console.log("tamaño del archivo: ", file.size);
		if (file.size > 20000000) {
			// console.log("excede el máximo permitido");
			setError("Tamaño de archivo supera 20 MB ");
			setLoading(false);
			return;
		} else {
			setFileToRec(file);
			setError(null);
		}

		const storageRef = ref(
			storage,
			"resource/" + company + "/documents/" + file.name
		);
		setStorageRef(storageRef);
		form.fileName = file.name;

		setLoading(false);
	};

	const loadFile = async () => {
		return new Promise((resolve, reject) => {
			const uploadTask = uploadBytesResumable(storageRef, fileToRec);

			uploadTask.on(
				"state_changed",
				(snapshot) => {
					const progress = Math.floor(
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100
					);
					// setProgressLoad(progress);

					switch (snapshot.state) {
						case "paused":
							console.log("Upload is paused");
							break;
						case "running":
							console.log("Upload is running");
							break;
					}
				},
				(error) => {
					console.log(error);
					reject(error);
				},
				async () => {
					try {
						const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
						console.log("Si entra a grabar en resource-documents", downloadURL);
						form.resource = downloadURL;
						resolve(downloadURL);
					} catch (error) {
						console.error("Error al obtener la URL de descarga", error);
						reject(error);
					} finally {
						// Puedes hacer cualquier limpieza aquí si es necesario
					}
				}
			);
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!form.fileName) {
			alert("Debe seleccionar un archivo para subir..");
			return;
		}
		const nameCollection = "documents-list-" + company;
		setLoading(true);
		await delFile(documentToEdit.resource);
		try {
			await loadFile();
		} catch (error) {
			setError("Error durante la carga del archivo", error);
			return;
		}
		const fechita = new Date();
		const year = fechita.getFullYear();
		const month = fechita.getMonth() + 1; // Los meses son indexados desde 0, así que sumamos 1
		const day = fechita.getDate();
		const twoDigits = (number) => (number < 10 ? `0${number}` : number);
		const actualDate = `${year}-${twoDigits(month)}-${twoDigits(day)}`;
		form.date = actualDate;
		updateDocument(form);

		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = (e) => {
		setDocumentToEdit(null);
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo1">{documentToEdit && "Cambio de Archivo"}</h3>
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="title2-account">Empresa: {company}</h2>
					{loading ? (
						<Loader />
					) : (
						<div>
							{!form ? (
								<div>
									<p>
										No se puedo leer la base de datos, por favor cumunicarse con
										el Administrador IT
									</p>
								</div>
							) : (
								<div>
									<h2 className="title2-account">Area: {form.area}</h2>
									<h2 className="title2-account">Nombre: {form.name}</h2>
									<h2 className="title2-account">Versión: {form.version}</h2>
									<input
										type="file"
										accept=".pdf"
										name="resource"
										id=""
										value=""
										onChange={handleSelectFile}
										className="input0"
									/>
								</div>
							)}
							<hr />
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={closeModal}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
export default CrudDocumentsForm2;
