import React, { useState } from "react";
import CrudUsuariosTableRow from "./CrudUsuariosTableRow";

const CrudUsuariosTable = ({ user, setUserToEdit, deleteUser, company }) => {
	const [filters, setFilters] = useState({
		name: "",
		email: "",
		area: "",
		type: "",
	});

	function handleSearch(e, columnName) {
		const { value } = e.target;
		setFilters((prevFilters) => ({
			...prevFilters,
			[columnName]: value.toLowerCase(),
		}));
	}
	const filteredData = user.filter((item) =>
		Object.entries(filters).every(([key, value]) =>
			item[key].toLowerCase().includes(value)
		)
	);

	return (
		<div>
			<h3 className="titulo1">Lista de Usuarios de: {company}</h3>
			<table className="tabla-cuentas">
				<thead>
					<tr>
						<th className="titulo-tabla">
							<label className="titulo14">Nombre</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.name}
								onChange={(e) => handleSearch(e, "name")}
							/>
						</th>
						<th className="titulo-tabla">
							<label className="titulo14">Usuario</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.email}
								onChange={(e) => handleSearch(e, "email")}
							/>
						</th>

						<th className="titulo-tabla">
							<label className="titulo14">Tipo</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.type}
								onChange={(e) => handleSearch(e, "type")}
							/>
						</th>

						<th className="titulo-tabla">Acción</th>
					</tr>
				</thead>
				<tbody>
					{user.length > 0 ? (
						filteredData.map((el) => (
							<CrudUsuariosTableRow
								key={el.id}
								el={el}
								setUserToEdit={setUserToEdit}
								deleteUser={deleteUser}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default CrudUsuariosTable;
