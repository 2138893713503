import { initializeApp } from "@firebase/app";
import React, { useState } from "react";
import {
	getDocs,
	collection,
	addDoc,
	getFirestore,
	query,
	where,
	orderBy,
	startAfter,
	limit,
	startAt,
	doc,
	updateDoc,
} from "@firebase/firestore"; // Asegúrate de importar las funciones correctas

import NavbarBpw from "../../generalComponents/NavbarBpw";
import { firebaseConfig } from "../../../config/firebaseConfig";

export const ProcessMigrationArtisano = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [form, setForm] = useState({
		originalCollection: "",
		newCollection: "",
		excludedField: "",
	});
	const [message, setMessage] = useState("");
	const [loading, setLoading] = useState(false);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmitRespaldo = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			// Paso 1: Recuperar Datos con Filtro
			const querySnapshot = await getDocs(
				query(
					collection(db, form.originalCollection),
					where("company", "==", "Artisano Ltda")
				)
			);

			const totalDocuments = querySnapshot.size;
			const batchSize = 100; // Número de documentos por lote
			let processedDocuments = 0;

			const documents = [];

			// Iterar sobre lotes de documentos

			let lastDocumentIndex = null;
			while (processedDocuments < totalDocuments) {
				const batchQuery = query(
					collection(db, form.originalCollection),
					where("company", "==", "Artisano Ltda"),
					orderBy("id"), // Ajusta el orden según tus necesidades
					startAfter(lastDocumentIndex),
					limit(batchSize)
				);

				const batchSnapshot = await getDocs(batchQuery);

				if (batchSnapshot.size === 0) {
					// Si no hay más documentos, salir del bucle
					break;
				}

				batchSnapshot.forEach((doc) => {
					const data = doc.data();
					delete data.company;
					documents.push(data);
				});

				processedDocuments += batchSnapshot.size;

				// Obtener el último documento para usarlo en el próximo lote
				lastDocumentIndex = batchSnapshot.docs[batchSnapshot.size - 1];

				// Actualizar el indicador de progreso
				const progressPercentage = (processedDocuments / totalDocuments) * 100;
				console.log(`Progreso: ${progressPercentage.toFixed(2)}%`);
			}
			// Paso 2: Almacenar en la Nueva Colección

			for (const document of documents) {
				try {
					await addDoc(collection(db, form.newCollection), document);
				} catch (error) {
					console.log("Error al grabar:", error);
					console.log("Documento con error:", document);
					// Puedes decidir cómo manejar el error, ya sea continuar con el siguiente documento o hacer algo más
				}
			}

			setMessage(
				"Copia exitosa, había: " +
					totalDocuments +
					" se copiaron: " +
					processedDocuments
			);
			setForm({
				originalCollection: "",
				newCollection: "",
			});

			// Limpiar el mensaje después de unos segundos
			// setTimeout(() => {
			// 	setMessage("");
			// }, 3000);
		} catch (error) {
			console.error("Error al copiar la colección:", error);
			setMessage(
				"Error al copiar la colección. Consulta la consola para más detalles."
			);
		} finally {
			setLoading(false);
		}
	};

	const handleSubmit01 = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			// Paso 1: Recuperar Datos con Filtro
			const querySnapshot = await getDocs(
				query(
					collection(db, form.originalCollection),
					where("company", "==", "Artisano Ltda")
				)
			);

			const totalDocuments = querySnapshot.size;
			const batchSize = 100; // Número de documentos por lote
			let processedDocuments = 0;
			let lastDocument = null;

			// Iterar sobre lotes de documentos
			while (processedDocuments < totalDocuments) {
				const batchQuery = query(
					collection(db, form.originalCollection),
					where("company", "==", "Artisano Ltda"),
					orderBy("id"), // Ajusta el orden según tus necesidades
					startAfter(lastDocument),
					limit(batchSize)
				);

				const batchSnapshot = await getDocs(batchQuery);

				if (batchSnapshot.size === 0) {
					// Si no hay más documentos, salir del bucle
					break;
				}

				const documents = batchSnapshot.docs.map((doc) => {
					const data = doc.data();
					delete data.company;
					data.id = null;
					return data;
				});

				// Paso 2: Almacenar en la Nueva Colección
				for (const document of documents) {
					try {
						await addDoc(collection(db, form.newCollection), document).then(
							async (res) => {
								if (res.id) {
									const docRef = doc(db, form.newCollection, res.id);
									await updateDoc(docRef, {
										id: res.id,
									});
								}
							}
						);
						processedDocuments += 1;

						// Actualizar el indicador de progreso cada 10 documentos
						if (processedDocuments % 10 === 0) {
							const progressPercentage =
								(processedDocuments / totalDocuments) * 100;
							console.log(`Progreso: ${progressPercentage.toFixed(2)}%`);
							setMessage("Avace: " + progressPercentage);
						}
					} catch (error) {
						console.log("Error al grabar:", error);
						console.log("Documento con error:", document);
						// Puedes decidir cómo manejar el error, ya sea continuar con el siguiente documento o hacer algo más
					}
				}

				// Obtener el último documento para usarlo en el próximo lote
				lastDocument = batchSnapshot.docs[batchSnapshot.size - 1];
			}

			setMessage(
				`Copia exitosa, había: ${totalDocuments} se copiaron: ${processedDocuments}`
			);
			setForm({
				originalCollection: "",
				newCollection: "",
			});
		} catch (error) {
			console.error("Error al copiar la colección:", error);
			setMessage(
				"Error al copiar la colección. Consulta la consola para más detalles."
			);
		} finally {
			setLoading(false);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		try {
			setLoading(true);

			// Paso 1: Recuperar Datos con Filtro por lotes
			const batchSize = 100; // Número de documentos por lote
			let processedDocuments = 0;
			let lastDocument = null;

			// Iterar sobre lotes de documentos
			while (true) {
				const batchQuery = query(
					collection(db, form.originalCollection),
					where("company", "==", "Artisano Ltda"),
					orderBy("id"),
					startAfter(lastDocument),
					limit(batchSize)
				);

				const batchSnapshot = await getDocs(batchQuery);

				if (batchSnapshot.size === 0) {
					// Si no hay más documentos, salir del bucle
					break;
				}

				const documents = batchSnapshot.docs.map((doc) => {
					const data = doc.data();
					delete data.company;
					data.id = null;
					return data;
				});

				// Paso 2: Almacenar en la Nueva Colección
				for (const document of documents) {
					try {
						const res = await addDoc(
							collection(db, form.newCollection),
							document
						);
						if (res.id) {
							const docRef = doc(db, form.newCollection, res.id);
							await updateDoc(docRef, { id: res.id });
						}
						processedDocuments += 1;

						// Actualizar el indicador de progreso cada 10 documentos
						if (processedDocuments % 10 === 0) {
							const progressPercentage = (processedDocuments / batchSize) * 100;
							console.log(`Progreso: ${progressPercentage.toFixed(2)}%`);
							setMessage("Avance: " + progressPercentage);
						}
					} catch (error) {
						console.log("Error al grabar:", error);
						console.log("Documento con error:", document);
						// Puedes decidir cómo manejar el error, ya sea continuar con el siguiente documento o hacer algo más
					}
				}

				// Obtener el último documento para usarlo en el próximo lote
				lastDocument = batchSnapshot.docs[batchSnapshot.size - 1];
			}

			setMessage(
				`Copia exitosa, se copiaron: ${processedDocuments} documentos`
			);
			setForm({
				originalCollection: "",
				newCollection: "",
			});
		} catch (error) {
			console.error("Error al copiar la colección:", error);
			setMessage(
				"Error al copiar la colección. Consulta la consola para más detalles."
			);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div>
			{localUser.type === "1" && <NavbarBpw />}
			<article className="titulo9">Migración Artisano febrero 2024</article>
			<div style={styles.container}>
				{loading ? (
					<p style={styles.processingMessage}>Procesando...</p>
				) : (
					<form onSubmit={handleSubmit} style={styles.form}>
						<label style={styles.label}>
							Colección Original:
							<input
								type="text"
								name="originalCollection"
								value={form.originalCollection}
								onChange={handleChange}
								style={styles.input}
							/>
						</label>
						<br />
						<label style={styles.label}>
							Nueva Colección:
							<input
								type="text"
								name="newCollection"
								value={form.newCollection}
								onChange={handleChange}
								style={styles.input}
							/>
						</label>
						<br />
						<button type="submit" style={styles.button} disabled={loading}>
							Copiar Colección
						</button>
					</form>
				)}
				{message && <p style={styles.message}>{message}</p>}
			</div>
		</div>
	);
};

const styles = {
	container: {
		display: "flex",
		justifyContent: "center", // Añadido para centrar horizontalmente
		alignItems: "center",
		height: "100vh",
	},
	form: {
		background: "#f0f0f0",
		padding: "20px",
		borderRadius: "8px",
		boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
	},
	label: {
		display: "block",
		margin: "10px 0",
	},
	input: {
		width: "100%",
		padding: "10px", // Ajustado el espacio entre el borde y el texto
		borderRadius: "4px",
		border: "1px solid #ccc",
	},
	button: {
		background: "#4caf50",
		color: "#fff",
		padding: "12px", // Ajustado el espacio dentro del botón
		borderRadius: "4px",
		cursor: "pointer",
	},
	message: {
		marginTop: "20px",
		color: "#4caf50", // Cambiado el color del mensaje de éxito
	},
	processingMessage: {
		fontSize: "18px",
		color: "#333",
		fontStyle: "italic",
	},
};
