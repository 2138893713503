import React from "react";
import imagen from "../../generalComponents/images/logoBPW.png";

export const UsersFunctionsInitial = () => {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	return (
		<div>
			<div className="containerIcon">
				<h2> MENU USUARIO </h2>
				<article className="titulo9"> {localUser.email} </article>
				<br />
				<br />
				<br />
				<img src={imagen} />
			</div>
		</div>
	);
};
