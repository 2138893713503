import React from "react";
import { Redirect } from "react-router";
import NavbarBpw from "../generalComponents/NavbarBpw";

export const DataVersionApp = () => {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	return (
		<div>
			{localUser.type === "1" ? (
				<div>
					<NavbarBpw />

					<aritcle className="titulo9">
						Versión: ProductionV03 github Julio 14 2024 (las licencias no
						remuneradas pueden ser solicitadas desde cada usuario. )
					</aritcle>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};
