import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { useModal } from "../../../../generalComponents/hooks/useModal";
import Loader from "../../../../generalComponents/Loader";
import Message from "../../../../generalComponents/Message";
import { firebaseConfig } from "../../../../../config/firebaseConfig";
import Modal from "../../../../generalComponents/Modal";
import { PermisionsInventoryFormulasForm } from "./permisionsInventoryFormulasForm";
import { PermisionsInventoryFormulasTable } from "./permisionsInventoryFormulasTable";

export const PermisionsInventoryFormulasApp = ({ users }) => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const company = localStorage.getItem("usuariosEmpresa");

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [userPermisions, setUserPermisions] = useState(null);
	const [userPermisionsToEdit, setUserPermisionsToEdit] = useState(null);

	useEffect(() => {
		askPermisions();
	}, []);

	const askPermisions = () => {
		setLoading(true);
		const collectionName = "inventoryPermisions-" + company;

		const q = query(
			collection(db, collectionName),
			where("typePermision", "==", "Formulas")
		);
		onSnapshot(q, (querySnapshot) => {
			const dataList = [];
			querySnapshot.forEach((doc) => {
				dataList.push(doc.data());
			});
			setUserPermisions(dataList);
			setLoading(false);
		});
	};

	const clickCrear = () => {
		let newUserPermisions = userPermisions.filter(
			(line) => line.typePermision === "Formulas"
		);
		if (newUserPermisions.length > 0) {
			setUserPermisionsToEdit(newUserPermisions[0]);
		} else {
			setUserPermisionsToEdit(null);
		}

		openModal1();
	};

	return (
		<div>
			<article>
				<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
					<PermisionsInventoryFormulasForm
						users={users}
						userPermisionsToEdit={userPermisionsToEdit}
						setUserPermisionToEdit={setUserPermisionsToEdit}
						closeModal={closeModal1}
						company={company}
					/>
				</Modal>

				{loading && <Loader />}
				{error && <Message mensaje={error} bgColor="#dc3545" />}

				{userPermisions ? (
					userPermisions.length >= 0 ? (
						<div>
							<button onClick={clickCrear} className="btn-create-document">
								Modificar
							</button>
							{userPermisions && (
								<PermisionsInventoryFormulasTable
									userPermisions={userPermisions}
								/>
							)}
						</div>
					) : (
						<h3 className="alertEmpty">No hay Usuarios asignados</h3>
					)
				) : (
					<h3 className="alertEmpty">Buscando...</h3>
				)}
			</article>
		</div>
	);
};
