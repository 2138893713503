import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import Loader from "../../../generalComponents/Loader";
import {
	query,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import Select from "react-select";
import { firebaseConfig } from "../../../../config/firebaseConfig";
import { FormulasManagementFormTable } from "./formulasManagementFormTable";

export const FormulasManagementForm = ({
	company,
	createProductionFormula,
	updateProductionFormula,
	productionFormulaToEdit,
	setProductionFormulaToEdit,
	closeModal,
}) => {
	const initialForm = {
		id: null,
		productionFormula: "",
		product: "",
		creationDate: "",
		ingredients: [],
		status: "activo",
	};

	const ingredientData = {
		amount: 0,
		description: "",
		features: "",
		id: "",
		measureUnit: "",
		refInventory: null,
	};

	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const [inventoryMaster, setInventoryMaster] = useState(null);
	const [selectedInventoryMaster, setSelectedInventoryMaster] = useState(null);
	const [ingredientToAdd, setIngredientToAdd] = useState(ingredientData);

	const askMasterInventory = async () => {
		setLoading(true);
		const q = query(collection(db, "inventoryMaster-" + company));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setInventoryMaster(dataList);
				convertSelectProductionFormula(dataList);
				setLoading(false);
			},
			(error) => {
				setInventoryMaster(null);
				// setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askMasterInventory();
		if (productionFormulaToEdit) {
			setForm(productionFormulaToEdit);
		} else {
			setForm(initialForm);
		}
	}, [productionFormulaToEdit]);

	const convertSelectProductionFormula = (productList) => {
		let selectProductList = [];
		productList.map((item) => {
			const lineProductList = {
				value: item.refInventory,
				label:
					item.refInventory +
					" - " +
					item.description +
					" [" +
					item.measureUnit +
					"] ",
				id: item.id,
				product: item,
			};
			selectProductList.push(lineProductList);
		});
		setSelectedInventoryMaster(selectProductList);
	};

	const handleSelectMainProduct = async (e) => {
		if (!form.productionFormula) {
			alert("Debe dar un nombre a la Fórmula");
			return;
		}
		setForm({
			...form,
			product: e.product,
		});

		// await askAssignments(e.value);
	};

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeIngredientAdd = (e) => {
		setIngredientToAdd({
			...ingredientToAdd,
			[e.target.name]: parseFloat(e.target.value),
		});

		// if (e.target.name === "amount") {
		// 	setIngredientToAdd({
		// 		...ingredientToAdd,
		// 		[e.target.name]: parseInt(e.target.value),
		// 	});
		// } else {
		// 	setIngredientToAdd({
		// 		...ingredientToAdd,
		// 		[e.target.name]: parseFloat(e.target.value),
		// 	});
		// }
	};

	const handleSelectChangeIngredient = (e) => {
		console.log("qué es e:", e);
		setIngredientToAdd({
			...ingredientToAdd,
			description: e.product.description,
			features: e.product.features,
			id: e.product.id,
			measureUnit: e.product.measureUnit,
			refInventory: e.product.refInventory,
		});
	};

	const addIngredient = () => {
		if (ingredientToAdd.refInventory === null) {
			alert("debe seleccionar un producto...");
			return;
		}
		if (ingredientToAdd.amount === 0) {
			alert("debe colocar una cantidad de producto...");
			return;
		}
		const valueCompare = ingredientToAdd.refInventory;
		const compare = form.ingredients.filter(
			(line) => line.refInventory === valueCompare
		);
		console.log("compare:", compare);
		if (compare.length > 0) {
			alert("no puede repetir producto ya seleccionado...");
			return;
		}
		form.ingredients.push(ingredientToAdd);
		setIngredientToAdd(ingredientData);
	};

	const eraseIngredient = (id) => {
		if (form.ingredients.length === 1) {
			setForm({
				...form,
				ingredients: [],
			});
		} else {
			const res = form.ingredients.filter((item) => item.id != id);
			setForm({
				...form,
				ingredients: res,
			});
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (form.id === null) {
			createProductionFormula(form);
		} else {
			updateProductionFormula(form);
		}

		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setProductionFormulaToEdit(null);
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo1">
					{productionFormulaToEdit ? (
						<article>Editar Fórmula de Producción ({company})</article>
					) : (
						<article>Agregar Nueva Fórmula de producción ({company})</article>
					)}
				</h3>
				<form onSubmit={handleSubmit} className="form-procesos">
					{loading ? (
						<Loader />
					) : (
						inventoryMaster && (
							<div>
								{!form.product ? (
									selectedInventoryMaster &&
									selectedInventoryMaster.length > 0 ? (
										<div>
											<label className="titulo5">Formula de Producción</label>
											<input
												type="text"
												name="productionFormula"
												placeholder="Formula de Producción"
												onChange={handleChange}
												value={form.productionFormula}
												className="input1"
												required
											/>
											<Select
												className="input1_3"
												options={selectedInventoryMaster}
												name="refProductionProduct"
												onChange={handleSelectMainProduct}
												placeholder="Seleccione Orden de Producción"
											/>
										</div>
									) : (
										<article>
											{" "}
											No hay órdenes de producción para asignar inventario...{" "}
										</article>
									)
								) : (
									<div>
										<article className="title2-account">
											Fórmula de produción: {form.productionFormula}
										</article>
										<article className="title2-account">
											producto:
											{form.product.refInventory +
												" - " +
												form.product.description +
												" [" +
												form.product.measureUnit +
												"] "}
										</article>
										<hr className="hrWhite" />
										<label className="titulo10">Componentes del producto</label>
										{form.ingredients.length <= 0 ? (
											<article className="titulo11">
												...No hay productos Adicionados...
											</article>
										) : (
											<FormulasManagementFormTable
												ingredientsList={form.ingredients}
												eraseIngredient={eraseIngredient}
											/>
										)}
										<hr className="hrWhite" />
										<label className="titulo10">Adicionar Componentes</label>
										<table className="tabla-inventory">
											<thead>
												<tr>
													<th className="tabla-column-etiqueta">Componente</th>
													<th className="tabla-column-etiqueta">Cantidad</th>
													<th className="tabla-column-etiqueta"></th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td className="col-tabla-procesos1-">
														<Select
															className="input1_3"
															options={selectedInventoryMaster}
															name="refProductionProduct"
															onChange={handleSelectChangeIngredient}
															placeholder="Seleccione Orden de Producción"
														/>
													</td>
													<td className="col-tabla-procesos1-">
														<input
															required
															type="number"
															// step="0.01"
															min="0"
															name="amount"
															placeholder="Cantidad"
															onChange={handleChangeIngredientAdd}
															value={ingredientToAdd.amount}
															className="input0"
														/>
													</td>
													<td>
														<input
															value="Adicionar"
															className="btnShowPermisions"
															onClick={addIngredient}
														/>
													</td>
												</tr>
											</tbody>
										</table>
										<hr className="hrWhite" />
									</div>
								)}
								<input
									type="submit"
									value="Enviar"
									className="btn-send-reg-asign"
								/>
								<input
									type="reset"
									value="Limpiar"
									onClick={handleReset}
									className="btn-clean-reg-asign"
								/>
								<input
									type="reset"
									value="Cerrar"
									onClick={closeModal}
									className="btn-close-reg-asign"
								/>
							</div>
						)
					)}
				</form>
			</div>
		</div>
	);
};
