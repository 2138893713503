import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import {
	EmailAuthProvider,
	getAuth,
	updatePassword,
	reauthenticateWithCredential,
} from "firebase/auth";

import Loader from "../../generalComponents/Loader";

export const ChangePassword = () => {
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [userProvidedPassword, setUserProvidedPassword] = useState("");
	const [contrasena, setContrasena] = useState("");
	const [contrasena2, setContrasena2] = useState("");
	const [close, setClose] = useState(false);
	const [refresh, setRefresh] = useState(false);

	useEffect(() => {
		setRefresh(false);
		setUserProvidedPassword("");
		setContrasena("");
		setContrasena2("");
	}, [refresh]);

	const auth = getAuth();

	const user = auth.currentUser;

	const doLogin = async (e) => {
		e.preventDefault();

		if (contrasena.length < 6) {
			alert("La  Nueva contraseña debe tener mínimo 6 caracteres...");
			setRefresh(true);
			return;
		}
		if (contrasena !== contrasena2) {
			alert("Las contraseñas son diferentes...");
			setRefresh(true);
			return;
		}

		setLoading(true);
		const credential = EmailAuthProvider.credential(
			auth.currentUser.email,
			userProvidedPassword
		);

		// const result = await reauthenticateWithCredential(
		// 	auth.currentUser,
		// 	credential
		// );

		reauthenticateWithCredential(user, credential)
			.then(() => {
				updatePassword(user, contrasena)
					.then((response) => {
						alert("Contraseña Actualizada....");
						setClose(true);
						// Update successful.
					})
					.catch((error) => {
						alert(error.message);
						//setError(error);
						// An error ocurred
						// ...
					});
			})
			.catch((error) => {
				alert(error.message);
				setRefresh(true);
				return;
				// An error ocurred
				// ...
			});

		// updatePassword(user, contrasena)
		// 	.then((response) => {
		// 		console.log("response: ", response);
		// 		alert("Contraseña Actualizada....");
		// 		setClose(true);
		// 		// Update successful.
		// 	})
		// 	.catch((error) => {
		// 		console.log("*** ocurrió un error: ", error);
		// 		setError("Error de Autenticación...");
		// 		//setError(error);
		// 		// An error ocurred
		// 		// ...
		// 	});

		setLoading(false);
	};

	return (
		<div>
			<div className="container2">
				<h2 className="titleLogin">¿ Desea cambiar la contraseña ?</h2>
				<div className="login">
					<article className="fondo">
						<form onSubmit={(e) => doLogin(e)}>
							{loading && <Loader />}
							{error && <span className="text-danger">{error}</span>}

							<input
								type="password"
								className="form-control mb-2"
								placeholder="Contraseña actual..."
								// onChange= {e => onChange(e, 'email')}
								onChange={(text) => setUserProvidedPassword(text.target.value)}
								value={userProvidedPassword}
							/>
							<input
								type="password"
								className="form-control mb-2"
								placeholder="Ingresa Nueva contraseña..."
								// onChange= {e => onChange(e, 'email')}
								onChange={(text) => setContrasena(text.target.value)}
								value={contrasena}
							/>
							<input
								type="password"
								className="form-control mb-2"
								placeholder="Confirme la contraseña Nueva..."
								// onChange= {e => onChange(e, 'email')}
								onChange={(text) => setContrasena2(text.target.value)}
								value={contrasena2}
							/>
							{/* <h4>{mail}</h4>
						<h3>{error}</h3> */}

							<button className="buttonLogin">Cambiar contraseña</button>
						</form>
					</article>
				</div>
			</div>
			{close && <Redirect to="/" />}
		</div>
	);
};
