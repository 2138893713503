import React from "react";

export const UserPersonalData = ({ employeeData }) => {
	return (
		<div>
			{employeeData.status ? (
				<div className="containerCvLeft">
					<table className="tabla-procesos">
						<thead>
							<tr className="fila-tabla-procesos">
								<th className=""> </th>
								<th className=""> </th>
							</tr>
						</thead>
						<tbody>
							<tr className="">
								<td className="">Nombre:</td>
								<td className="">{employeeData.name}</td>
							</tr>
							<tr className="">
								<td className="">Tipo de documento:</td>
								<td className="">{employeeData.typeDocument}</td>
							</tr>
							<tr className="">
								<td className="">Número de Documento:</td>
								<td className="">{employeeData.document}</td>
							</tr>
							<tr className="">
								<td className="">Fecha de expedición documento:</td>
								<td className="">{employeeData.expeditionDate}</td>
							</tr>
							{employeeData.expirateDate !== "" && (
								<tr className="">
									<td className="">Fecha de terminación documento:</td>
									<td className="">{employeeData.expirateDate}</td>
								</tr>
							)}
							<tr className="">
								<td className="">Email:</td>
								<td className="">{employeeData.email}</td>
							</tr>
							<tr className="">
								<td className="">Teléfono:</td>
								<td className="">{employeeData.phone}</td>
							</tr>
							<tr className="">
								<td className="">Dirección:</td>
								<td className="">{employeeData.adress}</td>
							</tr>
							{employeeData.eps !== "" && (
								<tr className="">
									<td className="">EPS:</td>
									<td className="">{employeeData.eps}</td>
								</tr>
							)}
							{employeeData.arl !== "" && (
								<tr className="">
									<td className="">ARL:</td>
									<td className="">{employeeData.arl}</td>
								</tr>
							)}
							<tr className="">
								<td className="">Fondo de Pensiones:</td>
								<td className="">{employeeData.fondoPension}</td>
							</tr>
							<tr className="">
								<td className="">Fondo de Cesantías:</td>
								<td className="">{employeeData.fondoCesantias}</td>
							</tr>
							<tr className="">
								<td className="">Caja de Compensación:</td>
								<td className="">{employeeData.cajaCompensacion}</td>
							</tr>
							<tr className="">
								<td className="">Cargo:</td>
								<td className="">{employeeData.cargo}</td>
							</tr>
							<tr className="">
								<td className="">Jefe Inmediato:</td>
								<td className="">{employeeData.inmediateBoss}</td>
							</tr>
							<tr className="">
								<td className="">Fecha de Ingreso:</td>
								<td className="">{employeeData.dateIntake}</td>
							</tr>
							<tr className="">
								<td className="">Area:</td>
								<td className="">{employeeData.area}</td>
							</tr>
							<tr className="">
								<td className="">Tipo de Contrato:</td>
								<td className="">{employeeData.contractType}</td>
							</tr>
							{/* <tr className="">
								<td className="">Salario:</td>
								<td className="">{employeeData.salary}</td>
							</tr> */}
							<tr className="">
								<td className="">Fecha Terminación del Contrato:</td>
								<td className="">{employeeData.dateOut}</td>
							</tr>
							<tr className="">
								<td className="">Status:</td>
								<td className="">{employeeData.status}</td>
							</tr>

							{/* <tr className="fila-tabla-cuenstas">
						<td className="colTableDocuments0">Tipo de Documento:</td>
						<td className="colTableDocuments0">{employeeData.typeDocument}</td>
					</tr> */}
							{/* {Object.entries(employeeData).map(([key, value]) => (
						<tr key={key}>
							<td>{key}</td>
							<td>{value}</td>
						</tr>
					))} */}
						</tbody>
					</table>
				</div>
			) : (
				<article>Empesado inactivo...</article>
			)}
		</div>
	);
};
