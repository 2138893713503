import React, { useEffect, useState } from "react";
import { StudentsPendinglTableRow } from "./StudentsPendingTableRow";

export const StudentsPendingTable = ({ studentCourses, setSelectedCourse }) => {
	const [coursesInDate, setCoursesInDate] = useState(null);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const fechita1 = new Date();

	function filterDates() {
		setCoursesInDate(
			studentCourses.filter(
				(item) =>
					fechita1.valueOf() >= item.initialDate.seconds * 1000 &&
					fechita1.valueOf() <= item.finalDate.seconds * 1000
			)
		);
	}

	useEffect(() => {
		filterDates();
	}, [studentCourses]);

	return (
		<div>
			<h3 className="titulo2">Cursos pendientes por realizar...</h3>
			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						<th className="titleTableDocuments02">Curso</th>
						<th className="titleTableDocuments03">Status</th>
						<th className="titleTableDocuments03">Calificación</th>
						<th className="titleTableDocuments03">Desde</th>
						<th className="titleTableDocuments03">Hasta</th>
						<th className="titleTableDocuments03">Acción</th>
					</tr>
				</thead>
				<tbody>
					{coursesInDate && coursesInDate.length > 0 ? (
						coursesInDate.map((el) => (
							<StudentsPendinglTableRow
								key={el.id}
								el={el}
								setSelectedCourse={setSelectedCourse}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">No tiene historial de Cursos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
