import React from "react";

export const EntitiesTableRow = ({
	el,
	setEntitieToEdit,
	openModal,
	deleteEntitie,
}) => {
	const editButton = (el) => {
		setEntitieToEdit(el);
		openModal();
	};

	const deleteButton = (el) => {
		deleteEntitie(el);
		setEntitieToEdit(el);
	};
	return (
		<tr className="fila-tabla-cuenstas">
			{/* <td className="colTableDocuments0">{el.area}</td> */}
			<td className="colTableDocuments2">{el.name}</td>

			<td className="colTableDocuments2">
				<button onClick={() => editButton(el)} className="btnEditDocuments">
					Editar
				</button>

				<button onClick={() => deleteButton(el)} className="btnDeleteDocuments">
					Borrar
				</button>
			</td>
		</tr>
	);
};
