import { initializeApp } from "@firebase/app";
import React, { useState, useEffect } from "react";
import {
	query,
	onSnapshot,
	collection,
	getFirestore,
	where,
} from "@firebase/firestore";
import Loader from "../../../generalComponents/Loader";
import { useModal } from "../../../generalComponents/hooks/useModal";
import Modal from "../../../generalComponents/Modal";

import "../../../styles/HumanResources.css";
import { firebaseConfig } from "../../../../config/firebaseConfig";
import { SearchNoveltiesApp } from "../searchNoveltiesApp";
import { LicencesTable } from "./licencesTable";
import { LicerncesForm } from "./licerncesForm";

export const LicencesApp = ({ employeeData }) => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [dataBase, setDataBase] = useState(null);
	const [listEmployees, setListEmployees] = useState(null);
	const [dataBase2, setDataBase2] = useState(null);
	const [documentToEdit, setDocumentToEdit] = useState(null);
	const [documentToDelete, setDocumentToDelete] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const [showEmploye, setShowEmploye] = useState(false);
	const [listNovelties, setListNovelties] = useState(null);
	const [showSearchNovelties, setShowSearchNovelties] = useState(false);
	const [showNovelties, setShowNovelties] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);
	useEffect(() => {
		askLicencences();
	}, []);

	const askLicencences = () => {
		setLoading(true);
		const nameCollection = "noveltiesEmployees-" + company;
		const q = query(
			collection(db, nameCollection),
			where("noveltyName", "==", "Licencia no remunerada"),
			where("employeeDocument", "==", employeeData.document)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const clickCrear = () => {
		openModal1();
	};

	const clickGoSearch = () => {
		setShowSearchNovelties(true);
	};
	return (
		<div>
			{loading ? (
				<Loader />
			) : (
				<div>
					<article className="titulo9">
						Gestión de Licencias No Remuneradas
					</article>
					{showSearchNovelties ? (
						<SearchNoveltiesApp
							setShowSearchNovelties={setShowSearchNovelties}
							listEmployees={listEmployees}
							listNovelties={listNovelties}
							company={company}
							setDocumentToEdit={setDocumentToEdit}
							openModal1={openModal1}
							setShowNovelties={setShowNovelties}
							employeeData={employeeData}
							// deleteDocument={deleteDocument}
						/>
					) : (
						<div>
							<button onClick={clickCrear} className="btn-create-document">
								Solicitar Licencia
							</button>
							{/* <button onClick={clickGoSearch} className="btn-create-document">
								Buscar Novedades
							</button> */}
							<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
								<LicerncesForm
									employeeData={employeeData}
									listEmployees={listEmployees}
									listNovelties={listNovelties}
									closeModal1={closeModal1}
									user={localUser.email}
									company={company}
									documentToEdit={documentToEdit}
									setDocumentToEdit={setDocumentToEdit}
								/>
							</Modal>
							{dataBase && dataBase.length > 0 ? (
								<LicencesTable data={dataBase} />
							) : (
								<article className="titulo3">
									No hay permisos para mostrar...
								</article>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};
