import React, { useState } from "react";
import { PermisionsTableRow } from "./permisionsTableRow";

export const PermisionsTable = ({ data }) => {
	const [filters, setFilters] = useState({
		aprovalStatus: "",
		dateInitial: "",
		dateFinal: "",
		reason: "",
		employeeDocument: "",
		employeeName: "",
		// commentBoss:"",
		userAuthorizer: "",
	});

	function handleSearch(e, columnName) {
		const { value } = e.target;
		setFilters((prevFilters) => ({
			...prevFilters,
			[columnName]: value.toLowerCase(),
		}));
	}
	const filteredData = data.filter((item) =>
		Object.entries(filters).every(([key, value]) =>
			item[key].toLowerCase().includes(value)
		)
	);
	return (
		<div>
			<h3 className="titulo1">Lista de Permisos</h3>
			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						{/* <th className="titleSearchColumn01">ID</th> */}
						<th className="titleSearchColumn01">
							<label className="titulo14">Fecha Inicial</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.dateInitial}
								onChange={(e) => handleSearch(e, "dateInitial")}
							/>
						</th>
						<th className="titleSearchColumn01">
							<label className="titulo14">Fecha Final</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.dateFinal}
								onChange={(e) => handleSearch(e, "nadateFinalme")}
							/>
						</th>
						<th className="titleSearchColumn01">
							<label className="titulo14">Motivo</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.reason}
								onChange={(e) => handleSearch(e, "reason")}
							/>
						</th>
						<th className="titleSearchColumn01">
							<label className="titulo14">Jefe</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.userAuthorizer}
								onChange={(e) => handleSearch(e, "userAuthorizer")}
							/>
						</th>
						<th className="titleSearchColumn01">
							<label className="titulo14">Estado</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.aprovalStatus}
								onChange={(e) => handleSearch(e, "aprovalStatus")}
							/>
						</th>
						{/* <th className="titleSearchColumn01">
							<label className="titulo14">Estado</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.status}
								onChange={(e) => handleSearch(e, "status")}
							/>
						</th> */}
						<th className="titleTableDocuments03">Ver Anexo</th>
					</tr>
				</thead>
				<tbody>
					{data.length > 0 ? (
						filteredData.map((el) => (
							<PermisionsTableRow
								key={el.id}
								el={el}
								// setDocumentToEdit={setDocumentToEdit}
								// setDocumentToDelete={setDocumentToDelete}
								// deleteDocument={deleteDocument}
								// setShowForm={setShowForm}
								// deleteDocument={deleteDocument}
								// setShowEmploye={setShowEmploye}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
