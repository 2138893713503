import React, { useState } from "react";
import { Link } from "react-router-dom";
import { updateDocumentById } from "../../../../utils/actions/firebase/firebaseActions";
import Loader from "../../../generalComponents/Loader";

export const FormulasManagementTableRow = ({
	el,
	setProductionFormulaToEdit,
	openModal,
	deleteProductionOrder,
	setModalAvialbe,
}) => {
	const [loading, setLoading] = useState(false);
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const email = localUser.email;
	const nameCollection = "productionFormulas-" + company;

	const editButton = (el) => {
		setProductionFormulaToEdit(el);
		setModalAvialbe(true);
		openModal();
	};

	const deleteButton = (el) => {
		deleteProductionOrder(el);
	};

	const changeStatus = async (newStatus) => {
		setLoading(true);
		const changes = el;
		changes.status = newStatus;
		const docId = el.id;
		await updateDocumentById(nameCollection, docId, changes);
		setLoading(false);
	};

	const buttonChangeStatus = async () => {
		let newStatus = "";
		if (el.status === "activo") {
			newStatus = "bloqueado";
		} else {
			newStatus = "activo";
		}
		await changeStatus(newStatus);
	};

	if (loading === true) {
		return <Loader />;
	} else {
		return (
			<tr className="fila-tabla-cuenstas">
				{/* <td className="colTableDocuments0">{el.area}</td> */}
				<td className="colTableDocuments1">{el.creationDate}</td>
				<td className="colTableDocuments1">{el.productionFormula}</td>
				<td className="colTableDocuments2">
					{el.product.measureUnit} {el.product.description} -{" "}
					{el.product.refInventory}
				</td>
				<td className="colTableDocuments1">{el.status}</td>
				<td className="colTableDocuments2">
					{el.ingredients.map((item) => (
						<div>
							{item.amount} {item.measureUnit} -{item.description} -{" "}
							{item.refInventory}
						</div>
					))}
				</td>

				<td className="colTableDocuments2">
					<button onClick={() => editButton(el)} className="btnEditDocuments">
						Editar
					</button>
					{/* borrar solo se habilita para super administrador */}
					{/* <button
						onClick={() => deleteButton(el)}
						className="btnDeleteDocuments"
					>
						Borrar
					</button> */}
					{el.status === "activo" ? (
						<button
							onClick={() => buttonChangeStatus()}
							className="btnCloseRegprocesos"
						>
							Desactivar
						</button>
					) : (
						<button
							onClick={() => buttonChangeStatus()}
							className="btnOpenRegprocesos"
						>
							Activar
						</button>
					)}
				</td>
			</tr>
		);
	}
};
