import React, { useEffect, useState } from "react";
import {
	askDataKeyValue2Simple,
	askDataKeyValue3Simple,
	deleteDocumentById,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import Loader from "../../generalComponents/Loader";

const RegProcessAdminTableRow = ({ el, irVerDetalle, counter, company }) => {
	// console.log("****** que hay en el.********", el);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [questions, setQuestions] = useState(el.questions);
	const processRegister = el;

	const updateQuestions = async () => {
		if (el.questions === 0 || !el.questions || el.questions > el.responses) {
			// console.log("++***+++*** ingresó a grabar questions");
			const resp = await askDataKeyValue2Simple(
				"activitiesRegister-" + company,
				"process",
				el.process,
				"processNumber",
				el.processNumber
			);
			const activitiesList = resp.data;
			const questions1 = activitiesList.length;
			processRegister.questions = questions1;
			try {
				await updateDocumentById(
					"processRegister-" + company,
					el.id,
					processRegister
				);
				setQuestions(questions1);
			} catch (error) {
				console.log("*** erorr: ", error);
			}
		}
	};
	useEffect(() => {
		updateQuestions();
	}, []);

	const buttonAction = (el) => {
		irVerDetalle(el);
	};

	const eraseActvities = async () => {
		let isDelete = window.confirm(
			`¿Está segur@ de Borrar este Proceso? esto borrará toda información relacionada con el proceso seleccionado, etapas, actividades ...`
		);

		if (isDelete) {
			let isDeleteConfirmation = window.confirm(
				"¿C o m p l e t a m e n t e   s e g u r @ ?"
			);
			if (isDeleteConfirmation) {
				setLoading(true);
				const result = await askDataKeyValue3Simple(
					"activitiesRegister-" + company,
					"process",
					el.process,
					"area",
					el.area,
					"processNumber",
					el.processNumber
				);
				const result2 = await askDataKeyValue3Simple(
					"assignments-" + company,
					"process",
					el.process,
					"area",
					el.area,
					"processNumber",
					el.processNumber
				);
				result.data.map((fila) => {
					console.log("a borrar actividades", fila);
					deleteDocumentById("activitiesRegister-" + company, fila.id);
				});
				alert("se han borrado las Actividades para el proceso seleccionado");
				result2.data.map((fila) => {
					console.log("a borrar asgignaciones ó etapas", fila);
					deleteDocumentById("assignments-" + company, fila.id);
				});
				alert("se han borrado las Etapas para el proceso seleccionado");
				console.log("Finalmente borrar proceso registrado", el);
				deleteDocumentById("processRegister-" + company, el.id);
				const amount = el.quantity;
				console.log("amount:", amount);
				if (amount && amount > 0) {
					let nameCol = "productionProduct-" + company;
					await askDataKeyValue2Simple(
						nameCol,
						"productionOrder",
						el.productionOrder,
						"refProductionProduct",
						el.refProductionProduct
					).then(async (productionProductToModify) => {
						let pptm = productionProductToModify.data[0];
						console.log("pptm: ", pptm);
						pptm.onProcess = pptm.onProcess - amount;
						await updateDocumentById(nameCol, pptm.id, pptm);
					});
				}
				alert("se ha borrado el proceso seleccionado");
				setLoading(false);
			}
		}
	};

	if (loading === true) {
		return <Loader />;
	} else {
		return (
			<tr className="fila-tabla-cuenstas">
				<td className="col-tabla-regtarea1">{counter}</td>
				<td className="col-tabla-regtarea1">{el.date1}</td>
				{/* <td className="col-tabla-regtarea1">{el.hour1}</td> */}
				<td className="col-tabla-regtarea2">{el.processNumber}</td>
				<td className="col-tabla-regprocesos1">{el.process}</td>
				<td className="col-tabla-regtarea1">{el.area}</td>
				<td className="col-tabla-regprocesos2">{el.status}</td>
				{/* <td className="col-tabla-regprocesos2">
					<div>
						{el.status === "abierto" ? (
							<button
								onClick={() => buttonChangeStatus()}
								className="btnCloseRegprocesos"
							>
								Cerrar
							</button>
						) : (
							<button
								onClick={() => buttonChangeStatus()}
								className="btnOpenRegprocesos"
							>
								Abrir
							</button>
						)}
					</div>
				</td> */}
				<td className="col-tabla-regprocesos2">
					{Math.round(el.complianceProcess * 100)}%
				</td>
				<td className="col-tabla-regprocesos2">
					{el.responses === el.questions ? (
						<div className="responseColorFull">
							<p>{Math.round((el.responses * 100) / el.questions)}%</p>
						</div>
					) : (
						<div className="responseColorEmpty">
							<p>{Math.round((el.responses * 100) / el.questions)}%</p>
						</div>
					)}
				</td>
				<td className="col-tabla-regprocesos2">
					<button
						onClick={() => buttonAction(el)}
						className="btn-ver-regprocesos"
					>
						Ver
					</button>
					<button
						onClick={() => eraseActvities()}
						className="btn-delete-process"
					>
						Borrar
					</button>

					{/* <button
						onClick={() => buttonChangeStatus()}
						className="btnOpenRegprocesos"
					>
						{el.status === "abierto" ? "Cerrar" : "Abrir"}
					</button> */}
				</td>
			</tr>
		);
	}
};

export default RegProcessAdminTableRow;
