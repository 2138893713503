import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { useModal } from "../../../generalComponents/hooks/useModal";
import Loader from "../../../generalComponents/Loader";
import Message from "../../../generalComponents/Message";
import Modal from "../../../generalComponents/Modal";
import { firebaseConfig } from "../../../../config/firebaseConfig";
import {
	addDataKey2,
	askDataKeyValue2Simple,
	deleteDocumentById,
	updateDocumentById,
} from "../../../../utils/actions/firebase/firebaseActions";
import { EntitiesForm } from "./entitiesForm";
import { EntitiesTable } from "./entitiesTable";

export const EntitiesApp = ({ type }) => {
	// const type = "EPS";
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [dataBase, setDataBase] = useState(null); //listado ARL
	const [entitieToEdit, setEntitieToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loadInventory, setLoadInventory] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	const nameCollection = "entitiesRRHH-" + company;

	useEffect(() => {
		askEntitie();
	}, []);

	const askEntitie = async () => {
		// console.log("navegador:", navigator.geolocation);
		setLoading(true);
		const q = query(collection(db, nameCollection), where("type", "==", type));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				console.log("error", error);
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const clickCrear = () => {
		setEntitieToEdit(null);
		openModal1();
	};

	async function createEntitie(data) {
		setLoading(true);
		const response = await addDataKey2(
			nameCollection,
			data,
			"type",
			data.type,
			"name",
			data.name
		);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		await askEntitie();
		setLoading(false);
	}

	async function updateEntitie(data) {
		setLoading(true);
		const docId = data.id;
		const changes = data;
		const key = "type";
		const value = type;
		const key2 = "name";
		const value2 = data.name;
		const response = await askDataKeyValue2Simple(
			nameCollection,
			key,
			value,
			key2,
			value2
		);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				if (response.data[0].id != docId) {
					setError("Esta Entidad ya existe...");
				} else {
					await updateDocumentById(nameCollection, docId, changes);
				}
			} else {
				await updateDocumentById(nameCollection, docId, changes);
			}
		} else {
			setError(response.error);
		}
		setLoading(false);
	}

	async function deleteEntitie(data) {
		const docId = data.id;
		if (data.name === "Retiro" || data.name === "Permiso") {
			setError("No se puede Borrar este Item...");
			return;
		}
		let isDelete = window.confirm(
			`¿Estás seguro de eliminar esta Entidad... ?`
		);
		if (isDelete) {
			setLoading(true);
			setError("");
			await deleteDocumentById(nameCollection, docId)
				.then((res) => {
					console.log("respuesta", res);
				})
				.catch((error) => {
					console.log("error", error);
					setError(error);
				});
			setError(null);
			setLoading(false);
		} else {
			return;
		}
	}

	return (
		<div>
			<article>
				{/* <article className="titulo11">Listado de {type}</article> */}
				<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
					<EntitiesForm
						company={company}
						createEntitie={createEntitie}
						updateEntitie={updateEntitie}
						entitieToEdit={entitieToEdit}
						setEntitieToEdit={setEntitieToEdit}
						closeModal={closeModal1}
						type={type}
					/>
				</Modal>
				{loading ? (
					<Loader />
				) : (
					<div>
						{error && <Message mensaje={error} bgColor="#dc3545" />}
						{dataBase ? (
							dataBase.length >= 0 ? (
								<div>
									<button onClick={clickCrear} className="btn-create-document">
										Adicionar {type}
									</button>
									<EntitiesTable
										data={dataBase}
										setEntitieToEdit={setEntitieToEdit}
										deleteEntitie={deleteEntitie}
										updateEntitie={updateEntitie}
										openModal={openModal1}
										type={type}
									/>
								</div>
							) : (
								<h3 className="alertEmpty">
									No hay referencia creadas para mostrar
								</h3>
							)
						) : (
							<h3 className="alertEmpty">Buscando...</h3>
						)}
					</div>
				)}
			</article>
		</div>
	);
};
