import React, { useEffect, useState } from "react";
import {
	addData,
	askDataKeyValue,
	askDataKeyValue2Simple,
	askDataSimple,
	deleteDocumentById,
	updateDocumentById,
} from "../../../../../utils/actions/firebase/firebaseActions";
import Loader from "../../../../generalComponents/Loader";

export const ParticularTransferInTable = ({ inventoryTransfer, multiple }) => {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const company = localStorage.getItem("usuariosEmpresa");
	const [loading, setLoading] = useState(false);
	const collectionName = "inventoryParticular-" + company;
	const collectionName2 = "inventoryTransfer-" + company;
	const collectionName3 = "inventoryInTransit-" + company;
	const collectionName4 = "inventoryGeneral-" + company;

	const recieveAction = async (transfer) => {
		setLoading(true);
		console.log("transfer products:", transfer.transferProducts);
		let inventoryInTransit = null;
		let inventoryFrom = null;
		if (transfer.from === "warehouse") {
			await askDataKeyValue(collectionName3, "from", "warehouse").then(
				(result) => {
					inventoryInTransit = result.data;
				}
			);

			await askDataSimple(collectionName4).then((result) => {
				inventoryFrom = result.data;
			});
		} else {
			await askDataKeyValue2Simple(
				collectionName3,
				"from",
				transfer.from,
				"processNumber",
				transfer.fromProcessNumber
			).then((result) => {
				inventoryInTransit = result.data;
			});
			await askDataKeyValue2Simple(
				collectionName,
				"user",
				transfer.userFrom,
				"processNumber",
				transfer.fromProcessNumber
			).then((result) => {
				inventoryFrom = result.data;
			});
		}

		await askDataKeyValue2Simple(
			collectionName,
			"user",
			localUser.email,
			"processNumber",
			transfer.processNumber
		).then((result) => {
			transfer.transferProducts.map(async (trProduct) => {
				const productToRec = {
					id: null,
					amount: trProduct.amount,
					description: trProduct.description,
					refInventory: trProduct.refInventory,
					cost: trProduct.cost,
					user: localUser.email,
					processNumber: inventoryTransfer[0].processNumber,
				};
				let productList = result.data; //lista de productos en el inventario particular para el usuairo y el proceso
				if (productList.length > 0) {
					let counter = 0;
					productList.map(async (particularProduct) => {
						if (particularProduct.refInventory === trProduct.refInventory) {
							let piToModify = productList.filter(
								(item) => item.refInventory === trProduct.refInventory
							);
							//modifica el costo prorateando con lo que hay y lo que va a ingresar
							console.log("cantidad inicial: ", piToModify[0].amount);
							console.log("cantidad adicional: ", trProduct.amount);
							console.log("costo inicial: ", piToModify[0].cost);
							console.log("costo adicional: ", trProduct.cost);
							try {
								piToModify[0].cost =
									(piToModify[0].amount * piToModify[0].cost +
										trProduct.amount * trProduct.cost) /
									(piToModify[0].amount + trProduct.amount);
							} catch (error) {
								piToModify[0].cost = trProduct.cost;
							}
							console.log("costo final: ", piToModify[0].cost);
							//modifica la cantidad aumentando lo que va a recibir
							piToModify[0].amount = piToModify[0].amount + trProduct.amount;

							await updateDocumentById(
								collectionName,
								piToModify[0].id,
								piToModify[0]
							);
						} else {
							counter += 1;
						}
					});
					if (counter === productList.length) {
						// crea cada producto en el inventario destino con la cantidad transferida
						await addData(collectionName, productToRec);
					}
				} else {
					// crea cada producto en el inventario destino con la cantidad transferida
					await addData(collectionName, productToRec);
				}
				// aqui debe actualizar la cantidad de inventario en tránsito
				let iitToModify = inventoryInTransit.filter(
					(item) => item.refInventory === trProduct.refInventory
				);
				iitToModify[0].amount = iitToModify[0].amount - trProduct.amount; //indefinido amount ???
				if (iitToModify[0].amount === 0) {
					await deleteDocumentById(collectionName3, iitToModify[0].id);
				} else {
					await updateDocumentById(
						collectionName3,
						iitToModify[0].id,
						iitToModify[0]
					);
				}
				//aqui debe actulizar la canditad del inventario de procedencia from
				let ifToModify = inventoryFrom.filter(
					(item) => item.refInventory === trProduct.refInventory
				);
				ifToModify[0].amount = ifToModify[0].amount - trProduct.amount;
				if (inventoryTransfer[0].from === "warehouse") {
					await updateDocumentById(
						collectionName4,
						ifToModify[0].id,
						ifToModify[0]
					);
				} else {
					if (ifToModify[0].amount === 0) {
						await deleteDocumentById(collectionName, ifToModify[0].id);
					} else {
						await updateDocumentById(
							collectionName,
							ifToModify[0].id,
							ifToModify[0]
						);
					}
				}
			});
		});
		// Modifica el estatus en la transferencia de "pendiente" a "aceptado"
		let newTransfer = transfer;
		newTransfer.status = "aceptado";
		await updateDocumentById(collectionName2, transfer.id, newTransfer);
		setTimeout(function () {
			setLoading(false);
		}, 1000);
	};

	const rejeactAction = async (transfer) => {
		setLoading(true);

		let inventoryInTransit = null;
		if (transfer.from === "warehouse") {
			await askDataKeyValue(collectionName3, "from", "warehouse").then(
				(result) => {
					inventoryInTransit = result.data;
				}
			);
		} else {
			await askDataKeyValue2Simple(
				collectionName3,
				"from",
				transfer.from,
				"processNumber",
				transfer.fromProcessNumber
			).then((result) => {
				inventoryInTransit = result.data;
			});
		}

		//modifica el tránsito disminuyendo la cantidad no recibida
		transfer.transferProducts.map(async (trProduct) => {
			// aqui debe actualizar la cantidad de inventario en tránsito
			let iitToModify = inventoryInTransit.filter(
				(item) => item.refInventory === trProduct.refInventory
			);
			iitToModify[0].amount = iitToModify[0].amount - trProduct.amount;
			if (iitToModify[0].amount === 0) {
				await deleteDocumentById(collectionName3, iitToModify[0].id);
			} else {
				await updateDocumentById(
					collectionName3,
					iitToModify[0].id,
					iitToModify[0]
				);
			}
		});
		// modifica el estatus en la colección de transferencias, pasa de pendiente a rechazado
		let newTransfer = transfer;
		newTransfer.status = "rechazado";
		await updateDocumentById(collectionName2, transfer.id, newTransfer);
		setTimeout(function () {
			setLoading(false);
		}, 1000);
	};

	return (
		<div className={!multiple && "containerInventory04"}>
			{!multiple && (
				<article className="titulo9">
					Tiene INVENTARIO pendiente por recibir:
				</article>
			)}
			{loading ? (
				<Loader />
			) : (
				<table className="tabla-process">
					<thead>
						<tr className="fila-tabla-inventory">
							<th className="titulo-tabla-inventory">Fecha</th>
							<th className="titulo-tabla-inventory">Origen</th>
							{/* <th className="titulo-tabla-inventory">Orden</th> */}
							<th className="titulo-tabla-inventory">Etapa</th>
							<th className="titulo-tabla-inventory">Productos</th>
							<th className="titulo-tabla-inventory">Acción</th>
						</tr>
					</thead>

					<tbody>
						{inventoryTransfer.map((transfer, index) => (
							<tr key={index} className="fila-tabla-cuenstas">
								<td className="col-tabla-inventory1">{transfer.date}</td>
								<td className="col-tabla-inventory1">
									{transfer.fromProcessNumber
										? transfer.fromProcessNumber
										: "Almacén General"}
								</td>
								{/* <td className="col-tabla-inventory1">
									{transfer.productionOrder}
								</td> */}
								<td className="col-tabla-inventory2">{transfer.stage}</td>
								<td className="col-tabla-inventory2">
									{transfer.transferProducts.map((product) => (
										<h3 className="titulo4">
											{product.amount} - {product.description}
										</h3>
									))}
								</td>
								<td className="col-tabla-inventory2">
									<button
										onClick={() => recieveAction(transfer)}
										className="btn-show-stage"
									>
										Recibir
									</button>
									<button
										onClick={() => rejeactAction(transfer)}
										className="btn-delete-process"
									>
										Rechazar
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};
