import React, { useEffect, useState } from "react";
import { useModal } from "../../../generalComponents/hooks/useModal";
import Loader from "../../../generalComponents/Loader";
import Message from "../../../generalComponents/Message";
import Modal from "../../../generalComponents/Modal";
import { PermisionsHRRequestsForm } from "./permisionsHRRequestsForm";
import { PermisionsHRRequestsTable } from "./permisionsHRRequestsTable";

export const PermisionsHRRequestsApp = ({ users, userPermisions }) => {
	let requestsUserPermision = userPermisions.filter(
		(line) => line.typePermision === "RequestsView"
	);
	const company = localStorage.getItem("usuariosEmpresa");
	const [isOpenModal1, openModal1, closeModal1] = useModal(false);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [userPermisionsToEdit, setUserPermisionsToEdit] = useState(null);

	const clickCrear = () => {
		let newUserPermisions = userPermisions.filter(
			(line) => line.typePermision === "RequestsView"
		);
		if (newUserPermisions.length > 0) {
			setUserPermisionsToEdit(newUserPermisions[0]);
		} else {
			setUserPermisionsToEdit(null);
		}

		openModal1();
	};

	return (
		<div>
			<article>
				<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
					<PermisionsHRRequestsForm
						users={users}
						userPermisionsToEdit={userPermisionsToEdit}
						setUserPermisionToEdit={setUserPermisionsToEdit}
						closeModal={closeModal1}
						company={company}
					/>
				</Modal>

				{loading && <Loader />}
				{error && <Message mensaje={error} bgColor="#dc3545" />}

				{requestsUserPermision ? (
					requestsUserPermision.length >= 0 ? (
						<div>
							<button onClick={clickCrear} className="btn-create-document">
								Modificar
							</button>
							<PermisionsHRRequestsTable
								requestsUserPermision={requestsUserPermision}
							/>
						</div>
					) : (
						<h3 className="alertEmpty">No hay Usuarios asignados</h3>
					)
				) : (
					<h3 className="alertEmpty">Buscando...</h3>
				)}
			</article>
		</div>
	);
};
