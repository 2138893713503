import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	where,
	query,
	onSnapshot,
	collection,
	getFirestore,
	orderBy,
} from "@firebase/firestore";
import Select from "react-select";
import { firebaseConfig } from "../../../../../config/firebaseConfig";
import Loader from "../../../../generalComponents/Loader";
// import { ProductsToTransferTable } from "./productsToTransferTable";
import { recTransferInventoryParticular } from "../../../../../utils/actions/firebase/firebaseActions";
import { ParticularProductsToTransferTable } from "./particularProductsToTransferTable";

export const ParticularTransferOutForm = ({ aviableInventory, closeModal }) => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const initialForm = {
		id: null,
		from: localUser.email,
		to: null,
		transferProducts: [],
		date: null,
		userFrom: localUser.email,
		productionOrder: null,
		processNumber: null,
		stage: null,
		status: null,
	};

	const initialTransferProduct = {
		product: null,
		amount: 0,
		position: 0,
	};

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [productionsOrders, setProductionsOrders] = useState(null);
	const [procesRegister, setProcesRegister] = useState(null);
	const [assignments, setAssignments] = useState(null);
	const [form, setForm] = useState(initialForm);
	const [selectedOrderProduction, setSelectedOrderProduction] = useState(null);
	const [selectedProcess, setSelectedProcess] = useState(null);
	const [selectAssignments, setSelectAssignments] = useState(null);
	const [selectInventory, setSelectInventory] = useState(null);
	const [productToAdd, setProductToAdd] = useState(initialTransferProduct);
	const [selectedInitialDestination, setSelectedInitialDestination] =
		useState(null);
	const [validation, setValidation] = useState(0);

	useEffect(() => {
		askProductionOrders();
		convertSelectInventory();
		convertinitialDestination();
		setValidation(0);
	}, [aviableInventory]);

	const askProductionOrders = async () => {
		const nameCollection = "productionOrders-" + company;

		setLoading(true);
		const q = query(
			collection(db, nameCollection),
			orderBy("creationDate", "desc"),
			where("status", "==", "abierto")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setProductionsOrders(dataList);
				convertSelectProductionOrder(dataList);
				setLoading(false);
			},
			(error) => {
				console.log("error: ", error);
				setProductionsOrders(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askProcessRegister = async (productionOrder) => {
		setLoading(true);
		const q = query(
			collection(db, "processRegister-" + company),
			where("productionOrder", "==", productionOrder),
			where("inventoryControl", "==", "SI"),
			where("status", "==", "abierto")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setProcesRegister(dataList);
				convertSelectProcess(dataList);
				setLoading(false);
			},
			(error) => {
				setProcesRegister(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askAssignments = async (processNumber) => {
		setLoading(true);
		const q = query(
			collection(db, "assignments-" + company),
			where("processNumber", "==", processNumber),
			where("assignment", "!=", ""),
			where("status", "==", "abierto")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				// console.log("asignaciones: ", dataList);
				setAssignments(dataList);
				convertSelectAssignments(dataList);
				setLoading(false);
			},
			(error) => {
				setAssignments(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const convertinitialDestination = () => {
		let selectDestination = [
			{
				label: "Almacén General",
				value: "warehouse",
			},
			{
				label: "Orden de Producción",
				value: "productionOrder",
			},
		];
		setSelectedInitialDestination(selectDestination);
	};

	const convertSelectProductionOrder = (productionOrder) => {
		let selectPO = [];
		productionOrder.map((item) => {
			const linePO = {
				value: item.productionOrder,
				label: item.productionOrder,
				id: item.id,
			};
			selectPO.push(linePO);
		});
		setSelectedOrderProduction(selectPO);
	};

	const convertSelectProcess = (processRegister) => {
		let selectPR = [];
		processRegister.map((item) => {
			const linePR = {
				value: item.processNumber,
				label: item.processNumber + "--" + item.process,
				id: item.id,
			};
			selectPR.push(linePR);
		});
		setSelectedProcess(selectPR);
	};

	const convertSelectAssignments = (assignments) => {
		let selectAssignments = [];
		assignments.map((item) => {
			const lineAssignments = {
				value: item.assignment,
				label: item.stage + " -- " + item.assignment,
				stage: item,
				id: item.id,
			};
			selectAssignments.push(lineAssignments);
		});
		setSelectAssignments(selectAssignments);
	};

	const convertSelectInventory = () => {
		let selectInventoryProv = [];
		aviableInventory.map((item) => {
			const lineInventory = {
				value: item.refInventory,
				label:
					item.refInventory +
					" - " +
					item.description +
					" [ " +
					item.amount +
					" ] ",
				id: item.id,
				description: item.description,
				product: item,
				cost: item.cost,
			};
			selectInventoryProv.push(lineInventory);
		});
		setSelectInventory(selectInventoryProv);
	};

	const handleInitialDestination = (e) => {
		setForm({
			...form,
			to: e.value,
		});
	};

	const handleSelectProductionOrder = async (e) => {
		setForm({
			...form,
			productionOrder: e.value,
		});

		await askProcessRegister(e.value);
	};

	const handleSelectProcess = async (e) => {
		if (e.value === aviableInventory[0].processNumber) {
			setValidation(1);
		}
		setForm({
			...form,
			processNumber: e.value,
		});

		await askAssignments(e.value);
	};
	const handleSelectAssignments = async (e) => {
		if (validation === 1) {
			if (e.stage.assignment === localUser.email) {
				alert("No puede transferir a si mismo dentro del mismo proceso...");
				return;
			}
		}
		setForm({
			...form,
			stage: e.stage,
		});

		// await askAssignments(e.value);
	};

	const handleSelectChangeProduct = (e) => {
		setProductToAdd({
			...productToAdd,
			product: e,
			position: form.transferProducts.length,
		});
	};

	const handleChangeProductAdd = (e) => {
		if (e.target.name === "amount") {
			setProductToAdd({
				...productToAdd,
				[e.target.name]: parseInt(e.target.value),
			});
		} else {
			setProductToAdd({
				...productToAdd,
				[e.target.name]: parseFloat(e.target.value),
			});
		}
	};

	const addProduct = () => {
		if (productToAdd.product === null) {
			alert("debe seleccionar un producto...");
			return;
		}
		if (productToAdd.amount === 0) {
			alert("debe colocar una cantidad de producto...");
			return;
		}
		if (productToAdd.amount > productToAdd.product.product.amount) {
			alert("No puede transferir más unidades que las que posee...");
			return;
		}

		const valueCompare = productToAdd.product.value;
		const compare = form.transferProducts.filter(
			(line) => line.product.value === valueCompare
		);
		if (compare.length > 0) {
			alert("no puede repetir producto ya seleccionado...");
			return;
		}

		form.transferProducts.push(productToAdd);
		setProductToAdd(initialTransferProduct);
	};

	const handleReset = (e) => {
		setForm(initialForm);
	};

	const handleClose = (e) => {
		setForm(initialForm);
		closeModal();
	};

	const eraseProduct = (index) => {
		if (form.transferProducts.length === 1) {
			setForm({
				...form,
				transferProducts: [],
			});
		} else {
			const res = form.transferProducts.filter(
				(item) => item.position != index
			);
			setForm({
				...form,
				transferProducts: res,
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (form.transferProducts.length === 0) {
			alert("No ha ingresado ningún producto...");
			setLoading(false);
			return;
		}
		const originProcessNumber = aviableInventory[0].processNumber;

		const response = await recTransferInventoryParticular(
			form,
			company,
			originProcessNumber
		);
		if (response.statusResponse) {
			handleReset();
			alert("Se ha grabado correctamente la transferencia...");
		} else {
			setError(response.error);
		}
		closeModal();
		window.location.replace(""); //REFRESCA LA PAGINA

		setLoading(false);
	};

	return (
		<div>
			<form onSubmit={handleSubmit} className="form-procesos">
				<h2 className="title2-account">
					Transferencia desde proceso número: ***
					{aviableInventory[0].processNumber} ***
				</h2>
				{loading ? (
					<Loader />
				) : (
					<div>
						{!form.to ? (
							<Select
								className="input1_3"
								options={selectedInitialDestination}
								onChange={handleInitialDestination}
								placeholder="Seleccione Destino"
							/>
						) : (
							<div>
								{form.to === "warehouse" ? (
									<div>
										<article className="titulo10">
											Trasferir A: Almacén General
										</article>
										<div>
											<hr className="hrWhite" />
											<label className="titulo10">Productos a Transferir</label>
											{form.transferProducts.length <= 0 ? (
												<article className="titulo11">
													...No hay productos Adicionados...
												</article>
											) : (
												<ParticularProductsToTransferTable
													productsList={form.transferProducts}
													eraseProduct={eraseProduct}
												/>
											)}
											<hr className="hrWhite" />

											<label className="titulo10">Adicionar productos</label>
											<table className="tabla-inventory">
												<thead>
													<tr>
														<th className="tabla-column-etiqueta">
															Referencia
														</th>
														<th className="tabla-column-etiqueta">Cantidad</th>
														<th className="tabla-column-etiqueta"></th>
													</tr>
												</thead>
												<tbody>
													<tr>
														<td className="col-tabla-procesos1-">
															<Select
																className="input1_3"
																options={selectInventory}
																name="refProductionProduct"
																onChange={handleSelectChangeProduct}
																placeholder="Seleccione un producto"
															/>
														</td>
														<td className="col-tabla-procesos1-">
															<input
																required
																type="number"
																min="0"
																name="amount"
																placeholder="Cantidad"
																onChange={handleChangeProductAdd}
																value={productToAdd.amount}
																className="input0"
															/>
														</td>
														<td>
															<input
																// type="submit"
																value="Adicionar"
																className="btnShowPermisions"
																onClick={addProduct}
															/>
														</td>
													</tr>
												</tbody>
											</table>
											<hr className="hrWhite" />
										</div>
									</div>
								) : (
									<div>
										<article className="titulo10">
											Trasferir A: Orden de Producción
										</article>
										{!form.productionOrder ? (
											selectedOrderProduction &&
											selectedOrderProduction.length > 0 ? (
												<Select
													className="input1_3"
													options={selectedOrderProduction}
													name="refProductionProduct"
													onChange={handleSelectProductionOrder}
													placeholder="Seleccione Orden de Producción de destino"
												/>
											) : (
												<article>
													No hay órdenes de producción para asignar
													inventario...
												</article>
											)
										) : (
											<div>
												<article className="titulo10">
													Orden de Producción: {form.productionOrder}
												</article>
												{!form.processNumber ? (
													selectedProcess && selectedProcess.length > 0 ? (
														<Select
															className="input1_3"
															options={selectedProcess}
															name="refProductionProduct"
															onChange={handleSelectProcess}
															placeholder="Seleccione el Proceso"
														/>
													) : (
														<article className="titulo2">
															No hay Procesos asociados a esta Orden de
															producción...
														</article>
													)
												) : (
													<div>
														<article className="titulo10">
															Proceso: {form.processNumber}
														</article>
														{!form.stage ? (
															selectAssignments &&
															selectAssignments.length > 0 ? (
																<Select
																	className="input1_3"
																	options={selectAssignments}
																	name="refProductionProduct"
																	onChange={handleSelectAssignments}
																	placeholder="Seleccione la Etapa"
																/>
															) : (
																<article className="titulo2">
																	No hay etapas asignadas en este proceso...
																</article>
															)
														) : (
															<div>
																<article className="titulo10">
																	Etapa: {form.stage.stage}
																</article>
																<article className="titulo10">
																	Asignado a: {form.stage.assignment}
																</article>
															</div>
														)}
													</div>
												)}
											</div>
										)}

										{form.stage && (
											<div>
												<hr className="hrWhite" />
												<label className="titulo10">
													Productos a Transferir
												</label>
												{form.transferProducts.length <= 0 ? (
													<article className="titulo11">
														...No hay productos Adicionados...
													</article>
												) : (
													<ParticularProductsToTransferTable
														productsList={form.transferProducts}
														eraseProduct={eraseProduct}
													/>
												)}
												<hr className="hrWhite" />

												<label className="titulo10">Adicionar productos</label>
												<table className="tabla-inventory">
													<thead>
														<tr>
															<th className="tabla-column-etiqueta">
																Referencia
															</th>
															<th className="tabla-column-etiqueta">
																Cantidad
															</th>
															<th className="tabla-column-etiqueta"></th>
														</tr>
													</thead>
													<tbody>
														<tr>
															<td className="col-tabla-procesos1-">
																<Select
																	className="input1_3"
																	options={selectInventory}
																	name="refProductionProduct"
																	onChange={handleSelectChangeProduct}
																	placeholder="Seleccione un producto"
																/>
															</td>
															<td className="col-tabla-procesos1-">
																<input
																	required
																	type="number"
																	min="0"
																	name="amount"
																	placeholder="Cantidad"
																	onChange={handleChangeProductAdd}
																	value={productToAdd.amount}
																	className="input0"
																/>
															</td>
															<td>
																<input
																	// type="submit"
																	value="Adicionar"
																	className="btnShowPermisions"
																	onClick={addProduct}
																/>
															</td>
														</tr>
													</tbody>
												</table>
												<hr className="hrWhite" />
											</div>
										)}
									</div>
								)}
							</div>
						)}
						<div>
							<hr />
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={handleClose}
								className="btn-close-reg-asign"
							/>
						</div>
					</div>
				)}
			</form>
		</div>
	);
};
