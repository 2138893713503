import React, { useEffect, useState } from "react";
import { ProcessOrdersTableRow } from "./processOrdersTableRow";

export const ProcessOrdersTable = ({
	data,
	setProductionOrderToEdit,
	deleteProductionOrder,
	openModal,
	setVerOrder,
	setProductionOrderToShow,
}) => {
	const [showData, setShowData] = useState(data);

	useEffect(() => {
		setShowData(data);
	}, [data]);

	function handleSearch(e) {
		if (e.target.value === "") {
			return setShowData(data);
		}
		setShowData(
			data.filter(
				(item) =>
					item.productionOrder.toLowerCase().includes(e.target.value) ||
					item.client.toLowerCase().includes(e.target.value)
			)
		);
	}

	return (
		<div>
			<div className="document-search">
				<h2 className="title-search">Buscar</h2>
				<input
					type="search"
					name=""
					id=""
					onChange={handleSearch}
					className="search-box"
				/>
			</div>
			<h3 className="titulo1">Lista de Ordenes de Producción</h3>
			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						<th className="titleTableDocuments03">Orden de Producción</th>
						<th className="titleTableDocuments03">Cliente</th>
						<th className="titleTableDocuments03">Fecha de creación</th>
						<th className="titleTableDocuments03">Fecha Prometida</th>
						<th className="titleTableDocuments03">Acción</th>
					</tr>
				</thead>
				<tbody>
					{showData.length > 0 ? (
						showData.map((el) => (
							<ProcessOrdersTableRow
								key={el.id}
								el={el}
								setProductionOrderToEdit={setProductionOrderToEdit}
								openModal={openModal}
								deleteProductionOrder={deleteProductionOrder}
								setVerOrder={setVerOrder}
								setProductionOrderToShow={setProductionOrderToShow}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
