import React, { useEffect, useState } from "react";
import Loader from "../../generalComponents/Loader";
import { updateDocumentById } from "../../../utils/actions/firebase/firebaseActions";
import Message from "../../generalComponents/Message";

export const RequestsEditForm = ({
	employeeData,
	closeModal2,
	user,
	documentToEdit,
	setDocumentToEdit,
	company,
}) => {
	const [form, setForm] = useState(documentToEdit);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		setForm(documentToEdit);
	}, [documentToEdit]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		const fechita = new Date();
		const year = fechita.getFullYear();
		const month = fechita.getMonth() + 1; // Los meses son indexados desde 0, así que sumamos 1
		const day = fechita.getDate();
		const twoDigits = (number) => (number < 10 ? `0${number}` : number);
		const actualDate = `${year}-${twoDigits(month)}-${twoDigits(day)}`;
		const nameCollection = "requestsEmployees-" + company;
		form.userUpdate = user;
		form.dateUpdate = actualDate;
		updateDocumentById(nameCollection, form.id, form);
		setLoading(false);
		closeModal2();
	};

	const closeForm = () => {
		setForm(documentToEdit);
		closeModal2();
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo1">
					{documentToEdit ? "Editar Solicitud" : "Hacer Solicitud"}
				</h3>
				<form onSubmit={handleSubmit} className="formRH">
					{loading ? (
						<Loader />
					) : (
						form && (
							<div>
								<div className="containerFormRH">
									<label className="humanLabel0">Empleado *</label>
									<label className="humanInput0">{form.employeeName}</label>
									<br />
									<label className="humanLabel0">Solicitud *</label>
									<label className="humanInput0">{form.request}</label>
									<br />
									<label className="humanLabel0">
										Respuesta a la Solicitud *
									</label>
									<input
										type="text"
										name="commentAproval"
										placeholder="Respuesta a la Solicitud"
										onChange={handleChange}
										value={form.commentAproval}
										className="humanInput0"
									/>
									<br />
									<label className="humanLabel0">
										Respuesta a la Solicitud *
									</label>
									<select
										name="aprovalStatus"
										onChange={handleChange}
										value={form.aprovalStatus}
										className="input1"
									>
										<option defaultValue>Pendiente</option>
										<option>Tramitado</option>
									</select>
								</div>

								{error && <Message mensaje={error} bgColor="#dc3545" />}
								<input
									type="submit"
									value="Enviar"
									className="btn-send-reg-asign"
								/>

								<input
									type="reset"
									value="Cerrar"
									onClick={closeForm}
									className="btn-close-reg-asign"
								/>
							</div>
						)
					)}
				</form>
			</div>
		</div>
	);
};
