import React, { useEffect, useState } from "react";
import RegProcessAdminTableRow from "./RegProcessAdminTableRow";

const RegProcessAdminTable = ({ regProcess, irVerDetalle, company }) => {
	function sumarDias(fecha, dias) {
		fecha.setDate(fecha.getDate() + dias);
		return fecha;
	}

	let counter = 0;

	const [data, setData] = useState(regProcess);
	const [filters, setFilters] = useState({
		date1: "",
		area: "",
		processNumber: "",
		process: "",
		status: "",
	});

	useEffect(() => {
		setData(regProcess);
	}, [regProcess]);

	function handleSearch(e, columnName) {
		const { value } = e.target;
		setFilters((prevFilters) => ({
			...prevFilters,
			[columnName]: value.toLowerCase(),
		}));
	}
	const filteredData = data.filter((item) =>
		Object.entries(filters).every(([key, value]) =>
			item[key].toLowerCase().includes(value)
		)
	);

	return (
		<div>
			<div>
				<h3 className="titulo1">
					Lista Registro de Procesos (Total:{data.length})
				</h3>
			</div>

			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						<th className="titulo-tabla-procesos">No.</th>
						<th className="titulo-tabla-procesos">
							<label className="titulo14">Fecha</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.date1}
								onChange={(e) => handleSearch(e, "date1")}
							/>
						</th>
						<th className="titulo-tabla-procesos">
							<label className="titulo14"># Proceso</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.processNumber}
								onChange={(e) => handleSearch(e, "processNumber")}
							/>
						</th>
						<th className="titulo-tabla-procesos">
							<label className="titulo14">Nombre Proceso</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.process}
								onChange={(e) => handleSearch(e, "process")}
							/>
						</th>
						<th className="titulo-tabla-procesos">
							<label className="titulo14">Area</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.area}
								onChange={(e) => handleSearch(e, "area")}
							/>
						</th>
						<th className="titulo-tabla-procesos">
							<label className="titulo14">Estado</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.status}
								onChange={(e) => handleSearch(e, "status")}
							/>
						</th>
						<th className="titulo-tabla-procesos">Cumplimiento</th>
						<th className="titulo-tabla-procesos">Avance</th>
						<th className="titulo-tabla-procesos">Acción</th>
					</tr>
				</thead>
				<tbody>
					{data.length > 0 ? (
						filteredData.map(
							(el) => (
								(counter = counter + 1),
								(
									<RegProcessAdminTableRow
										key={el.id}
										el={el}
										irVerDetalle={irVerDetalle}
										counter={counter}
										company={company}
									/>
								)
							)
						)
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default RegProcessAdminTable;
