import { initializeApp } from "@firebase/app";
import Select from "react-select";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
	orderBy,
} from "@firebase/firestore";
import {
	askDataKeyValue,
	askDataKeyValue2Simple,
	askDataSimple,
	updateDocumentById,
} from "../../../../utils/actions/firebase/firebaseActions";
import { firebaseConfig } from "../../../../config/firebaseConfig";
import Loader from "../../../generalComponents/Loader";
import Message from "../../../generalComponents/Message";

export const FormulasTransformationApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const company = localUser.company;

	const initialForm = {
		productionOrder: null,
		processNumber: null,
		productionFormula: null,
		quantityProduct: 0,
		formula: null,
		amount: 0,
		processRegister: null,
	};

	const [form, setForm] = useState(initialForm);
	const [selectOrder, setSelectOrder] = useState(null);
	const [selectProcess, setSelectProcess] = useState(null);
	const [selectFormula, setSelectFormula] = useState(null);

	useEffect(() => {
		askProductionOrders();
	}, []);

	const askProductionOrders = async () => {
		const nameCollection = "productionOrders-" + company;

		setLoading(true);
		const q = query(
			collection(db, nameCollection),
			orderBy("creationDate", "desc"),
			where("status", "==", "abierto")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				convertSelectProductionOrder(dataList);
				setLoading(false);
			},
			(error) => {
				console.log("error: ", error);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askProcessRegister = async (productionOrder) => {
		setLoading(true);
		const q = query(
			collection(db, "processRegister-" + company),
			where("productionOrder", "==", productionOrder),
			where("inventoryControl", "==", "SI"),
			where("status", "==", "abierto")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				convertSelectProcess(dataList);
				setLoading(false);
			},
			(error) => {
				setError(error);
				setLoading(false);
			}
		);
	};

	const askProductionFormulas = async (refInventory) => {
		setLoading(true);
		const q = query(
			collection(db, "productionFormulas-" + company),
			where("product.refInventory", "==", refInventory)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				convertSelectProductionFormulas(dataList);
				setLoading(false);
			},
			(error) => {
				console.log("error: ", error);
				setError(error);
				setLoading(false);
			}
		);
	};

	const convertSelectProductionOrder = (productionOrder) => {
		let selectPO = [];
		productionOrder.map((item) => {
			const linePO = {
				value: item.productionOrder,
				label: item.productionOrder,
				id: item.id,
			};
			selectPO.push(linePO);
		});
		setSelectOrder(selectPO);
	};

	const convertSelectProcess = (processRegister) => {
		let selectPR = [];
		processRegister.map((item) => {
			const linePR = {
				value: item.processNumber,
				label:
					item.processNumber +
					"--" +
					item.process +
					"--" +
					item.refProductionProduct,
				refInventory: item.refProductionProduct,
				quantity: item.quantity,
				finishProduct: item.finishProduct,
				id: item.id,
				processRegister: item,
			};
			selectPR.push(linePR);
		});
		setSelectProcess(selectPR);
	};

	const convertSelectProductionFormulas = (ProdcutionFormulas) => {
		let selectPF = [];
		ProdcutionFormulas.map((item) => {
			const linePR = {
				value: item.productionFormula,
				label:
					item.productionFormula +
					"--" +
					item.product.refInventory +
					"--" +
					item.product.description,
				refInventory: item.refProductionProduct,
				id: item.id,
				formula: item,
			};
			selectPF.push(linePR);
		});
		setSelectFormula(selectPF);
	};

	const handleChange = (e) => {
		if (e.target.name === "amount") {
			setForm({
				...form,
				[e.target.name]: parseInt(e.target.value),
			});
		} else {
			setForm({
				...form,
				[e.target.name]: parseFloat(e.target.value),
			});
		}
	};

	const handleSelectProductionOrder = async (e) => {
		setForm({
			...form,
			productionOrder: e.value,
		});

		await askProcessRegister(e.value);
	};

	const handleSelectProcess = async (e) => {
		let newQuantity = e.quantity - e.finishProduct;
		setForm({
			...form,
			processNumber: e.value,
			quantityProduct: newQuantity,
			processRegister: e.processRegister,
		});
		console.log("se seleccionó el proceso: ", e.value);
		console.log("e: ", e);

		await askProductionFormulas(e.refInventory);
	};

	const handleSelectFormula = async (e) => {
		setForm({
			...form,
			productionFormula: e.value,
			formula: e.formula,
		});
		console.log("se seleccionó la fórmula: ", e.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		console.log("Recibe:", form);
		//consulta el inventario General
		const nameCollection1 = "inventoryGeneral-" + company;
		const resp1 = await askDataSimple(nameCollection1);
		const inventoryGeneral = resp1.data;
		console.log("inventrario General:", inventoryGeneral);
		let ingredients = form.formula.ingredients;
		let refToTransfer = [];
		ingredients.map((ingredient) => {
			refToTransfer.push(ingredient.refInventory);
		});
		console.log("refToTransfer:", refToTransfer);
		let ingredientsInIG = inventoryGeneral.filter((item) =>
			refToTransfer.includes(item.refInventory)
		);
		console.log(
			"disponibilidad en almacén general ingredientes a transformar: ",
			ingredientsInIG
		);
		//consulta inventario en tránsito
		const nameCollection2 = "inventoryInTransit-" + company;

		const resp2 = await askDataKeyValue(nameCollection2, "from", "warehouse");
		const inventoryInTransit = resp2.data;
		let ingredientsInIT = inventoryInTransit.filter((item) =>
			refToTransfer.includes(item.refInventory)
		);
		console.log(
			"que hay en tránsito de ingredientes a transformar: ",
			ingredientsInIT
		);
		let aviableIngredients = [];
		ingredientsInIG.map((itemInIG) => {
			ingredientsInIT.map((itemInIT) => {
				if (itemInIG.refInventory === itemInIT.refInventory) {
					itemInIG.amount = itemInIG.amount - itemInIT.amount;
				}
				aviableIngredients.push(itemInIG);
			});
		});
		console.log("Disponibilidad de los ingredientes: ", aviableIngredients);
		let amountNecesaryIngredients = [];
		ingredients.map((ingredient) => {
			let totalIngredient = ingredient;
			totalIngredient.amount = totalIngredient.amount * form.amount;
			amountNecesaryIngredients.push(totalIngredient);
		});
		console.log(
			"cantidades necesarias de ingredientes: ",
			amountNecesaryIngredients
		);
		// determinar si hay suficientes ingredientes
		let insuficienty = "";

		amountNecesaryIngredients.map((necesary) => {
			aviableIngredients.map((aviable) => {
				if (necesary.refInventory === aviable.refInventory) {
					if (necesary.amount > aviable.amount) {
						insuficienty =
							insuficienty +
							"cantidad insuficiente de: " +
							necesary.refInventory +
							"  ,";
					}
				}
			});
		});
		if (insuficienty != "") {
			alert(insuficienty);

			setLoading(false);
			handleReset();
			return;
		} else {
			console.log("*** si hay suficiente ***");
		}

		// Modifica la cantidad pendiente y producida en la orden (productionProduct-company)
		let productionProduct = (
			await askDataKeyValue2Simple(
				"productionProduct-" + company,
				"refProductionProduct",
				form.formula.product.refInventory,
				"productionOrder",
				form.productionOrder
			)
		).data[0];

		productionProduct.finishedProduct =
			productionProduct.finishedProduct + form.amount;
		productionProduct.onProcess = productionProduct.onProcess - form.amount;
		console.log("ok modificado productionProduct: ", productionProduct);

		await updateDocumentById(
			"productionProduct-" + company,
			productionProduct.id,
			productionProduct
		);

		// Modifica la cantidad pendiente y producida en el proceso (productionProduct-company)

		let processRegister = form.processRegister;
		processRegister.finishProduct = processRegister.finishProduct + form.amount;

		console.log("ok Nuevo proceso a registrar: ", processRegister);

		await updateDocumentById(
			"processRegister-" + company,
			processRegister.id,
			processRegister
		);

		// Modifica cantidad de ingredientes en el almacén general ingredientsInIG

		let totalCostFormula = 0;

		ingredientsInIG.map(async (ingredientInIG) => {
			amountNecesaryIngredients.map(async (ingredientNecesary) => {
				if (ingredientInIG.refInventory === ingredientNecesary.refInventory) {
					let necesaryTotalCost =
						ingredientInIG.cost * ingredientNecesary.amount;
					totalCostFormula = totalCostFormula + necesaryTotalCost;

					ingredientInIG.amount =
						ingredientInIG.amount - ingredientNecesary.amount;
					console.log("ok va a grabar en IG: ", ingredientInIG);
					await updateDocumentById(
						"inventoryGeneral-" + company,
						ingredientInIG.id,
						ingredientInIG
					);
				}
			});
		});

		// modifica costo y cantidad del producto en almacén general

		let productInIG = inventoryGeneral.filter((item) =>
			form.formula.product.refInventory.includes(item.refInventory)
		)[0];
		console.log("Producto en IG: ", productInIG);

		let newCostProductInIG =
			(productInIG.cost * productInIG.amount + totalCostFormula) /
			(productInIG.amount + form.amount);
		productInIG.cost = newCostProductInIG;
		productInIG.amount = productInIG.amount + form.amount;
		console.log("Nuevos valores de Producto in IG", productInIG);
		await updateDocumentById(
			"inventoryGeneral-" + company,
			productInIG.id,
			productInIG
		);

		alert("Transformación Realizada con éxito... ");

		setLoading(false);
		handleReset();
	};

	const handleReset = (e) => {
		setForm(initialForm);
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<div>
					<h3 className="titulo11">Transformación por Fórmulas</h3>
					{error && <Message />}
					<form onSubmit={handleSubmit} className="form-inventory2">
						{loading ? (
							<Loader />
						) : (
							<div>
								{!form.productionOrder ? (
									<div>
										<label className="titulo8">
											Seleccione Orden de producción:{" "}
										</label>
										<Select
											className="input0"
											options={selectOrder}
											name="productionOrder"
											onChange={handleSelectProductionOrder}
											placeholder="Orden de Producción"
										/>
									</div>
								) : (
									<div>
										<article className="titulo8">
											Orden de Producción: {form.productionOrder}
										</article>
										{!form.processNumber ? (
											<Select
												className="input0"
												options={selectProcess}
												name="processNumber"
												onChange={handleSelectProcess}
												placeholder="Número de Proceso"
											/>
										) : (
											<div>
												<article className="titulo8">
													Proceso: {form.processNumber}
												</article>
												{!form.productionFormula ? (
													<Select
														className="input0"
														options={selectFormula}
														name="productionFormula"
														onChange={handleSelectFormula}
														placeholder="Fórmula de Producción"
													/>
												) : (
													<div>
														<table className="tabla-procesos">
															<thead>
																<tr className="fila-tabla-procesos">
																	<th className="titleTableDocuments03">
																		Fórmula
																	</th>
																	<th className="titleTableDocuments02">
																		Producto
																	</th>
																	<th className="titleTableDocuments02">
																		Componentes
																	</th>
																</tr>
															</thead>
															<tbody>
																<tr className="fila-tabla-cuenstas">
																	<td className="colTableDocuments1">
																		{form.formula.productionFormula}
																	</td>
																	<td className="colTableDocuments2">
																		{form.formula.product.measureUnit}{" "}
																		{form.formula.product.description} -{" "}
																		{form.formula.product.refInventory}
																	</td>

																	<td className="colTableDocuments2">
																		{form.formula.ingredients.map((item) => (
																			<div>
																				{item.amount} {item.measureUnit} -
																				{item.description} - {item.refInventory}
																			</div>
																		))}
																	</td>
																</tr>
															</tbody>
														</table>
														<hr />
														<div>
															<label className="titulo8">
																Cantidad (máximo {form.quantityProduct}):{" "}
															</label>
															<input
																required
																type="number"
																min="0"
																max={form.quantityProduct}
																name="amount"
																placeholder="Cantidad"
																onChange={handleChange}
																value={form.amount}
																className="input0"
															/>
														</div>
													</div>
												)}
											</div>
										)}
									</div>
								)}
								<hr />
								<input
									type="submit"
									value="Transformar"
									className="btn-send-reg-asign"
								/>
								<input
									type="reset"
									value="Limpiar"
									onClick={handleReset}
									className="btn-clean-reg-asign"
								/>
							</div>
						)}
					</form>
				</div>
			</div>
		</div>
	);
};
