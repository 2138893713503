import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { askDataKeyValue } from "../../../utils/actions/firebase/firebaseActions";

export default function ViewPresentations(props) {
	let { id } = useParams();
	const [source, setSource] = useState("");
	const company = localStorage.getItem("usuariosEmpresa");

	const askDocuments = async () => {
		const nameCollection = "coursesMaterial-" + company;
		const key = "id";
		const value = id;
		const response = await askDataKeyValue(nameCollection, key, value);
		console.log("response:", response.data[0].resurce);
		setSource(response.data[0].presentationsResource);
	};

	useEffect(() => {
		askDocuments();
	}, []);

	return (
		<div>
			{console.log("en el componente, source: ", source)}
			{source && (
				<div className="view-documents-container">
					{/* <iframe src={source} frameborder="0" className="view-documents" /> */}
					<object data={source} frameborder="0" className="view-documents" />
					{/* <iframe src=`http://docs.google.com/gview?url=${source}&embedded=true`  frameborder="0" /> */}
				</div>
			)}
		</div>
	);
}
