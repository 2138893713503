import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../config/firebaseConfig";
// import "../../styles/TimeManagement.css";
import NavbarBpw2 from "../../generalComponents/NavbarBpw2";
import NavbarBpw3 from "../../generalComponents/NavbarBpw3";
import NavbarBpw4 from "../../generalComponents/NavbarBpw4";
import Message from "../../generalComponents/Message";
import Loader from "../../generalComponents/Loader";
import { GeneralAreasManagementInitial } from "./generalAreasManagementInitial";
import CrudRegProcessApp from "../../processes/regProcess/CrudRegProcessApp";
import CrudRegAssignmentsApp from "../../processes/regAssignments/CrudRegAssignmentsApp";

export const GeneralAreasManagementApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [permitedClock, setPermitedClock] = useState(false);
	const [permitedReports, setPermitedReports] = useState(false);
	const [permitedDataBases, setPermitedDataBases] = useState(false);
	const [option, setOption] = useState(null);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	// console.log("localUser: ", localUser);

	const askPermisions = () => {
		setLoading(true);
		const collectionName = "timePermisions-" + company;

		const q = query(collection(db, collectionName));
		onSnapshot(q, (querySnapshot) => {
			const dataList = [];
			querySnapshot.forEach((doc) => {
				dataList.push(doc.data());
			});
			const email = localUser.email;

			try {
				let clockPermisions = dataList.filter(
					(item) => item.typePermision == "Clock"
				)[0].timeClockPermision;
				if (clockPermisions.includes(email)) {
					setPermitedClock(true);
				} else {
					setPermitedClock(false);
				}
			} catch (error) {}

			try {
				let reportsPermisions = dataList.filter(
					(item) => item.typePermision == "Reports"
				)[0].timeReportsPermision;
				if (reportsPermisions.includes(email)) {
					setPermitedReports(true);
				} else {
					setPermitedReports(false);
				}
			} catch (error) {}

			try {
				let dataBasesPermisions = dataList.filter(
					(item) => item.typePermision == "DataBases"
				)[0].timeDataBasesPermision;
				if (dataBasesPermisions.includes(email)) {
					setPermitedDataBases(true);
				} else {
					setPermitedDataBases(false);
				}
			} catch (error) {}

			setLoading(false);
		});
	};

	useEffect(() => {
		askPermisions();
	}, []);

	const changeButton = (option) => {
		setOption(option);
	};

	return (
		<div>
			{localUser.type === "2" ? (
				<NavbarBpw2 />
			) : localUser.type === "3" ? (
				<NavbarBpw3 />
			) : (
				localUser.type === "4" && <NavbarBpw4 />
			)}

			<article>
				{loading ? (
					<Loader />
				) : (
					<div>
						{error && <Message mensaje={error} bgColor="#dc3545" />}

						<div className="containerTime01">
							<div className="containerTime02">
								{/* {(localUser.type === "2" || localUser.type === "3") && (
									<div>
										{(localUser.area === "Gerencia" ||
											localUser.area === "Producción") && (
											<div>
												{option === "Ordenes Crear" ? (
													<button
														className="buttonTime03"
														onClick={() => changeButton("Ordenes Crear")}
													>
														Ordenes Abrir/Cerrar
													</button>
												) : (
													<button
														className="buttonTime02"
														onClick={() => changeButton("Ordenes Crear")}
													>
														Ordenes Abrir/Cerrar
													</button>
												)}
											</div>
										)}
									</div>
								)} */}
								{/* {localUser.type === "3" && (
									<div>
										{option === "Ordenes Ver" ? (
											<button
												className="buttonTime03"
												onClick={() => changeButton("Ordenes Ver")}
											>
												Ordenes Ver
											</button>
										) : (
											<button
												className="buttonTime02"
												onClick={() => changeButton("Ordenes Ver")}
											>
												Ordenes Ver
											</button>
										)}
									</div>
								)} */}
								{localUser.type === "3" && (
									<div>
										<div>
											{option === "Supervisión " ? (
												<button
													className="buttonTime03"
													onClick={() => changeButton("Supervisión ")}
												>
													Supervisión
												</button>
											) : (
												<button
													className="buttonTime02"
													onClick={() => changeButton("Supervisión ")}
												>
													Supervisión
												</button>
											)}
										</div>
									</div>
								)}

								<div>
									{localUser.type === "3" && (
										<div>
											{option === "Tareas Propias" ? (
												<button
													className="buttonTime03"
													onClick={() => changeButton("Tareas Propias")}
												>
													Tareas Propias
												</button>
											) : (
												<button
													className="buttonTime02"
													onClick={() => changeButton("Tareas Propias")}
												>
													Tareas Propias
												</button>
											)}
										</div>
									)}
								</div>

								{/* {permitedClock && (
									<div>
										{option === "Relojes" ? (
											<button
												className="buttonTime03"
												onClick={() => changeButton("Relojes")}
											>
												Relojes
											</button>
										) : (
											<button
												className="buttonTime02"
												onClick={() => changeButton("Relojes")}
											>
												Relojes
											</button>
										)}
									</div>
								)} */}
								{/* {permitedReports && (
										<div>
											{option === "Informes" ? (
												<button
													className="buttonTime03	"
													onClick={() => changeButton("Informes")}
												>
													Informes
												</button>
											) : (
												<button
													className="buttonTime02	"
													onClick={() => changeButton("Informes")}
												>
													Informes
												</button>
											)}
										</div>
									)} */}

								{/* {permitedDataBases && (
										<div>
											{option === "Data Base" ? (
												<button
													className="buttonTime03	"
													onClick={() => changeButton("Data Base")}
												>
													Data Base
												</button>
											) : (
												<button
													className="buttonTime02	"
													onClick={() => changeButton("Data Base")}
												>
													Data Base
												</button>
											)}
										</div>
									)} */}

								{/* {localUser.type === "2" && (
									<div>
										{option === "Fórmulas" ? (
											<button
												className="buttonTime03	"
												onClick={() => changeButton("Fórmulas")}
											>
												Fórmulas
											</button>
										) : (
											<button
												className="buttonTime02	"
												onClick={() => changeButton("Fórmulas")}
											>
												Fórmulas
											</button>
										)}
									</div>
								)} */}
								{/* {localUser.type === "2" && (
									<div>
										{option === "Permisos" ? (
											<button
												className="buttonTime03	"
												onClick={() => changeButton("Permisos")}
											>
												Permisos
											</button>
										) : (
											<button
												className="buttonTime02	"
												onClick={() => changeButton("Permisos")}
											>
												Permisos
											</button>
										)}
									</div>
								)} */}
							</div>

							<div className="containerTime03">
								{option === null && (
									<GeneralAreasManagementInitial area={localUser.area} />
								)}
								{/* {option === "Ordenes Crear" && <ProductionOrdersApp />} */}
								{/* {option === "Ordenes Ver" && <ProcessOrderApp />} */}
								{option === "Supervisión " && (
									<CrudRegProcessApp area={localUser.area} />
								)}
								{/* {option === "Usuarios" && <UsersProductionApp />} */}
								{/* {option === "Histórico" && <HistoricalUsersProductionApp />} */}
								{option === "Tareas Propias" && (
									<CrudRegAssignmentsApp area={localUser.area} />
								)}
								{/* {option === "Relojes" && <TimeClocksApp />}
								{option === "Informes" && <GeneralAreasManagementInitial />}
								{option === "Data Base" && <GeneralAreasManagementInitial />}
								{option === "Permisos" && <GeneralAreasManagementInitial />}
								{option === "Fórmulas" && <FormulasManagementApp />} */}
							</div>
						</div>
					</div>
				)}
			</article>
		</div>
	);
};
