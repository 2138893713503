import React, { useState } from "react";
import { Redirect } from "react-router";
import NavbarBpw2 from "../../generalComponents/NavbarBpw2";
import { AsignationApp } from "../asignation/AsignationApp";
import { CoursesApp } from "../courses/CoursesApp";
import EvaluationsApp from "../evaluations/EvaluationsApp";
import { PresentationsApp } from "../presentations/PresentationsApp";
import { StudentsApp } from "../students/StudentsApp";
import "../styles/MainTraining.css";
import { MainTrainingInitial } from "./MainTrainingInitial";

export const MainTrainingCompany = () => {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const [option, setOption] = useState(null);

	const changeButton = (option) => {
		setOption(option);
	};
	return (
		<div>
			{localUser.type === "2" ? (
				<div>
					<NavbarBpw2 />
					<div className="containerTraining01">
						<div className="containerTraining02">
							{option === "cursos" ? (
								<button
									className="buttonTraining03"
									onClick={() => changeButton("cursos")}
								>
									Cursos
								</button>
							) : (
								<button
									className="buttonTraining02"
									onClick={() => changeButton("cursos")}
								>
									Cursos
								</button>
							)}

							{option === "presentaciones" ? (
								<button
									className="buttonTraining03"
									onClick={() => changeButton("presentaciones")}
								>
									Material
								</button>
							) : (
								<button
									className="buttonTraining02"
									onClick={() => changeButton("presentaciones")}
								>
									Material
								</button>
							)}
							{option === "evaluaciones" ? (
								<button
									className="buttonTraining03"
									onClick={() => changeButton("evaluaciones")}
								>
									Evaluaciones
								</button>
							) : (
								<button
									className="buttonTraining02"
									onClick={() => changeButton("evaluaciones")}
								>
									Evaluaciones
								</button>
							)}
							{option === "asignacion" ? (
								<button
									className="buttonTraining03	"
									onClick={() => changeButton("asignacion")}
								>
									Asignación
								</button>
							) : (
								<button
									className="buttonTraining02	"
									onClick={() => changeButton("asignacion")}
								>
									Asignación
								</button>
							)}
							{option === "alumnos" ? (
								<button
									className="buttonTraining03	"
									onClick={() => changeButton("alumnos")}
								>
									Cursos Propios
								</button>
							) : (
								<button
									className="buttonTraining02	"
									onClick={() => changeButton("alumnos")}
								>
									Curos Propios
								</button>
							)}

							{/* {option === "otros" ? (
								<button
									className="buttonTraining03"
									onClick={() => changeButton("otros")}
								>
									Otros
								</button>
							) : (
								<button
									className="buttonTraining02"
									onClick={() => changeButton("otros")}
								>
									Otros
								</button>
							)} */}
						</div>

						<div className="containerTraining03">
							{option === null && <MainTrainingInitial />}
							{option === "cursos" && <CoursesApp />}
							{option === "presentaciones" && <PresentationsApp />}
							{option === "evaluaciones" && <EvaluationsApp />}
							{option === "asignacion" && <AsignationApp />}
							{option === "alumnos" && <StudentsApp />}
							{/* {option === "otros" && <CoursesApp />} */}
						</div>
					</div>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};
