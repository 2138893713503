import React, { useState } from "react";
import { Link } from "react-router-dom";
// import imagen from "../components/images/logoBPWhorizontal.png";
import imagen from "./images/logoBPW.png";

export default function NavbarBpw3() {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const ModuleKeyProcess = localStorage.getItem("ModuleKeyProcess");
	const ModuleKeyDocuments = localStorage.getItem("ModuleKeyDocuments");
	const ModuleKeyCapacitation = localStorage.getItem("ModuleKeyCapacitation");
	const ModuleKeyEvaluation = localStorage.getItem("ModuleKeyEvaluation");

	const [visibleProduction, setVisibleProduction] = useState(false);
	const [visibleInventroy, setVisibleInventroy] = useState(false);

	function openProduction() {
		setVisibleProduction(true);
	}

	function closeProduction() {
		setVisibleProduction(false);
	}

	function openInventory() {
		setVisibleInventroy(true);
	}

	function closeInventrory() {
		setVisibleInventroy(false);
	}

	return (
		<div className="navbar">
			<div className="div01Navbar">
				<img src={imagen} className="icono" />
			</div>
			{localUser.area != "Producción" &&
				localUser.area != "Gerencia" &&
				localUser.area != "Recursos Humanos" &&
				localUser.area != "Gestión" && (
					<div className="div01Navbar01">
						<Link to="/process-management" className="linkNavbar">
							{localUser.area}
						</Link>
					</div>
				)}

			{localUser.area === "Recursos Humanos" && (
				<div>
					<Link to="/humanResources-management" className="linkNavbar">
						Recursos Humanos
					</Link>
				</div>
			)}

			{(localUser.area === "Producción" ||
				localUser.area === "Gerencia" ||
				localUser.area === "Comercial") &&
				ModuleKeyProcess === "si" && (
					<div onMouseLeave={closeProduction}>
						<div className="div01Navbar01">
							<Link to="/production-management" className="linkNavbar">
								Producción
							</Link>
						</div>
						{visibleProduction === true && (
							<div className="dropdownMenu">
								{localUser.area === "Producción" && (
									<div className="div01Navbar01">
										<Link to="/production-orders" className="linkNavbar">
											Apertura y Cierre - Ordenes de Producción
										</Link>
									</div>
								)}
								{localUser.area === "Producción" && (
									<div className="div01Navbar01">
										<Link
											to="/production-process-orders"
											className="linkNavbar"
										>
											Ordenes de Producción
										</Link>
									</div>
								)}
								<div className="div01Navbar01">
									<Link
										to="/supervisor-company/reg_procesos"
										className="linkNavbar"
									>
										Supervisión de Procesos
									</Link>
								</div>
								<div className="div01Navbar01">
									<Link to="/worker-company/assignments" className="linkNavbar">
										Etapas Asignadas
									</Link>
								</div>

								<div className="div01Navbar01">
									<Link to="/time-management" className="linkNavbar">
										Tiempos
									</Link>
								</div>
							</div>
						)}
					</div>
				)}
			{(localUser.area === "Producción" || localUser.area === "Gerencia") && (
				<div>
					{ModuleKeyProcess === "si" && (
						<div className="div01Navbar01">
							<Link to="/inventory-user3" className="linkNavbar">
								Inventarios
							</Link>
						</div>
					)}
				</div>
			)}

			{ModuleKeyDocuments === "si" && (
				<div className="div01Navbar01">
					<Link to="/documents" className="linkNavbar">
						Documentos
					</Link>
				</div>
			)}
			{ModuleKeyCapacitation === "si" && (
				<div className="div01Navbar01">
					<Link to="/students_training_main_company" className="linkNavbar">
						Cursos
					</Link>
				</div>
			)}
			<div className="div01Navbar01">
				<Link to="/admin_user" className="linkNavbar">
					{/* {localUser.email} */}
					Usuario
				</Link>
			</div>
			<div className="div01Navbar01">
				<Link to="/" className="closeButton">
					Cerrar
				</Link>
			</div>
		</div>
	);
}
