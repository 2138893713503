import React from "react";
import { separadoresDeMil } from "../../../../utils/actions/firebase/firebaseActions";

export const FormulasManagementFormTable = ({
	ingredientsList,
	eraseIngredient,
}) => {
	return (
		<div className="containerProductsTransform">
			<table className="tabla-inventory">
				<thead>
					<tr className="fila-tabla-procesos-">
						<th className="tabla-column-etiqueta">Referencia</th>
						<th className="tabla-column-etiqueta">Descripción</th>
						<th className="tabla-column-etiqueta">Cantidad</th>
						<th className="tabla-column-etiqueta">Unidad</th>
						<th className="tabla-column-etiqueta">Acción</th>
					</tr>
				</thead>

				<tbody>
					{ingredientsList.map((ingredientList, index) => (
						<tr key={index}>
							<td className="tabla-column-data">
								{ingredientList.refInventory}
							</td>
							<td className="tabla-column-data">
								{ingredientList.description}
							</td>
							<td className="tabla-column-data">
								{separadoresDeMil(ingredientList.amount)}
							</td>
							<td className="tabla-column-data">
								{ingredientList.measureUnit}
							</td>

							<td>
								<input
									value="Borrar"
									className="btnErraseProduct"
									onClick={() => eraseIngredient(ingredientList.id)}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
