import { initializeApp } from "@firebase/app";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
	addDoc,
	doc,
	updateDoc,
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig, storage } from "../../../../config/firebaseConfig";
// import { ref, deleteObject } from "firebase/storage";
import {
	getDownloadURL,
	ref,
	uploadBytesResumable,
	deleteObject,
} from "@firebase/storage";
import {
	deleteDocumentById,
	updateDocumentById,
} from "../../../../utils/actions/firebase/firebaseActions";
import Loader from "../../../generalComponents/Loader";

export const DocNoveltiesApp = ({ documentToEdit, setDocumentToEdit }) => {
	const [documents, setDocuments] = useState([
		"Doc_Novedad",
		"Otro_doc_novedad",
	]);
	const docId = documentToEdit.employeeDocument;

	const initialForm = {
		id: null,
		document: "",
		Doc_Novedad: "",
		name_Doc_Novedad: "",
		Otro_doc_novedad: "",
		name_Otro_doc_novedad: "",
	};
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [form, setForm] = useState(documentToEdit);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [progressLoad, setProgressLoad] = useState(-1);
	const [fileToRec, setFileToRec] = useState(null);
	const [storageRef, setStorageRef] = useState(null);
	const company = localStorage.getItem("usuariosEmpresa");
	// const nameCollection = "documentsNoveltiesEmployees-" + company;
	const nameCollection = "noveltiesEmployees-" + company;

	useEffect(() => {}, []);

	const askDocsNovelties = async () => {
		setLoading(true);
		const q = query(
			collection(db, nameCollection),
			where("document", "==", docId)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				if (dataList.length > 0) {
					setForm(dataList[0]);
				} else {
					setForm(initialForm);
				}
				setLoading(false);
			},
			(error) => {
				setError(error);
				setLoading(false);
			}
		);
	};

	const handleSelectFile = (e) => {
		setLoading(true);
		const file = e.target.files[0];
		// console.log("tamaño del archivo: ", file.size);

		if (file.size > 20000000) {
			// console.log("excede el máximo permitido");
			setError("Tamaño de archivo supera 20 MB ");
			setLoading(false);
			return;
		} else {
			setFileToRec(file);
			setError(null);
		}

		const uniqueFileName = `${Date.now()}_${file.name}`;
		const storageRef = ref(
			storage,
			"resource/" +
				company +
				"/employees/" +
				docId +
				"/novelties/" +
				uniqueFileName
		);
		form["name_" + e.target.name] = uniqueFileName;
		setStorageRef(storageRef);

		// const storageRef = ref(
		// 	storage,
		// 	"resource/" + company + "/employees/" + docId + "/novelties/" + file.name
		// );
		// form["name_" + e.target.name] = file.name;

		setLoading(false);
	};

	const LoadFile = async (param) => {
		setLoading(true);
		const uploadTask = uploadBytesResumable(storageRef, fileToRec);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.floor(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgressLoad(progress);

				switch (snapshot.state) {
					case "paused":
						console.log("Upload is paused");
						break;
					case "running":
						console.log("Upload is running");
						break;
				}
			},
			(error) => {
				setError(error);
				console.log(error);
				setLoading(false); // Establecer loading a false en caso de error
			},
			async () => {
				try {
					const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
					form[param] = downloadURL;
					updateDocumentById(nameCollection, form.id, form);
				} catch (error) {
					setError(error);
					console.error("Error al obtener la URL de descarga", error);
					// Manejar el error según sea necesario
					setLoading(false); // Establecer loading a false en caso de error
				} finally {
					setLoading(false); // Establecer loading a false después de finalizar la carga del archivo
				}
			}
		);

		// uploadTask.on(
		// 	"state_changed",
		// 	(snapshot) => {
		// 		const progress = Math.floor(
		// 			(snapshot.bytesTransferred / snapshot.totalBytes) * 100
		// 		);
		// 		setProgressLoad(progress);

		// 		switch (snapshot.state) {
		// 			case "paused":
		// 				console.log("Upload is paused");
		// 				break;
		// 			case "running":
		// 				console.log("Upload is running");
		// 				break;
		// 		}
		// 	},
		// 	(error) => {
		// 		console.log(error);
		// 	},
		// 	() => {
		// 		getDownloadURL(uploadTask.snapshot.ref)
		// 			.then(async (downloadURL) => {
		// 				form[param] = downloadURL;

		// 				updateDocumentById(nameCollection, form.id, form);
		// 			})
		// 			.finally(() => {
		// 				setLoading(false); // Establecer loading a false después de finalizar la carga del archivo
		// 			});
		// 	}
		// );
	};

	const deleteDocument = async (data) => {
		const nameCollection = "documents-list-" + company;
		const docId = data.id;
		let isDelete = window.confirm(`¿Estás seguro de eliminar este Documento?`);
		if (isDelete) {
			setLoading(true);
			setError("");
			await deleteDocumentById(nameCollection, docId)
				.then((res) => {
					console.log("respuesta", res);
				})
				.catch((error) => {
					console.log("error", error);
					setError(error);
				});
			setError(null);
			setLoading(false);
		} else {
			return;
		}
	};

	const deleteFile = async (param) => {
		const filePath = form[param];
		let isDelete = window.confirm(`¿Estás seguro de eliminar este Documento?`);
		if (isDelete) {
			setLoading(true);
			setError("");
			const desertRef = ref(storage, filePath);
			deleteObject(desertRef)
				.then(async () => {
					form[param] = "";
					form["name_" + param] = "";
					await updateDocumentById(nameCollection, form.id, form);
					// console.log("File deleted successfully");
					setLoading(false);
				})
				.catch((error) => {
					setError(error);
					// console.log("Uh-oh, an error occurred!");
					setLoading(false);
				});
		} else {
			return;
		}
	};

	return (
		<div>
			<br />
			{loading ? (
				<Loader />
			) : (
				<table className="tabla-procesos">
					<thead>
						<tr className="fila-tabla-procesos">
							<th className="titleSearchColumn01">Documento</th>
							<th className="titleSearchColumn01">Ver </th>
							<th className="titleSearchColumn01">Subir / Borrar</th>
						</tr>
					</thead>
					<tbody>
						{documents.map((doc, index) => (
							<tr key={index} className="fila-tabla-cuenstas">
								<td className="colTableDocuments0">{doc}</td>
								<td className="colTableDocuments0">
									{form[doc] ? (
										<a href={form[doc]} target="_blank" rel="noreferrer">
											<button className="btnViewDocuments">Ver</button>
										</a>
									) : (
										<div>NO document</div>
									)}
								</td>

								<td className="colTableDocuments0">
									{!form["name_" + doc] || form["name_" + doc] === "" ? (
										<div className="input-container">
											<label htmlFor="fileInput">Seleccionar Archivo</label>
											<input
												type="file"
												accept=".pdf"
												name={doc}
												id="fileInput"
												onChange={handleSelectFile}
											/>
										</div>
									) : form[doc] === "" || !form[doc] ? (
										<button
											className="btnViewDocuments"
											onClick={() => LoadFile(doc)}
										>
											Subir
										</button>
									) : (
										<button
											className="btnDeleteDocuments"
											onClick={() => deleteFile(doc)}
										>
											Borrar
										</button>
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};
