import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { useModal } from "../../../generalComponents/hooks/useModal";
import Loader from "../../../generalComponents/Loader";
import Message from "../../../generalComponents/Message";

import Modal from "../../../generalComponents/Modal";
import { firebaseConfig } from "../../../../config/firebaseConfig";
import ProductionDocumentsTable from "./productionDocumentsTable";
import {
	addDocumento,
	addDocumentProduction,
	deleteDocumentById,
	updateDocumentById,
} from "../../../../utils/actions/firebase/firebaseActions";
import ProductionDocumentsForm from "./productionDocumentsForm";

export const ProductionDocumentsApp = ({ productionOrder }) => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [showTable, setShowTable] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [dataBase, setDataBase] = useState(null);
	const [documentToEdit, setDocumentToEdit] = useState(null);
	const [documentToDelete, setDocumentToDelete] = useState(null);
	const nameCollection = "documents-production-list-" + company;

	const [isOpenModal1, openModal1, closeModal1] = useModal(false); //uno para cada modal que se utilice
	const [isOpenModal2, openModal2, closeModal2] = useModal(false);

	useEffect(() => {
		askDocs();
	}, []);

	const askDocs = async () => {
		setLoading(true);

		const q = query(
			collection(db, nameCollection),
			where("productionOrder", "==", productionOrder)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				// console.log("dataList: ", dataList);
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const createDocument = async (data) => {
		setLoading(true);
		const response = await addDocumentProduction(data, company);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		// askDocs();
		setLoading(false);
	};

	const updateDocument = async (data) => {
		setLoading(true);
		const docId = data.id;
		const changes = data;
		await updateDocumentById(nameCollection, docId, changes);
		setLoading(false);
	};

	const deleteDocument = async (data) => {
		const docId = data.id;
		let isDelete = window.confirm(`¿Estás seguro de eliminar este Documento?`);
		if (isDelete) {
			setLoading(true);
			setError("");
			await deleteDocumentById(nameCollection, docId)
				.then((res) => {
					console.log("respuesta", res);
				})
				.catch((error) => {
					console.log("error", error);
					setError(error);
				});
			setError(null);
			setLoading(false);
		} else {
			return;
		}
	};

	const clickCrear = () => {
		setDocumentToEdit(null);
		openModal1();
	};

	const openTable = () => {
		setShowTable(true);
	};
	const closeTable = () => {
		setShowTable(false);
	};

	return (
		<div className="headerPO">
			<article className="titulo3">
				Lista de Documentos
				{!showTable ? (
					<button onClick={() => openTable()} className="btnVisibleAnswerOn">
						Ver
					</button>
				) : (
					<button onClick={() => closeTable()} className="btnVisibleAnswerOff">
						Ocultar
					</button>
				)}
			</article>

			{showTable && (
				<article>
					<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
						<ProductionDocumentsForm
							// dataBase2={dataBase2}
							createDocument={createDocument}
							updateDocument={updateDocument}
							documentToEdit={documentToEdit}
							setDocumentToEdit={setDocumentToEdit}
							company={company}
							closeModal={closeModal1}
							setError={setError}
							isOpenModal1={isOpenModal1}
							productionOrder={productionOrder}
						/>
					</Modal>
					<Modal isOpen={isOpenModal2} closeModal={closeModal2}>
						{/* <CrudDocumentsForm2
							updateDocument={updateDocument}
							documentToEdit={documentToEdit}
							setDocumentToEdit={setDocumentToEdit}
							company={company}
							closeModal={closeModal2}
							setError={setError} 
						/>*/}
					</Modal>

					{loading && <Loader />}
					{error && <Message mensaje={error} bgColor="#dc3545" />}
					{dataBase ? (
						<div>
							<button
								onClick={clickCrear}
								className="btn-create-document-process"
							>
								Nuevo Documento
							</button>

							<ProductionDocumentsTable
								documents={dataBase}
								setDocumentToEdit={setDocumentToEdit}
								setDocumentToDelete={setDocumentToDelete}
								deleteDocument={deleteDocument}
								openModal1={openModal1}
								openModal2={openModal2}
							/>
						</div>
					) : (
						<h3>No hay Documentos para mostrar</h3>
					)}
				</article>
			)}
		</div>
	);
};
