import React from "react";
import { EmployeesForm } from "./employeesForm";
import Modal from "../../generalComponents/Modal";
import { useModal } from "../../generalComponents/hooks/useModal";
import { DocEmployeesApp } from "./docEmployees/docEmployeesApp";

export const EmployeCvApp = ({
	data,
	setShowEmploye,
	user,
	documentToEdit,
	setDocumentToEdit,
	company,
	setShowForm,
}) => {
	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	const buttonAction1 = () => {
		setShowEmploye(false);
	};

	const clickEditar = () => {
		setDocumentToEdit(data);
		openModal1();
	};
	return (
		<div>
			<article className="titulo11">
				HOJA DE VIDA{" "}
				<button onClick={() => buttonAction1()} className="btn-return">
					Cerrar Hoja
				</button>
			</article>
			<div className="ContainerHojaVida">
				<div className="containerCvLeft">
					<table className="tabla-procesos">
						<thead>
							<tr className="fila-tabla-procesos">
								<th className=""> </th>
								<th className=""> </th>
							</tr>
						</thead>
						<tbody>
							<tr className="">
								<td className="">Nombre:</td>
								<td className="">{data.name}</td>
							</tr>
							<tr className="">
								<td className="">Tipo de documento:</td>
								<td className="">{data.typeDocument}</td>
							</tr>
							<tr className="">
								<td className="">Número de Documento:</td>
								<td className="">{data.document}</td>
							</tr>
							<tr className="">
								<td className="">Fecha de expedición documento:</td>
								<td className="">{data.expeditionDate}</td>
							</tr>
							<tr className="">
								<td className="">Fecha de terminación documento:</td>
								<td className="">{data.expirateDate}</td>
							</tr>
							<tr className="">
								<td className="">Email:</td>
								<td className="">{data.email}</td>
							</tr>
							<tr className="">
								<td className="">Teléfono:</td>
								<td className="">{data.phone}</td>
							</tr>
							<tr className="">
								<td className="">Dirección:</td>
								<td className="">{data.adress}</td>
							</tr>
							<tr className="">
								<td className="">EPS:</td>
								<td className="">{data.eps}</td>
							</tr>
							<tr className="">
								<td className="">ARL:</td>
								<td className="">{data.arl}</td>
							</tr>
							<tr className="">
								<td className="">Fondo de Pensiones:</td>
								<td className="">{data.fondoPension}</td>
							</tr>
							<tr className="">
								<td className="">Fondo de Cesantías:</td>
								<td className="">{data.fondoCesantias}</td>
							</tr>
							<tr className="">
								<td className="">Caja de Compensación:</td>
								<td className="">{data.cajaCompensacion}</td>
							</tr>
							<tr className="">
								<td className="">Cargo:</td>
								<td className="">{data.cargo}</td>
							</tr>
							<tr className="">
								<td className="">Jefe Inmediato:</td>
								<td className="">{data.inmediateBoss}</td>
							</tr>
							<tr className="">
								<td className="">Fecha de Ingreso:</td>
								<td className="">{data.dateIntake}</td>
							</tr>
							<tr className="">
								<td className="">Area:</td>
								<td className="">{data.area}</td>
							</tr>
							<tr className="">
								<td className="">Tipo de Contrato:</td>
								<td className="">{data.contractType}</td>
							</tr>
							<tr className="">
								<td className="">Salario:</td>
								<td className="">{data.salary}</td>
							</tr>
							<tr className="">
								<td className="">Fecha Terminación del Contrato:</td>
								<td className="">{data.dateOut}</td>
							</tr>
							<tr className="">
								<td className="">Status:</td>
								<td className="">{data.status}</td>
							</tr>

							{/* <tr className="fila-tabla-cuenstas">
						<td className="colTableDocuments0">Tipo de Documento:</td>
						<td className="colTableDocuments0">{data.typeDocument}</td>
					</tr> */}
							{/* {Object.entries(data).map(([key, value]) => (
						<tr key={key}>
							<td>{key}</td>
							<td>{value}</td>
						</tr>
					))} */}
						</tbody>
					</table>
					<button onClick={clickEditar} className="btnEditEmployee">
						Editar datos...
					</button>
				</div>
				<div className="containerCvRigth">
					<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
						<EmployeesForm
							setShowForm={setShowForm}
							company={company}
							user={user}
							setDocumentToEdit={setDocumentToEdit}
							documentToEdit={documentToEdit}
							closeModal1={closeModal1}
						/>
					</Modal>
					<DocEmployeesApp docId={documentToEdit.document} />
				</div>
			</div>
		</div>
	);
};
