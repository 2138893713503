import React, { useState, useEffect } from "react";
import Loader from "../../generalComponents/Loader";
import Select from "react-select";
import { addRegProcess } from "../../../utils/actions/firebase/firebaseActions";

const CrudRegProcesosForm = ({
	createRegProcess,
	company,
	agency,
	creator,
	area,
	closeModal,
	options,
}) => {
	const initialForm = {
		date: "",
		id: null,
		date1: "",
		hour1: "",
		agency: agency,
		area: area,
		creator: creator,
		processNumber: "",
		process: "",
		status: "abierto",
		dateClose: "",
		hourClose: "",
		complianceProcess: 0,
		questions: 0,
		responses: 0,
		productionOrder: null,
		refProductionProduct: null,
		quantity: 0,
		timeControl: "",
		inventoryControl: "",
		productionProduct: null,
		newOnProcess: 0,
		finishProduct: 0,
	};
	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const [slectedOption, setSlectedOption] = useState(null);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	useEffect(() => {
		setForm(initialForm);
	}, []);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleNewSelect = (selectedOption) => {
		setSlectedOption(selectedOption);
		setForm({
			...form,
			process: selectedOption.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!form.processNumber) {
			alert("Debe colocar un  Número que identifique el Proceso...");
			return;
		}
		if (form.process === "" || form.process === "Proceso") {
			alert("Debe seleccionar Proceso...");

			return;
		}
		setLoading(true);
		try {
			// await createRegProcess(form).then((response) => {
			await addRegProcess(form, company).then((response) => {
				console.log("**** hubo una respuesta: ", response);
				setLoading(false);
				terminar();
				setForm(initialForm);
				handleReset();
				closeModal();
			});
			// Aquí setLoading(false) se ejecutará solo si createRegProcess se completa sin errores
		} catch (error) {
			console.error("Error al ejecutar createRegProcess:", error);
			// Puedes manejar el error de la manera que prefieras, por ejemplo, mostrando un mensaje de error al usuario
			alert(
				"Error al ejecutar createRegProcess. Consulta la consola para más detalles."
			);
			setLoading(false);
		}

		// await createRegProcess(form);

		// terminar();
		// setForm(initialForm);
		// handleReset();
		// setLoading(false);
		// closeModal();
	};

	const terminar = () => {
		setForm(initialForm);
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setSlectedOption(null);
	};

	return (
		<div>
			<div className="cont1">
				<h3 className="titulo1">Agregar un nuevo Proceso</h3>
				<form onSubmit={(e) => handleSubmit(e)} className="form-cuentas">
					<h2 className="titulo1">{company}</h2>
					<h2 className="titulo3">Creador: {creator}</h2>
					{loading ? (
						<Loader />
					) : (
						<div>
							<label className="titulo1">Número de Proceso</label>
							<input
								type="text"
								name="processNumber"
								placeholder="# de Proceso"
								onChange={handleChange}
								value={form.processNumber}
								className="input3"
							/>
							<div className="input1_2">
								<label className="titulo1">Nombre del proceso.</label>
								<Select
									name="process"
									options={options}
									className="input1_3"
									onChange={handleNewSelect}
									placeholder="Seleccione Proceso..."
									value={slectedOption}
								/>
							</div>
							<hr />
							{localUser.area === "Producción" && (
								<div>
									<label className="nameCheckbox">
										Hacer control de Tiempos ?
									</label>
									<select
										className="input3"
										name="timeControl"
										onChange={handleChange}
										value={form.timeControl}
									>
										<option defaultValue>NO</option>
										<option>SI</option>
									</select>
								</div>
							)}
							{localUser.area === "Producción" && (
								<div>
									<label className="nameCheckbox">
										Hacer control de Inventarios ?
									</label>
									<select
										className="input3"
										name="inventoryControl"
										onChange={handleChange}
										value={form.inventoryControl}
									>
										<option defaultValue value>
											NO
										</option>
										<option>SI</option>
									</select>
								</div>
							)}
							<hr />
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={closeModal}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default CrudRegProcesosForm;
