import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	onSnapshot,
	collection,
	getFirestore,
	orderBy,
} from "@firebase/firestore";
import { Redirect } from "react-router";
import { firebaseConfig } from "../../../config/firebaseConfig";
import NavbarBpw2 from "../../generalComponents/NavbarBpw2";
import { useModal } from "../../generalComponents/hooks/useModal";
import Modal from "../../generalComponents/Modal";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import {
	addDatakey1,
	askDataKeyValue,
	deleteDocumentById,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import { ProductionOrdersForm } from "./productionOrdersForm";
import { ProductionOrdersTable } from "./productionOrdersTable";

export const ProductionOrdersApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [dataBase, setDataBase] = useState(null); //listado de Ordenes de Producción
	const [productionOrderToEdit, setProductionOrderToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [modalAvialbe, setModalAvialbe] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	const nameCollection = "productionOrders-" + company;

	// const date = new Date();
	// const actualDate = date.toLocaleDateString();
	// console.log("actualDate:", actualDate);
	// const transf1 = Date.parse(actualDate);
	// console.log("trans1: ", transf1);
	// console.log("1: ", date.toUTCString());
	// const date2 = new Date(actualDate);
	// const actualeDAte2 = date2.toLocaleDateString();
	// const date4 = new Date(actualeDAte2);
	// const date3 = new Date(transf1);
	// console.log("date:", date);
	// console.log("date2:", date2);
	// console.log("date3:", date3);
	// console.log("date4:", date4);

	// const convertirFechas = async () => {
	// 	setLoading(true);
	// 	const q = query(
	// 		collection(db, nameCollection)
	// 		// orderBy("creationDate", "desc")
	// 	);
	// 	onSnapshot(
	// 		q,
	// 		(querySnapshot) => {
	// 			let dataList = querySnapshot.docs.map((d) => ({
	// 				...d.data(),
	// 			}));

	// 			dataList.map(async (orden) => {
	// 				console.log("orden: ", orden);
	// 				let fecha_nac = orden.creationDate;
	// 				try {
	// 					const [dia, mes, ano] = fecha_nac.split("/");
	// 					let cambiada = `${mes}/${dia}/${ano}`;
	// 					orden.date1 = orden.creationDate;
	// 					const date2 = new Date(orden.creationDate);
	// 					console.log("date2: ", date2);

	// 					const date4 = new Date(cambiada);
	// 					console.log("grabar en creationDate: ", date4);
	// 					orden.creationDate = date4;
	// 					await updateDocumentById(nameCollection, orden.id, orden);
	// 				} catch (error) {
	// 					console.log(error);
	// 				}
	// 			});

	// 			setLoading(false);
	// 		},
	// 		(error) => {
	// 			console.log("error: ", error);

	// 			setError(error);
	// 			setLoading(false);
	// 		}
	// 	);
	// };

	const askProductionOrders = async () => {
		setLoading(true);
		const q = query(
			collection(db, nameCollection),
			orderBy("creationDate", "desc")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				console.log("error: ", error);
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askProductionOrders();
	}, []);

	const clickCrear = () => {
		setProductionOrderToEdit(null);
		setModalAvialbe(true);
		openModal1();
	};

	async function createProductionOrder(data) {
		const date = new Date();
		const actualDate = date.toLocaleDateString();
		data.creationDate = date;
		data.date1 = actualDate;
		setLoading(true);
		const response = await addDatakey1(
			nameCollection,
			data,
			"productionOrder",
			data.productionOrder
		);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		await askProductionOrders();
		setLoading(false);
	}

	async function updateProductionOrder(data) {
		setLoading(true);
		// console.log("data a modificar:", data);
		const docId = data.id;
		const changes = data;
		const key = "productionOrder";
		const value = data.productionOrder;
		// console.log("value2", value2);
		const response = await askDataKeyValue(nameCollection, key, value);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				if (response.data[0].id != docId) {
					setError("Esta Orden ya existe ya existe");
				} else {
					await updateDocumentById(nameCollection, docId, changes);
				}
			}
		}
		// await askAccounts();
		setLoading(false);
	}

	async function deleteProductionOrder(data) {
		const docId = data.id;
		let isDelete = window.confirm(
			`¿Estás seguro de eliminar esta Orden de Producción ?`
		);
		if (isDelete) {
			setLoading(true);
			setError("");
			await deleteDocumentById(nameCollection, docId)
				.then((res) => {
					console.log("respuesta", res);
				})
				.catch((error) => {
					console.log("error", error);
					setError(error);
				});
			setError(null);
			setLoading(false);
		} else {
			return;
		}
	}

	return (
		<div>
			{localUser.type === "2" || localUser.type === "3" ? (
				<div>
					<article>
						<article className="titulo9">
							Apertura y Cierre de Ordenes de Producción ({company})
						</article>
						{modalAvialbe && (
							<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
								<ProductionOrdersForm
									company={company}
									createProductionOrder={createProductionOrder}
									updateProductionOrder={updateProductionOrder}
									productionOrderToEdit={productionOrderToEdit}
									setProductionOrderToEdit={setProductionOrderToEdit}
									closeModal={closeModal1}
								/>
							</Modal>
						)}
						{loading ? (
							<Loader />
						) : (
							<div>
								{error && <Message mensaje={error} bgColor="#dc3545" />}
								{dataBase ? (
									dataBase.length >= 0 ? (
										<div>
											<button
												onClick={clickCrear}
												className="btn-create-document"
											>
												Nueva Orden de Producción
											</button>
											<ProductionOrdersTable
												data={dataBase}
												setProductionOrderToEdit={setProductionOrderToEdit}
												deleteProductionOrder={deleteProductionOrder}
												openModal={openModal1}
												setModalAvialbe={setModalAvialbe}
											/>
										</div>
									) : (
										<h3 className="alertEmpty">
											No hay ProductionOrderes para mostrar
										</h3>
									)
								) : (
									<h3 className="alertEmpty">Buscando...</h3>
								)}
							</div>
						)}
					</article>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};
