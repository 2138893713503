import React, { useEffect, useState } from "react";
import "../../../styles/MenuProcesos.css";
import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { storage } from "../../../../config/firebaseConfig";
import {
	askDataKeyValue,
	askDataKeyValue2Simple,
} from "../../../../utils/actions/firebase/firebaseActions";
import Loader from "../../../generalComponents/Loader";
import Message from "../../../generalComponents/Message";
import ProgressBar from "../../../generalComponents/ProgressBar";

const ProductionDocumentsForm = ({
	// dataBase2,
	createDocument,
	updateDocument,
	documentToEdit,
	setDocumentToEdit,
	company,
	closeModal,
	setError,
	isOpenModal1,
	productionOrder,
}) => {
	const initialForm = {
		id: null,
		name: "",
		description: "",
		date: "",
		resource: "",
		productionOrder: productionOrder,
	};
	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	// const [areas, setAreas] = useState(dataBase2);
	const [error2, setError2] = useState(null);
	const [progressLoad, setProgressLoad] = useState(-1);
	const nameCollection = "documents-production-list-" + company;

	useEffect(() => {
		if (documentToEdit) {
			setForm(documentToEdit);
		} else {
			setForm(initialForm);
		}
		// setAreas(dataBase2);
	}, [documentToEdit, isOpenModal1]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		console.log("*** empieza a subir archivo *****");
		if (!form.name) {
			alert("Debe colocar un nombre de documento...");
			return;
		}
		if (!form.description) {
			alert("Debe llenar el campo de descripción del documento...");
			return;
		}
		if (!form.resource) {
			alert("Debe seleccionar un archivo para subir..");
			return;
		}
		// const nameCollection = nameCollection;
		const key = "name";
		const value = form.name;
		const response = await askDataKeyValue(nameCollection, key, value);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				setError("Este Documento ya existe");
			} else {
				setLoading(true);
				const fechita = new Date();
				const actualDate = fechita.toLocaleDateString();
				form.date = actualDate;

				if (form.id === null) {
					createDocument(form);
				} else {
					updateDocument(form);
				}
			}
		}

		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleChangeFile = (e) => {
		console.log("recibe e: ", e);
		console.log("recibe e.target.name: ", e.target.name);
		setLoading(true);
		const file = e.target.files[0];
		console.log("tamaño del archivo: ", file.size);

		if (file.size > 20000000) {
			// console.log("excede el máximo permitido");
			setError2("Tamaño de archivo supera 20 MB ");
			setLoading(false);
			return;
		} else {
			setError2(null);
		}

		const storageRef = ref(
			storage,
			`${e.target.name}` + "/" + company + "/production/" + file.name
		);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.floor(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgressLoad(progress);

				switch (snapshot.state) {
					case "paused":
						console.log("Upload is paused");
						break;
					case "running":
						console.log("Upload is running");
						break;
				}
			},
			(error) => {
				console.log(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					console.log("File available at", downloadURL);
					setForm({
						...form,
						[e.target.name]: downloadURL,
					});
				});
			}
		);
		setLoading(false);
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setDocumentToEdit(null);
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo1">
					{documentToEdit ? "Editar Documento" : "Agregar Nuevo Documento"}
				</h3>
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="title2-account">Empresa: {company}</h2>
					{loading ? (
						<Loader />
					) : (
						<div>
							{/* <label className="labelSelect">Seleccione un Area</label>
							<select
								name="area"
								onChange={handleChange}
								value={form.area}
								className="input1"
							>
								<option defaultValue>Area</option>
								{areas &&
									areas.map((area) => (
										<option key={area.area} value={area.area}>
											{area.area}
										</option>
									))}
							</select> */}
							<input
								type="text"
								name="name"
								placeholder="Nombre del Documento"
								onChange={handleChange}
								value={form.name}
								className="input0"
							/>
							<input
								type="text"
								name="description"
								placeholder="Descripción del Documento"
								onChange={handleChange}
								value={form.description}
								className="input0"
							/>

							{documentToEdit ? (
								<div>{/* <p>{form.resource}</p> */}</div>
							) : (
								<div>
									<input
										type="file"
										accept=".pdf"
										name="resource"
										id=""
										// value={form.resource}
										onChange={handleChangeFile}
										className="input0"
									/>
									{(progressLoad > 0) & (progressLoad < 100) && (
										<ProgressBar
											bgcolor={"green"}
											fontcolor={"white"}
											progress={progressLoad}
											height={"30px"}
										/>
									)}
									{progressLoad !== 100 ? (
										progressLoad >= 0 && (
											<p className="loadingFile">Cargando...{progressLoad} %</p>
										)
									) : (
										<p className="loadedFile">Archivo Cargado</p>
									)}
								</div>
							)}

							<hr />
							{error2 && <Message mensaje={error2} bgColor="#dc3545" />}
							<hr />
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={closeModal}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default ProductionDocumentsForm;
