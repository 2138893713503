import React from "react";

export const LoadMasterInventoryTableRow = ({ el }) => {
	return (
		<tr className="fila-tabla-cuenstas">
			<td className="colTableDocuments1">{el.refInventory}</td>
			<td className="colTableDocuments1">{el.description}</td>
			<td className="colTableDocuments1">{el.features}</td>
			<td className="colTableDocuments1">{el.measureUnit}</td>
		</tr>
	);
};
