import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router";
import { borrarUsuario } from "../../../utils/actions";
import {
	askDataKeyValue,
	askDataSimple,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import NavbarBpw from "../../generalComponents/NavbarBpw";
import CrudUsuariosForm from "./CrudUsuariosForm";
import CrudUsuariosTable from "./CrudUsuariosTable";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";

const CrudUsuariosApp = (props) => {
	let { company } = useParams();
	const [db, setDb] = useState(null);
	const [userToEdit, setUserToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const empresa = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const askUsers = async () => {
		setLoading(true);
		const nameCollection = "users";
		const key = "company";
		const value = company;
		const respuesta = await askDataKeyValue(nameCollection, key, value);
		// const respuesta = await askUsuarios(empresa);
		if (respuesta.statusResponse === true) {
			setDb(respuesta.data);
			setLoading(false);
		} else {
			setDb(null);
			setError(respuesta.error);
			setLoading(false);
		}
	};

	useEffect(() => {
		askUsers();
	}, []);

	const updateUser = async (data) => {
		setLoading(true);
		const collection = "users";
		const docId = data.id;
		const changes = data;
		await updateDocumentById(collection, docId, changes);
		await askUsers();
		setLoading(false);
	};

	const deleteUser = async (id) => {
		let isDelete = window.confirm(
			`¿Estás seguro de eliminar el registro con el id '${id}'?`
		);

		if (isDelete) {
			setLoading(true);
			await borrarUsuario(id);
			await askUsers();
			setLoading(false);
		} else {
			return;
		}

		// if (isDelete) {
		// 	let newData = db.filter((el) => el.id !== id);
		// 	setDb(newData);
		// } else {
		// 	return;
		// }
	};

	const updatePersonel = async () => {
		setLoading(true);
		const nameCollection = "employees-" + company;
		const resp1 = await askDataSimple(nameCollection);
		if (resp1.statusResponse) {
			const employeesList = resp1.data;
			// console.log("lista empleados: ", employeesList);
			const users = db;
			let filterUsers = users.filter((el) => el.name === "");

			function findUsersNotInEmployees(usersList, employeesList) {
				// Filtrar usuarios que no están en employeesList basándonos en el campo "email"
				const usersNotInEmp = employeesList.filter((employee) => {
					const foundEmplo = users.find(
						(user) => user.email === employee.email
					);
					return !foundEmplo;
				});
				// const usersNotInEmployees = usersList.filter((user) => {
				// 	// Buscar el usuario en employeesList por el campo "email"
				// 	const foundEmployee = employeesList.find(
				// 		(employee) => employee.email === user.email
				// 	);

				// 	// Si no se encuentra, significa que el usuario no está en employeesList
				// 	return !foundEmployee;
				// });

				// return usersNotInEmployees;
				return usersNotInEmp;
			}

			const result = findUsersNotInEmployees(users, employeesList);
			// console.log("los que falta crer:", result.length, " en total");
			result.map((user) => {
				// console.log("correo:", user.email);
			});

			// console.log("users: ", filterUsers);
			filterUsers.map((fUser) => {
				employeesList.map((employee) => {
					if (fUser.email === employee.email) {
						fUser.name = employee.name;
						fUser.area = employee.area;
						fUser.type = "4";
					}
				});
			});
			// console.log("---- TERMINÓ DE CRUZAR -----");
			// console.log("users para grabar ahora: ", filterUsers);
			if (filterUsers.length === 0) {
				// console.log("sale si grabar nada");
				setLoading(false);
				return;
			}
			await Promise.all(
				users.map(async (user) => {
					await updateDocumentById("users", user.id, user);
				})
			);
			setLoading(false);
		}
		setError(resp1.error);
		setLoading(false);
	};

	return (
		<div>
			{localUser.type === "1" ? (
				loading ? (
					<Loader />
				) : (
					<div>
						<NavbarBpw />
						<article>
							<CrudUsuariosForm
								updateUser={updateUser}
								userToEdit={userToEdit}
								setUserToEdit={setUserToEdit}
								company={company}
							/>
							<button onClick={() => updatePersonel()} className="btn-editar">
								ACTUALIZAR PERSONAL
							</button>

							{error && <Message mensaje={error} bgColor="#dc3545" />}
							{db && (
								<CrudUsuariosTable
									company={company}
									user={db}
									setUserToEdit={setUserToEdit}
									deleteUser={deleteUser}
								/>
							)}
						</article>
					</div>
				)
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};

export default CrudUsuariosApp;
