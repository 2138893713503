import React, { useState } from "react";
import { updateDocumentById } from "../../../../utils/actions/firebase/firebaseActions";
import Loader from "../../../generalComponents/Loader";

export const AuthorizationForm = ({
	documentToEdit,
	setDocumentToEdit,
	closeModal1,
	newAprovalSataus,
	setNewAprovalSataus,
}) => {
	const [loading, setLoading] = useState(false);
	const [comment, setComment] = useState(documentToEdit.commentBoss);
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const recComment = (value) => {
		setComment(value);
		documentToEdit.commentBoss = value;
	};

	const closeForm = () => {
		setComment(null);
		closeModal1();
	};

	const handleSubmit = async (e) => {
		setLoading(true);
		e.preventDefault();
		if (documentToEdit.commentBoss === "") {
			alert("Debe colocar un comentario para poder continuar...");
			setLoading(false);
			return;
		}

		const fechita = new Date();
		const year = fechita.getFullYear();
		const month = fechita.getMonth() + 1; // Los meses son indexados desde 0, así que sumamos 1
		const day = fechita.getDate();
		const twoDigits = (number) => (number < 10 ? `0${number}` : number);
		const actualDate = `${year}-${twoDigits(month)}-${twoDigits(day)}`;
		const nameCollection = "noveltiesEmployees-" + company;

		documentToEdit.commentBoss = comment;
		documentToEdit.aprovalStatus = newAprovalSataus;
		documentToEdit.dateUpdate = actualDate;
		documentToEdit.userUpdate = localUser.email;
		await updateDocumentById(nameCollection, documentToEdit.id, documentToEdit);

		setLoading(false);
		closeModal1();
	};

	return (
		<div>
			{loading ? (
				<Loader />
			) : (
				<form onSubmit={handleSubmit} className="formRH">
					<article className="titulo2">
						Va a modificar el permiso a estado: {documentToEdit.aprovalStatus}
					</article>

					<article className="titulo8">Ingrese su comentario</article>
					<textarea
						value={comment}
						onChange={(e) => recComment(e.target.value)}
						// rows={4}
						cols={100}
						className="humanTexArea0"
					/>
					<br />

					<input type="submit" value="Enviar" className="btn-send-reg-asign" />
					<input
						type="reset"
						value="Cerrar"
						onClick={closeForm}
						className="btn-close-reg-asign"
					/>
				</form>
			)}
		</div>
	);
};
