import React, { useState } from "react";
import { AuthorizationsTableRow } from "./authorizationsTableRow";

export const AuthorizationsTable = ({
	data,
	licencia,
	openModal1,
	setDocumentToEdit,
	setNewAprovalSataus,
}) => {
	const [filters, setFilters] = useState({
		aprovalStatus: "",
		dateInitial: "",
		dateFinal: "",
		reason: "",
		employeeDocument: "",
		employeeName: "",
		// commentBoss:"",
		userAuthorizer: "",
	});

	function handleSearch(e, columnName) {
		const { value } = e.target;
		setFilters((prevFilters) => ({
			...prevFilters,
			[columnName]: value.toLowerCase(),
		}));
	}
	const filteredData = data.filter((item) =>
		Object.entries(filters).every(([key, value]) =>
			item[key].toLowerCase().includes(value)
		)
	);
	return (
		<div>
			{licencia ? (
				<h3 className="titulo1">Licencias para autorizar...</h3>
			) : (
				<h3 className="titulo1">Permisos para autorizar...</h3>
			)}
			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						{/* <th className="titleSearchColumn01">ID</th> */}
						<th className="titleSearchColumn01">
							<label className="titulo14">Fecha Inicial</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.dateInitial}
								onChange={(e) => handleSearch(e, "dateInitial")}
							/>
						</th>
						<th className="titleSearchColumn01">
							<label className="titulo14">Fecha Final</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.dateFinal}
								onChange={(e) => handleSearch(e, "nadateFinalme")}
							/>
						</th>
						<th className="titleSearchColumn01">
							<label className="titulo14">Trabajador</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.employeeName}
								onChange={(e) => handleSearch(e, "employeeName")}
							/>
						</th>
						<th className="titleSearchColumn01">
							<label className="titulo14">Motivo</label>
							<input
								className="searchColumn"
								type="text"
								value={filters.reason}
								onChange={(e) => handleSearch(e, "reason")}
							/>
						</th>

						<th className="titleTableDocuments03">Ver Anexo</th>
						<th className="titleTableDocuments03">Acción</th>
					</tr>
				</thead>
				<tbody>
					{data.length > 0 ? (
						filteredData.map((el) => (
							<AuthorizationsTableRow
								key={el.id}
								el={el}
								openModal1={openModal1}
								setDocumentToEdit={setDocumentToEdit}
								setNewAprovalSataus={setNewAprovalSataus}
								// setDocumentToDelete={setDocumentToDelete}
								// deleteDocument={deleteDocument}
								// setShowForm={setShowForm}
								// deleteDocument={deleteDocument}
								// setShowEmploye={setShowEmploye}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
