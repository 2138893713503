import React from "react";

export const PermisionsTableRow = ({
	el,
	setDocumentToEdit,
	setShowEmploye,
}) => {
	const buttonAction = (el) => {
		setDocumentToEdit(el);
		setShowEmploye(true);
	};

	return (
		<tr className="fila-tabla-cuenstas">
			<td className="colTableDocuments0">{el.dateInitial}</td>
			<td className="colTableDocuments2">{el.dateFinal}</td>
			<td className="colTableDocuments1">{el.reason}</td>
			<td className="colTableDocuments1">{el.userAuthorizer}</td>
			<td className="colTableDocuments1">{el.aprovalStatus}</td>
			{/* <td className="colTableDocuments1">{el.status}</td> */}

			<td className="colTableDocuments2">
				{/* <button onClick={() => buttonAction(el)} className="btnViewDocuments">
					Ver
				</button> */}
				<a href={el.Doc_Novedad} target="_blank" rel="noreferrer">
					<button className="btnViewDocuments">Ver</button>
				</a>
			</td>
		</tr>
	);
};
