import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
	getDocs,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../../../config/firebaseConfig";
import Loader from "../../../../generalComponents/Loader";
import Select from "react-select";
import {
	recTransformGeneralInventory,
	recTransformInventory,
} from "../../../../../utils/actions/firebase/firebaseActions";
import Message from "../../../../generalComponents/Message";
import { ProductsToGeneralTransformTable } from "./productsToGeneralTransformTable";

export const GeneralTransformationFrom = ({ closeModal }) => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const initialForm = {
		id: null,
		initialProducts: [],
		finalProducts: [],
		date: null,
		user: localUser.email,
		warehouse: "warehouse",
	};

	const initialTransforProduct = {
		product: null,
		amount: 0,
		position: 0,
	};

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [selectListProcess, setSelectListProcess] = useState(null);
	const [aviableInventory, setAviableInventory] = useState(null);
	const [firstInventory, setFirstInventory] = useState(null);
	const [secondInventory, setSecondInventory] = useState(null);
	const [form, setForm] = useState(initialForm);
	const [productToAdd, setProductToAdd] = useState(initialTransforProduct);
	const [productToAdd2, setProductToAdd2] = useState(initialTransforProduct);

	useEffect(() => {
		askAviableInventory();
		// askMasterInventory();
	}, []);

	const askMasterInventory = async () => {
		const nameCollection = "inventoryMaster-" + company;
		setLoading(true);
		const q = query(collection(db, nameCollection));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setSecondInventory(convertSelectInventory2(dataList));
				setLoading(false);
			},
			(error) => {
				setSecondInventory(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askInvetoryInTransit = async () => {
		let result = null;
		const nameCollection = "inventoryInTransit-" + company;
		const q = query(
			collection(db, nameCollection),
			where("from", "==", "warehouse")
		);
		await getDocs(q)
			.then((querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				result = dataList;
			})
			.catch((error) => {
				result.error = error;
				result = [];
			});
		return result;
	};

	const convertSelectInventory = (inventory) => {
		let selectInventoryProv = [];
		inventory.map((item) => {
			const lineInventory = {
				value: item.refInventory,
				label:
					item.refInventory +
					" - " +
					item.description +
					" [ " +
					item.amount +
					" ] ",
				id: item.id,
				description: item.description,
				product: item,
			};
			selectInventoryProv.push(lineInventory);
		});
		return selectInventoryProv;
	};

	const convertSelectInventory2 = (inventory) => {
		let selectInventoryProv = [];
		inventory.map((item) => {
			const lineInventory = {
				value: item.refInventory,
				label: item.refInventory + " - " + item.description,
				id: item.id,
				description: item.description,
				product: item,
			};
			selectInventoryProv.push(lineInventory);
		});
		return selectInventoryProv;
	};

	const askAviableInventory = async () => {
		setLoading(true);
		const pendingTransfer = await askInvetoryInTransit();
		// console.log("pendingTrasnfer:", pendingTransfer);
		const nameCollection = "inventoryGeneral-" + company;
		const q = query(collection(db, nameCollection));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				// setGeneralInventory(dataList);
				let validInventory = dataList;
				if (pendingTransfer.length === 0) {
					validInventory = dataList;
					setAviableInventory(dataList);
				} else {
					// console.log("validInventory: ", validInventory);
					pendingTransfer.map((productPending) => {
						const productToModify = validInventory.filter(
							(product) => product.refInventory === productPending.refInventory
						);

						const newAviability = validInventory.filter(
							(product) => product.refInventory !== productPending.refInventory
						);
						// console.log("productToModify: ", productToModify);
						// console.log("productPending: ", productPending);
						productToModify[0].amount =
							productToModify[0].amount - productPending.amount;
						newAviability.push(productToModify[0]);
						validInventory = newAviability;
					});

					setAviableInventory(validInventory);
				}
				// console.log("validInventory:", validInventory);
				// console.log(">>> Convertido: ", convertSelectInventory(validInventory));
				setFirstInventory(convertSelectInventory(validInventory));
				askMasterInventory();

				setLoading(false);
			},
			(error) => {
				// setGeneralInventory(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (form.initialProducts.length === 0) {
			alert("Debe seleccionar por lo menos un producto a Transformar...");
			setLoading(false);
			return;
		}
		if (form.finalProducts.length === 0) {
			alert(
				"Debe seleccionar por lo menos un producto que ingresará al inventario..."
			);
			setLoading(false);
			return;
		}
		// console.log("para ejecutar form:", form);
		const response = await recTransformGeneralInventory(form, company);
		if (response.statusResponse) {
			handleReset();
			alert("Se ha grabado correctamente la Transformación...");
		} else {
			setError(response.error);
		}
		closeModal();
		window.location.replace(""); //REFRESCA LA PAGINA

		setLoading(false);
	};

	const handleSelectChangeProduct = (e) => {
		setProductToAdd({
			...productToAdd,
			product: e,
			position: form.initialProducts.length,
		});
	};
	const handleSelectChangeProduct2 = (e) => {
		setProductToAdd2({
			...productToAdd2,
			product: e,
			position: form.finalProducts.length,
		});
	};

	const handleChangeProductAdd = (e) => {
		setProductToAdd({
			...productToAdd,
			[e.target.name]: parseFloat(e.target.value),
		});
		// if (e.target.name === "amount") {
		// 	setProductToAdd({
		// 		...productToAdd,
		// 		[e.target.name]: parseInt(e.target.value),
		// 	});
		// } else {
		// 	setProductToAdd({
		// 		...productToAdd,
		// 		[e.target.name]: parseFloat(e.target.value),
		// 	});
		// }
	};
	const handleChangeProductAdd2 = (e) => {
		setProductToAdd2({
			...productToAdd2,
			[e.target.name]: parseFloat(e.target.value),
		});
		// if (e.target.name === "amount") {
		// 	setProductToAdd2({
		// 		...productToAdd2,
		// 		[e.target.name]: parseInt(e.target.value),
		// 	});
		// } else {
		// 	setProductToAdd2({
		// 		...productToAdd2,
		// 		[e.target.name]: parseFloat(e.target.value),
		// 	});
		// }
	};

	const addProduct = () => {
		if (productToAdd.product === null) {
			alert("debe seleccionar un producto...");
			return;
		}
		if (productToAdd.amount === 0) {
			alert("debe colocar una cantidad de producto...");
			return;
		}
		if (productToAdd.amount > productToAdd.product.product.amount) {
			alert("No puede transferir más unidades que las que posee...");
			return;
		}

		const valueCompare = productToAdd.product.value;
		const compare = form.initialProducts.filter(
			(line) => line.product.value === valueCompare
		);
		if (compare.length > 0) {
			alert("no puede repetir producto ya seleccionado...");
			return;
		}

		form.initialProducts.push(productToAdd);
		setProductToAdd(initialTransforProduct);
	};

	const addProduct2 = () => {
		if (productToAdd2.product === null) {
			alert("debe seleccionar un producto...");
			return;
		}
		if (productToAdd2.amount === 0) {
			alert("debe colocar una cantidad de producto...");
			return;
		}

		// console.log("productToAdd2:", productToAdd2);

		const valueCompare = productToAdd2.product.value;
		// console.log("form: ", form);
		const compare = form.finalProducts.filter(
			(line) => line.product.value === valueCompare
		);
		if (compare.length > 0) {
			alert("no puede repetir producto ya seleccionado...");
			return;
		}

		form.finalProducts.push(productToAdd2);
		setProductToAdd2(initialTransforProduct);
	};

	const eraseProduct = (index) => {
		if (form.initialProducts.length === 1) {
			setForm({
				...form,
				initialProducts: [],
			});
		} else {
			const res = form.initialProducts.filter((item) => item.position != index);
			setForm({
				...form,
				initialProducts: res,
			});
		}
	};

	const eraseProduct2 = (index) => {
		if (form.finalProducts.length === 1) {
			setForm({
				...form,
				finalProducts: [],
			});
		} else {
			const res = form.finalProducts.filter((item) => item.position != index);
			setForm({
				...form,
				finalProducts: res,
			});
		}
	};

	const handleReset = (e) => {
		setForm(initialForm);
	};

	const handleClose = (e) => {
		// setForm(initialForm);
		closeModal();
	};

	return (
		<div>
			<form onSubmit={handleSubmit} className="form-procesos">
				<h2 className="title2-account">Transformaciones de Inventario</h2>
				{error && <Message mensaje={error} bgColor="#dc3545" />}
				{loading ? (
					<Loader />
				) : (
					<div>
						<div>
							<hr className="hrWhite" />
							<label className="titulo10">
								Productos a Transformar (salen del iventario)
							</label>
							<label className="titulo10">Adicionar productos</label>
							<table className="tabla-inventory-transform">
								<thead>
									<tr>
										<th className="tabla-column-etiqueta">Referencia</th>
										<th className="tabla-column-etiqueta">Cantidad</th>
										<th className="tabla-column-etiqueta"></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="col-tabla-procesos1-">
											<Select
												className="input1_3"
												options={firstInventory}
												name="refProductionProduct"
												onChange={handleSelectChangeProduct}
												placeholder="Seleccione un producto"
											/>
										</td>
										<td className="col-tabla-procesos1-">
											<input
												required
												type="number"
												min="0"
												name="amount"
												placeholder="Cantidad"
												onChange={handleChangeProductAdd}
												value={productToAdd.amount}
												className="input0"
											/>
										</td>
										<td>
											<input
												value="Adicionar"
												className="btnShowPermisions"
												onClick={addProduct}
											/>
										</td>
									</tr>
								</tbody>
							</table>

							{form.initialProducts.length <= 0 ? (
								<article className="titulo11">
									...No hay productos Adicionados...
								</article>
							) : (
								<ProductsToGeneralTransformTable
									productsList={form.initialProducts}
									eraseProduct={eraseProduct}
								/>
							)}

							<hr className="hrWhite" />
							<hr className="hrWhite" />
							<label className="titulo10">
								Productos Nuevos (entran del iventario)
							</label>
							<label className="titulo10">Adicionar productos</label>
							<table className="tabla-inventory-transform">
								<thead>
									<tr>
										<th className="tabla-column-etiqueta">Referencia</th>
										<th className="tabla-column-etiqueta">Cantidad</th>
										<th className="tabla-column-etiqueta"></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="tabla-column-transform">
											<Select
												className="input1_3"
												options={secondInventory}
												name="refProductionProduct"
												onChange={handleSelectChangeProduct2}
												placeholder="Seleccione un producto"
											/>
										</td>
										<td className="tabla-column-transform">
											<input
												required
												type="number"
												min="0"
												name="amount"
												placeholder="Cantidad"
												onChange={handleChangeProductAdd2}
												value={productToAdd2.amount}
												className="input0"
											/>
										</td>
										<td className="tabla-column-transform">
											<input
												value="Adicionar"
												className="btnShowPermisions"
												onClick={addProduct2}
											/>
										</td>
									</tr>
								</tbody>
							</table>

							{form.finalProducts.length <= 0 ? (
								<article className="titulo11">
									...No hay productos Adicionados...
								</article>
							) : (
								<ProductsToGeneralTransformTable
									productsList={form.finalProducts}
									eraseProduct={eraseProduct2}
								/>
							)}
							<hr className="hrWhite" />
							<hr className="hrWhite" />
						</div>

						<input
							type="submit"
							value="Enviar"
							className="btn-send-reg-asign"
						/>
						<input
							type="reset"
							value="Limpiar"
							onClick={handleReset}
							className="btn-clean-reg-asign"
						/>
						<input
							type="reset"
							value="Cerrar"
							onClick={handleClose}
							className="btn-close-reg-asign"
						/>
					</div>
				)}
			</form>
		</div>
	);
};
