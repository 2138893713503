import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import Loader from "../../../../generalComponents/Loader";
import Message from "../../../../generalComponents/Message";
import { firebaseConfig } from "../../../../../config/firebaseConfig";
import Modal from "../../../../generalComponents/Modal";
import { useModal } from "../../../../generalComponents/hooks/useModal";
import { GeneralTransformationFrom } from "./generalTransformationFrom";
import { GeneralTransformationsTable } from "./generalTransformationsTable";
// import { useModal } from "../../../../generalComponents/hooks/useModal";

export const GeneralTransformationsApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [dataBase, setDataBase] = useState(null); //alamacena base de datos de las TRANSFORMACIONES pendientes dede este usuario
	const [listProcess, setListProcess] = useState(null);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	useEffect(() => {
		askInventoryTransformation();
	}, []);

	const askInventoryTransformation = () => {
		setLoading(true);
		const nameCollection = "inventoryTransform-" + company;
		const q = query(
			collection(db, nameCollection),
			where("warehouse", "==", "warehouse"),
			where("status", "==", "pendiente")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);

				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
		setLoading(false);
	};

	const clickCrear = () => {
		console.log("hágale pues...");

		openModal1();
	};

	return (
		<div>
			{/* <p>Aquí se gestionan las transferencias de iventario de salida</p> */}
			<article className="titulo11">
				Tranasformaciones de Inventario General
			</article>

			<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
				<GeneralTransformationFrom
					closeModal={closeModal1}
					// setAviableInventory={setAviableInventory}
				/>
			</Modal>

			{loading ? (
				<Loader />
			) : (
				<div>
					{error && <Message mensaje={error} bgColor="#dc3545" />}
					<button onClick={clickCrear} className="btn-crear-proceso">
						Crear Transformación
					</button>
					{dataBase && dataBase.length > 0 ? (
						<div>
							<GeneralTransformationsTable dataBase={dataBase} />
						</div>
					) : (
						<article className="titulo2">
							No hay Transformaciones pendientes para mostrar...
						</article>
					)}
				</div>
			)}
		</div>
	);
};
