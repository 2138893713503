import React, { useEffect, useState } from "react";
import ProductionDocumentsTableRow from "./productionDocumentsTableRow";

const ProductionDocumentsTable = ({
	documents,
	setDocumentToEdit,
	setDocumentToDelete,
	deleteDocument,
	openModal1,
	openModal2,
}) => {
	const [data, setData] = useState(documents);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	useEffect(() => {
		setData(documents);
	}, [documents]);

	function handleSearch(e) {
		if (e.target.value === "") {
			return setData(documents);
		}
		setData(
			documents.filter(
				(item) =>
					item.name.toLowerCase().includes(e.target.value) ||
					item.description.toLowerCase().includes(e.target.value) ||
					item.area.toLowerCase().includes(e.target.value)
			)
		);
	}

	return (
		<div>
			{/* {console.log("data", data)} */}
			<div className="document-search">
				<h2 className="title-search">Buscar</h2>
				<input
					type="search"
					name=""
					id=""
					onChange={handleSearch}
					className="search-box"
				/>
			</div>
			<h3 className="titulo1">LISTA DE DOCUMENTOS</h3>
			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						<th className="titleTableDocuments02">Documento</th>
						<th className="titleTableDocuments02">Descripcion</th>
						<th className="titleTableDocuments03">Fecha</th>
						<th className="titleTableDocuments03">Ver</th>
						{localUser.type === "2" && (
							<th className="titleTableDocuments01">Acción</th>
						)}
						{localUser.type === "2" && (
							<th className="titleTableDocuments03">Borrar</th>
						)}
					</tr>
				</thead>
				<tbody>
					{data.length > 0 ? (
						data.map((el) => (
							<ProductionDocumentsTableRow
								key={el.id}
								el={el}
								setDocumentToEdit={setDocumentToEdit}
								setDocumentToDelete={setDocumentToDelete}
								deleteDocument={deleteDocument}
								openModal1={openModal1}
								openModal2={openModal2}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default ProductionDocumentsTable;
