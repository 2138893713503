import React from "react";

export const RequestsTableRow = ({
	el,
	setDocumentToEdit,
	setShowEmploye,
	openModal2,
	adminRequests,
}) => {
	const buttonAction = (el) => {
		setDocumentToEdit(el);
		openModal2();
		// setShowEmploye(true);
	};

	return (
		<tr className="fila-tabla-cuenstas">
			<td className="colTableDocuments0">{el.dateCreation}</td>
			{adminRequests && (
				<td className="colTableDocuments2">{el.employeeName}</td>
			)}
			<td className="colTableDocuments2">
				{el.request}
				{"  "}
				{el.name_Doc_Request && (
					<a href={el.Doc_Request} target="_blank" rel="noreferrer">
						<button className="btnViewDocuments">Ver</button>
					</a>
				)}
			</td>
			<td className="colTableDocuments2">{el.commentAproval}</td>
			<td className="colTableDocuments1">{el.aprovalStatus}</td>
			{/* <td className="colTableDocuments1">{el.status}</td> */}
			{adminRequests && (
				<td className="colTableDocuments2">
					{el.aprovalStatus !== "Tramitado" && (
						<button
							onClick={() => buttonAction(el)}
							className="btnEditDocuments"
						>
							Tramitar
						</button>
					)}
				</td>
			)}
		</tr>
	);
};
