import { initializeApp } from "@firebase/app";
import React, { useState, useEffect } from "react";
import {
	query,
	where,
	onSnapshot,
	orderBy,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { Redirect } from "react-router-dom";
import { useModal } from "../../../generalComponents/hooks/useModal";
import Modal from "../../../generalComponents/Modal";
import Loader from "../../../generalComponents/Loader";
import Message from "../../../generalComponents/Message";
import "../../../styles/RegProcesos.css";
import {
	addRegProcess,
	askDataKeyValue3,
	askDataKeyValue3Simple,
	askDataKeyValue4Simple,
} from "../../../../utils/actions/firebase/firebaseActions";
import { firebaseConfig } from "../../../../config/firebaseConfig";
import { RegProcessOrderForm } from "./regProcessOrderForm";
import { RegProcessOrderTable } from "./regProcessOrderTable";

const RegProcessOrderApp = ({
	productionOrder,
	productsList,
	goShowDetail,
}) => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [dataBase, setDataBase] = useState(null); //procesos registrados en la Orden de producción
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [verDetalle, setVerDetalle] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const email = localUser.email;
	const area = localUser.area;
	const [algo, setAlgo] = useState(null);
	const [processes, setProcesses] = useState(null);
	const [options, setOptions] = useState(null);
	const [optionProducts, setOptionProducts] = useState(null);

	const [isOpenModal1, openModal1, closeModal1] = useModal(false); //uno para cada modal que se utilice

	const preguntaRegProcesos = async () => {
		setLoading(true);
		const q = query(
			collection(db, "processRegister-" + company),
			// where("agency", "==", localUser.agency),
			// where("area", "==", localUser.area),
			where("productionOrder", "==", productionOrder),
			orderBy("date", "desc")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				console.log("dataList:", dataList);
				setDataBase(dataList);

				// setRefresh(true);
				setLoading(false);
			},
			(error) => {
				console.log("error:", error);
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		preguntaRegProcesos();
	}, []);

	const createRegProcess = async (data) => {
		setLoading(true);
		const response = await addRegProcess(data, company);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		setLoading(false);
		setRefresh(true);
	};

	const askProcess = async () => {
		setLoading(true);
		const nameCollection = "process-" + company;
		const key2 = "area";
		const value2 = area;
		const key3 = "release";
		const value3 = "si";
		const key4 = "active";
		const value4 = "si";

		const response = await askDataKeyValue3Simple(
			nameCollection,
			key2,
			value2,
			key3,
			value3,
			key4,
			value4
		);
		console.log("*** process: ", response);
		if (response.statusResponse === true) {
			setProcesses(response.data);
			// console.log("*** datos:", response.data);
			// console.log(">>>>> Procesess: ", processes);
			setOptions([]);

			// console.log("*** options:", options);
			var op = [];
			response.data.map((proceso) => {
				op.push({ label: proceso.process, value: proceso.process });
				// console.log("hace un push", proceso.process);
			});
			setOptions(op);
			// console.log("---- options:", options);
			setLoading(false);
		} else {
			setProcesses([]);
			setOptions([]);
			setError(response.error);

			setLoading(false);
		}
	};

	const buildProductsList = () => {
		setLoading(true);
		if (productsList) {
			setOptionProducts([]);
			var pList = [];
			productsList.map((product) => {
				pList.push({
					label: product.description,
					value: product.refProductionProduct,
					productionProduct: product,
					idProduct: product.id,
					finishedProduct: product.finishedProduct,
					totalAmount: product.amount,
					onProcess: product.onProcess,
				});
			});
			setOptionProducts(pList);
			setLoading(false);
		} else {
			setOptionProducts([]);
			setLoading(false);
		}
	};

	const clickCrear = async () => {
		await askProcess();
		buildProductsList();
		// setProcessToEdit(null);
		// setAlgo(true);
		openModal1();
	};

	const irVerDetalle = () => {
		setVerDetalle(true);
	};

	return (
		<div>
			<div>
				<article>
					{/* <p>Aquí se gestionan listas de procesos</p> */}

					{/* {console.log("+++++ procesess:", processes)} */}
					{options && (
						<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
							<RegProcessOrderForm
								createRegProcess={createRegProcess}
								company={company}
								agency={localUser.agency}
								creator={email}
								area={area}
								closeModal={closeModal1}
								processes={processes}
								options={options}
								productionOrder={productionOrder}
								optionProducts={optionProducts}
							/>
						</Modal>
					)}

					{loading ? (
						<Loader />
					) : (
						<div>
							<hr />
							{/* <label className="titleProcess">
								SUPERVISION DE PROCESOS ({company}){productionOrder}
							</label> */}
							{(localUser.area === "Gerencia" ||
								localUser.area === "Producción") && (
								<button onClick={clickCrear} className="btn-crear-proceso">
									Agregar Proceso
								</button>
							)}

							{error && <Message mensaje={error} bgColor="#dc3545" />}
							{dataBase ? (
								<RegProcessOrderTable
									regProcess={dataBase}
									irVerDetalle={irVerDetalle}
									company={company}
									goShowDetail={goShowDetail}
								/>
							) : (
								<h3>No hay procesos para mostrar</h3>
							)}
						</div>
					)}
				</article>
				{verDetalle === true && (
					<Redirect to="/supervisor-company/reg_procesos/ver_orden" />
				)}
			</div>
		</div>
	);
};

export default RegProcessOrderApp;
