import React, { useState } from "react";
import { useModal } from "../../../generalComponents/hooks/useModal";
import { AuthorizationsTable } from "./authorizationsTable";
import Modal from "../../../generalComponents/Modal";
import { AuthorizationForm } from "./authorizationForm";

export const AuthorizationsApp = ({ permisionsPendient, licencesPendient }) => {
	const [documentToEdit, setDocumentToEdit] = useState(null);
	const [isOpenModal1, openModal1, closeModal1] = useModal(false);
	const [newAprovalSataus, setNewAprovalSataus] = useState(null);
	return (
		<div>
			{documentToEdit && newAprovalSataus && (
				<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
					<AuthorizationForm
						documentToEdit={documentToEdit}
						setDocumentToEdit={setDocumentToEdit}
						closeModal1={closeModal1}
						newAprovalSataus={newAprovalSataus}
						setNewAprovalSataus={setNewAprovalSataus}
					/>
				</Modal>
			)}

			<AuthorizationsTable
				data={permisionsPendient}
				openModal1={openModal1}
				setDocumentToEdit={setDocumentToEdit}
				setNewAprovalSataus={setNewAprovalSataus}
			/>
			<AuthorizationsTable
				data={licencesPendient}
				licencia={true}
				openModal1={openModal1}
				setDocumentToEdit={setDocumentToEdit}
				setNewAprovalSataus={setNewAprovalSataus}
			/>
		</div>
	);
};
