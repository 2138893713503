import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import { PermisionsInventoryMasterApp } from "./permisionsInventoryMaster/permisionsInventoryMasterApp";
import "../../../styles/Inventory.css";
import { PermisionsInventoryGeneralApp } from "./permisionsInventoryGeneral/permisionsInventoryGeneralApp";
import { PermisionsInventoryTransfApp } from "./permisionsInventoryTransf/permisionsInventoryTransfApp";
import { PermisionsInventoryFormulasApp } from "./permisionsInventoryFormulas/permisionsInventoryFormulasApp";
import Loader from "../../../generalComponents/Loader";
import { askDataKeyValue } from "../../../../utils/actions/firebase/firebaseActions";
// import "../../../styles/MenuProcesos.css";

export default function InventoryPermisionsApp() {
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [showMaster, setShowMaster] = useState(false);
	const [showGeneral, setShowGeneral] = useState(false);
	const [showTransformations, setShowTransformations] = useState(false);
	const [showFormulas, setShowFormulas] = useState(false);
	const [users, setUsers] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		askUsers();
	}, []);

	const askUsers = async () => {
		setLoading(true);
		const nameCollection = "users";
		const key = "company";
		const value = company;
		const response = await askDataKeyValue(nameCollection, key, value);
		if (response.statusResponse === true) {
			setUsers(response.data);
			setLoading(false);
		} else {
			setUsers(null);
			setError(response.error);
			setLoading(false);
		}
	};

	const showItem = (value) => {
		if (value === "master") {
			setShowMaster(true);
		}
		if (value === "general") {
			setShowGeneral(true);
		}
		if (value === "transformations") {
			setShowTransformations(true);
		}
		if (value === "formulas") {
			setShowFormulas(true);
		}
	};
	const hideItem = (value) => {
		if (value === "master") {
			setShowMaster(false);
		}
		if (value === "general") {
			setShowGeneral(false);
		}
		if (value === "transformations") {
			setShowTransformations(false);
		}
		if (value === "formulas") {
			setShowFormulas(false);
		}
	};

	return (
		<div>
			{localUser.type === "2" ? (
				<div>
					<article className="titulo11">
						Permisos de Inventario ({company})
					</article>
					{loading ? (
						<Loader />
					) : (
						<div>
							{users && (
								<div>
									<div className="menuPermisionsInventory">
										<article className="titulo8">
											Maestro de Inventarios
										</article>
										{showMaster ? (
											<button
												className="btnShowPermisions"
												onClick={() => hideItem("master")}
											>
												Ocultar
											</button>
										) : (
											<button
												onClick={() => showItem("master")}
												className="btnShowPermisions"
											>
												Ver
											</button>
										)}
									</div>
									{showMaster && <PermisionsInventoryMasterApp users={users} />}

									<div className="menuPermisionsInventory">
										<article className="titulo8">Inventario General</article>
										{showGeneral ? (
											<button
												className="btnShowPermisions"
												onClick={() => hideItem("general")}
											>
												Ocultar
											</button>
										) : (
											<button
												onClick={() => showItem("general")}
												className="btnShowPermisions"
											>
												Ver
											</button>
										)}
									</div>
									{showGeneral && (
										<PermisionsInventoryGeneralApp users={users} />
									)}

									<div className="menuPermisionsInventory">
										<article className="titulo8">
											Autorización de Transformaciones ó Ajustes
										</article>
										{showTransformations ? (
											<button
												className="btnShowPermisions"
												onClick={() => hideItem("transformations")}
											>
												Ocultar
											</button>
										) : (
											<button
												onClick={() => showItem("transformations")}
												className="btnShowPermisions"
											>
												Ver
											</button>
										)}
									</div>
									{showTransformations && (
										<PermisionsInventoryTransfApp users={users} />
									)}

									<div className="menuPermisionsInventory">
										<article className="titulo8">
											Autorización de Aplicar Fórmulas directamente
										</article>
										{showFormulas ? (
											<button
												className="btnShowPermisions"
												onClick={() => hideItem("formulas")}
											>
												Ocultar
											</button>
										) : (
											<button
												onClick={() => showItem("formulas")}
												className="btnShowPermisions"
											>
												Ver
											</button>
										)}
									</div>
									{showFormulas && (
										<PermisionsInventoryFormulasApp users={users} />
									)}
								</div>
							)}
						</div>
					)}
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
}
