import { initializeApp } from "@firebase/app";
import "../../../components/styles/Production.css";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { useModal } from "../../generalComponents/hooks/useModal";
import { Redirect, useParams } from "react-router";
import NavbarBpw2 from "../../generalComponents/NavbarBpw2";
import { firebaseConfig } from "../../../config/firebaseConfig";
import Modal from "../../generalComponents/Modal";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import {
	addDataKey2,
	askDataKeyValue2Simple,
	askDataKeyValue3Simple,
	deleteDocumentById,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import { ProductionOrderViewForm } from "./productionOrderViewForm";
import { ProductionOrderViewTable } from "./productionOrderViewTable";
import NavbarBpw3 from "../../generalComponents/NavbarBpw3";

export const ProductionOrderViewApp = () => {
	let { productionOrder } = useParams();

	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [dataBase, setDataBase] = useState(null); //listado del productos en la Orden de Producción
	const [inventory, setInventory] = useState(null); //Maestro inventario de la empresa
	const [productionProductToEdit, setProductionProductToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [dataHeader, setDataHeader] = useState(null);

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);
	const nameCollection = "productionProduct-" + company;
	const nameCollection2 = "productionOrders-" + company;

	const askDataHeader = () => {
		setLoading(true);
		const q = query(
			collection(db, nameCollection2),
			where("productionOrder", "==", productionOrder)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataHeader(dataList[0]);
				setLoading(false);
			},
			(error) => {
				setDataHeader(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askProductionProduct = async () => {
		setLoading(true);
		const q = query(
			collection(db, nameCollection),
			where("productionOrder", "==", productionOrder)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askInventory = async () => {
		setLoading(true);
		const nameCollection = "inventoryMaster-" + company;
		const q = query(collection(db, nameCollection));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setInventory(dataList);
				setLoading(false);
			},
			(error) => {
				setInventory(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askDataHeader();
		askProductionProduct();
		askInventory();
	}, []);

	const clickCrear = () => {
		setProductionProductToEdit(null);
		openModal1();
	};

	async function crateProductionProduct(data) {
		setLoading(true);
		const response = await addDataKey2(
			nameCollection,
			data,
			"productionOrder",
			productionOrder,
			"refProductionProduct",
			data.refProductionProduct
		);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		await askProductionProduct();
		setLoading(false);
	}

	async function updateProductionProduct(data) {
		setLoading(true);
		console.log("--- empieza updateProductionProcut con data: ", data);
		const docId = data.id;
		const changes = data;
		const key2 = "refProductionProduct";
		const value2 = data.refProductionProduct;
		const key3 = "productionOrder";
		const value3 = data.productionOrder;
		const response = await askDataKeyValue2Simple(
			nameCollection,
			key2,
			value2,
			key3,
			value3
		);
		console.log("response:", response);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				if (response.data[0].id !== docId) {
					setError(
						"Este Producto ya está en la lista, si desea modifique cantidades a producir..."
					);
				} else {
					console.log("--- va a actualizar changes:", changes);
					await updateDocumentById(nameCollection, docId, changes);
				}
			}
		}
		setLoading(false);
	}

	async function deleteProductionProduct(data) {
		const docId = data.id;
		let isDelete = window.confirm(
			`¿Está seguro de eliminar este producto de la Orden de Producción ?`
		);
		if (isDelete) {
			setLoading(true);
			setError("");
			await deleteDocumentById(nameCollection, docId)
				.then((res) => {
					console.log("respuesta", res);
				})
				.catch((error) => {
					console.log("error", error);
					setError(error);
				});
			setError(null);
			setLoading(false);
		} else {
			return;
		}
	}

	return (
		<div>
			{localUser.type === "2" || localUser.type === "3" ? (
				<div>
					{localUser.type === "2" ? <NavbarBpw2 /> : <NavbarBpw3 />}

					{dataHeader && (
						<div className="headerPO">
							<h4>Orden de Producción: {productionOrder}</h4>
							<h6>Fecha: {dataHeader.date1}</h6>
							<h6>Cliente: {dataHeader.client}</h6>
							<h6>Orden de Compra: {dataHeader.purshaseOrder}</h6>
							<h6>Status: {dataHeader.status}</h6>
						</div>
					)}

					<article>
						{/* <p>Aquí se gestionan listas de Productos de la Orden de Producción </p> */}
						{/* <h3 className="titleProcess">PRODUCTOS SOLICITADOS ({company})</h3> */}
						{inventory && (
							<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
								<ProductionOrderViewForm
									company={company}
									crateProductionProduct={crateProductionProduct}
									updateProductionProduct={updateProductionProduct}
									productionProductToEdit={productionProductToEdit}
									setProductionProductToEdit={setProductionProductToEdit}
									closeModal={closeModal1}
									productionOrder={productionOrder}
									inventory={inventory}
								/>
							</Modal>
						)}
						{loading ? (
							<Loader />
						) : (
							<div>
								{error && <Message mensaje={error} bgColor="#dc3545" />}
								{dataBase ? (
									dataBase.length >= 0 ? (
										<div>
											<button
												onClick={clickCrear}
												className="btn-create-document"
											>
												Nuevo Producto
											</button>
											<ProductionOrderViewTable
												data={dataBase}
												setProductionProductToEdit={setProductionProductToEdit}
												deleteProductionProduct={deleteProductionProduct}
												openModal={openModal1}
											/>
										</div>
									) : (
										<h3 className="alertEmpty">
											No hay Productos para mostrar
										</h3>
									)
								) : (
									<h3 className="alertEmpty">Buscando...</h3>
								)}
							</div>
						)}
					</article>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};
