import { initializeApp } from "@firebase/app";
import React, { useState, useEffect } from "react";
import {
	query,
	where,
	onSnapshot,
	orderBy,
	collection,
	getFirestore,
	getDocs,
} from "@firebase/firestore";
import "@firebase/firestore";
import { useModal } from "../../generalComponents/hooks/useModal";
import Modal from "../../generalComponents/Modal";
import Loader from "../../generalComponents/Loader";
import CrudRegProcesosForm from "./CrudRegProcesosForm";
import Message from "../../generalComponents/Message";
import CrudRegProcesosTable from "./CrudRegProcesosTable";
import "../../styles/RegProcesos.css";
import {
	addRegProcess,
	askDataKeyValue3Simple,
} from "../../../utils/actions/firebase/firebaseActions";
import { firebaseConfig } from "../../../config/firebaseConfig";
import DetalleRegProcesos from "./DetalleRegProcesos";

const CrudRegProcessApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const initialForm = {
		status: "Todas",
		initialDate: "",
		finalDate: "",
	};
	const [form, setForm] = useState(initialForm);
	const [dataBase, setDataBase] = useState(null); //procesos registrados
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [verDetalle, setVerDetalle] = useState(false);
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const email = localUser.email;
	const area = localUser.area;
	const [processes, setProcesses] = useState(null);
	const [options, setOptions] = useState(null);
	const [elToShow, setelToShow] = useState(null);

	useEffect(() => {
		askRegProcess3days();
	}, []);

	const preguntaRegProcesos = async () => {
		setLoading(true);
		const q = query(
			collection(db, "processRegister-" + company),
			where("agency", "==", localUser.agency),
			where("area", "==", localUser.area),
			orderBy("date", "desc")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				// console.log("dataList:", dataList);
				setDataBase(dataList);
				// setRefresh(true);
				setLoading(false);
			},
			(error) => {
				console.log("error:", error);
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askRegProcess3days = () => {
		const fechita1 = new Date(); // Fecha actual
		const fechitaHace7Dias = new Date(fechita1); // Crear una copia de la fecha actual
		// Restarle 7 días a la copia
		fechitaHace7Dias.setDate(fechitaHace7Dias.getDate() - 3);
		// console.log("actual: ", fechita1);
		// console.log("hace 7 días: ", fechitaHace7Dias);

		setLoading(true);
		const q = query(
			collection(db, "processRegister-" + company),
			where("agency", "==", localUser.agency),
			where("area", "==", localUser.area),
			where("date", ">=", fechitaHace7Dias),
			orderBy("date", "desc")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				// console.log("dataList:", dataList);
				setDataBase(dataList);
				// setRefresh(true);
				setLoading(false);
			},
			(error) => {
				console.log("error:", error);
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const createRegProcess = async (data) => {
		// setLoading(true);

		await addRegProcess(data, company).then((response) => {
			console.log("primera respuesta:", response);
			if (response.statusResponse === false) {
				setError(response.error);
				// setLoading(false);
			}
			return response;
		});
	};

	const [isOpenModal1, openModal1, closeModal1] = useModal(false); //uno para cada modal que se utilice

	const askProcess = async () => {
		setLoading(true);
		const nameCollection = "process-" + company;
		const key2 = "area";
		const value2 = area;
		const key3 = "release";
		const value3 = "si";
		const key4 = "active";
		const value4 = "si";

		const response = await askDataKeyValue3Simple(
			nameCollection,
			key2,
			value2,
			key3,
			value3,
			key4,
			value4
		);
		if (response.statusResponse === true) {
			setProcesses(response.data);
			// console.log("*** datos:", response.data);
			// console.log(">>>>> Procesess: ", processes);
			setOptions([]);

			// console.log("*** options:", options);
			var op = [];
			response.data.map((proceso) => {
				op.push({ label: proceso.process, value: proceso.process });
				// console.log("hace un push", proceso.process);
			});
			setOptions(op);
			setLoading(false);
		} else {
			setProcesses([]);
			setOptions([]);
			setError(response.error);

			setLoading(false);
		}
	};
	const clickCrear = async () => {
		await askProcess();
		// setProcessToEdit(null);
		// setAlgo(true);
		openModal1();
	};

	const irVerDetalle = (el) => {
		setelToShow(el);
		setVerDetalle(true);
	};

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleReset = (e) => {
		// const processInitial = regProcess.filter(
		// 	(proceso) => proceso.date.seconds >= pastDate / 1000
		// );
		// // setRegProcessFilter(regProcess);
		// setRegProcessFilter(processInitial);
		// setRegProcessFilter2(processInitial);
		setForm(initialForm);
	};

	function formatDate(inputDate) {
		const parts = inputDate.split("-");
		const formattedDate = new Date(parts[0], parts[1] - 1, parts[2]);

		const day = formattedDate.getDate();
		const month = formattedDate.getMonth() + 1; // Los meses en JavaScript son 0-indexados
		const year = formattedDate.getFullYear();

		return `${day}/${month}/${year}`;
	}

	const dateFormToTimestamp = (fechaString) => {
		const partesFecha = fechaString.split("-");
		const fecha = new Date(partesFecha[0], partesFecha[1] - 1, partesFecha[2]);
		fecha.setDate(fecha.getDate());

		console.log("fecha---:", fecha);
		return fecha;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (form.initialDate === "" || form.finalDate === "") {
			alert("debe seleccionar rango de fechas para filtrar...");
			return;
		}
		const newInitialDate = dateFormToTimestamp(form.initialDate);
		const newFinalDate = dateFormToTimestamp(form.finalDate);
		const nameCollection = "processRegister-" + company;
		// console.log("dateIni:", form.initialDate, "--->", newInitialDate);
		// console.log("dateFin:", form.finalDate, "--->", newFinalDate);

		let q = query(
			collection(db, nameCollection),
			where("agency", "==", localUser.agency),
			where("area", "==", localUser.area),
			where("date", ">=", newInitialDate),
			where("date", "<=", newFinalDate),
			orderBy("date", "desc")
		);

		await getDocs(q)
			.then((querySnapshot) => {
				// console.log("querySnapshot: ", querySnapshot);
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				// console.log("dataList", dataList);
				setDataBase(dataList);
			})
			.catch((error) => {
				console.log("error: ", error);
				setError(error);
			});
	};

	return (
		<div>
			<div>
				{verDetalle === false ? (
					<article>
						{/* <p>Aquí se gestionan listas de procesos</p> */}
						<h3 className="titleProcess">
							SUPERVISION DE PROCESOS ({company})
						</h3>
						{/* {console.log("+++++ procesess:", processes)} */}
						{options && (
							<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
								<CrudRegProcesosForm
									createRegProcess={createRegProcess}
									company={company}
									agency={localUser.agency}
									creator={email}
									area={area}
									closeModal={closeModal1}
									processes={processes}
									options={options}
								/>
							</Modal>
						)}

						{loading ? (
							<Loader />
						) : (
							<div>
								<button onClick={clickCrear} className="btn-crear-proceso">
									Crear Proceso
								</button>

								{error && <Message mensaje={error} bgColor="#dc3545" />}
								{dataBase && dataBase.length > 0 ? (
									<div>
										<div>
											<h3 className="titulo3">
												Area: {dataBase[0].area} ____ Sucursal:{" "}
												{dataBase[0].agency}
											</h3>
										</div>
										<form onSubmit={handleSubmit} className="filter-procesos">
											{form.initialDate === "" && (
												<div>
													<p className="alertaFormulario">
														Por defecto se muestran procesos de los últimos 3
														días
													</p>
												</div>
											)}
											<label className="labelForm1">Fecha Inicial</label>
											<input
												type="date"
												name="initialDate"
												placeholder="Fecha Inicial"
												onChange={handleChange}
												value={form.initialDate}
												className="input3"
											/>
											<label className="labelForm1">Fecha Final</label>
											<input
												type="date"
												name="finalDate"
												placeholder="Fecha final"
												onChange={handleChange}
												value={form.finalDate}
												className="input3"
											/>
											{/* <br /> */}

											<input
												type="submit"
												value="Buscar"
												className="btn-filter-procesos"
											/>
											<input
												type="reset"
												value="Limpiar"
												onClick={handleReset}
												className="btnCleanFilterProcess"
											/>
										</form>

										<CrudRegProcesosTable
											regProcess={dataBase}
											irVerDetalle={irVerDetalle}
											company={company}
										/>
									</div>
								) : (
									<article className="titulo3">
										No hay procesos para mostrar
									</article>
								)}
							</div>
						)}
					</article>
				) : (
					elToShow && (
						<DetalleRegProcesos
							setVerDetalle={setVerDetalle}
							elToShow={elToShow}
							setelToShow={setelToShow}
						/>
					)
				)}
			</div>
		</div>
	);
};

export default CrudRegProcessApp;
