import React, { useEffect, useState } from "react";
import { separadoresDeMil } from "../../../../../utils/actions/firebase/firebaseActions";

export const ParticularShowTable = ({ particularInventory }) => {
	const [dataShow, setDataShow] = useState(particularInventory);

	useEffect(() => {
		setDataShow(particularInventory);
	}, [particularInventory]);

	function handleSearch(e) {
		if (e.target.value === "") {
			return setDataShow(particularInventory);
		}
		setDataShow(
			particularInventory.filter(
				(item) =>
					item.refInventory.toLowerCase().includes(e.target.value) ||
					item.processNumber.toLowerCase().includes(e.target.value) ||
					item.description.toLowerCase().includes(e.target.value)
			)
		);
	}

	return (
		<div>
			<div className="document-search">
				<h2 className="title-search">Buscar</h2>
				<input
					type="search"
					name=""
					id=""
					onChange={handleSearch}
					className="search-box"
				/>
			</div>
			<table className="tabla-inventory">
				<thead>
					<tr className="fila-tabla-procesos-">
						<th className="tabla-column-etiqueta">Referencia</th>
						<th className="tabla-column-etiqueta">Descripción</th>
						<th className="tabla-column-etiqueta">Cantidad</th>
						<th className="tabla-column-etiqueta">Proceso</th>
						<th className="tabla-column-etiqueta">Costo/U</th>
					</tr>
				</thead>
				<tbody>
					{dataShow.map((productList, index) => (
						<tr key={index}>
							<td className="tabla-column-data">{productList.refInventory}</td>
							<td className="tabla-column-data">{productList.description}</td>
							<td className="tabla-column-data">
								{separadoresDeMil(productList.amount)}
							</td>
							<td className="tabla-column-data">{productList.processNumber}</td>
							{productList.cost && (
								<td className="tabla-column-data">
									{productList.cost.toFixed(2)}
								</td>
							)}
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
