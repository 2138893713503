import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { useModal } from "../../generalComponents/hooks/useModal";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import Modal from "../../generalComponents/Modal";
import {
	addCourse,
	deleteDocumentById,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import { firebaseConfig } from "../../../config/firebaseConfig";
import { CoursesForm } from "./CoursesForm";
import { CoursesTable } from "./CoursesTable";

export const CoursesApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [dataBase, setDataBase] = useState(null); //listado de Cursos
	const [courseToEdit, setCourseToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [presentations, setPresentations] = useState([]); //material de cursos
	const [evaluations, setEvaluations] = useState([]);
	const [companies, setCompanies] = useState([]);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const askCourses = async () => {
		setLoading(true);
		const q = query(collection(db, "coursesList-" + company));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askPresentations = async () => {
		setLoading(true);
		const q = query(collection(db, "coursesMaterial-" + company));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setPresentations(dataList);
				setLoading(false);
			},
			(error) => {
				setPresentations([]);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askEvaluations = async () => {
		setLoading(true);
		const q = query(collection(db, "courseEvaluations-" + company));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setEvaluations(dataList);
				setLoading(false);
			},
			(error) => {
				setEvaluations([]);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askCompanies = async () => {
		setLoading(true);
		const q = query(collection(db, "accounts"));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setCompanies(dataList);
				setLoading(false);
			},
			(error) => {
				setCompanies([]);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askCourses();
		askPresentations();
		askEvaluations();
		askCompanies();
	}, []);

	const createCourse = async (data) => {
		setLoading(true);
		data.company = company;
		console.log("curso a crear:", data);

		const response = await addCourse(data);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		setLoading(false);
	};

	const updateCourse = async (data) => {
		setLoading(true);
		const nameCollection = "coursesList-" + company;
		const docId = data.id;
		await updateDocumentById(nameCollection, docId, data);
		setLoading(false);
	};

	const deleteCourse = async (data) => {
		const nameCollection = "coursesList-" + company;
		const docId = data.id;
		let isDelete = window.confirm(`¿Estás seguro de eliminar este Curso ?`);
		if (isDelete) {
			setLoading(true);
			setError("");
			await deleteDocumentById(nameCollection, docId)
				.then((res) => {
					console.log("respuesta", res);
				})
				.catch((error) => {
					console.log("error", error);
					setError(error);
				});
			setError(null);
			setLoading(false);
		} else {
			return;
		}
	};

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	const clickCrear = () => {
		setCourseToEdit(null);
		openModal1();
	};

	// const clickCambiarBd = async () => {
	// 	const q = query(collection(db, "coursesEvaluationQuestions"));
	// 	onSnapshot(q, (querySnapshot) => {
	// 		let dataList = querySnapshot.docs.map((d) => ({
	// 			...d.data(),
	// 		}));
	// 		console.log("lo que se va a pasar: ", dataList);
	// 		dataList.map(async (document) => {
	// 			await addDoc(
	// 				collection(db, "coursesEvaluationQuestions"),
	// 				document
	// 			).then(async (res) => {
	// 				if (res.id) {
	// 					const docRef = doc(db, "coursesEvaluationQuestions", res.id);
	// 					await updateDoc(docRef, {
	// 						id: res.id,
	// 					});
	// 				}
	// 			});
	// 		});
	// 	});
	// };

	return (
		<div>
			{/* <button onClick={clickCambiarBd} className="btn-create-document">
				Cambio en Firebase
			</button> */}

			<article>
				<h2 className="titleProcess">CURSOS </h2>
				<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
					<CoursesForm
						presentations={presentations}
						evaluations={evaluations}
						companies={companies}
						createCourse={createCourse}
						updateCourse={updateCourse}
						courseToEdit={courseToEdit}
						setCourseToEdit={setCourseToEdit}
						closeModal={closeModal1}
						setError={setError}
						isOpenModal1={isOpenModal1}
					/>
				</Modal>

				{loading && <Loader />}
				{error && <Message mensaje={error} bgColor="#dc3545" />}

				{dataBase ? (
					dataBase.length >= 0 ? (
						<div>
							<button onClick={clickCrear} className="btn-create-document">
								Nuevo Curso
							</button>
							<CoursesTable
								courses={dataBase}
								setCourseToEdit={setCourseToEdit}
								deleteCourse={deleteCourse}
								openModal1={openModal1}
							/>
						</div>
					) : (
						<h3 className="alertEmpty">No hay Cursos para mostrar</h3>
					)
				) : (
					<h3 className="alertEmpty">Buscando...</h3>
				)}
			</article>
		</div>
	);
};
