import React, { useEffect, useState } from "react";
import Loader from "../../../generalComponents/Loader";

export const EntitiesForm = ({
	company,
	createEntitie,
	updateEntitie,
	entitieToEdit,
	setEntitieToEdit,
	closeModal,
	type,
}) => {
	const initialForm = {
		id: null,
		type: type,
		name: "",
	};

	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (entitieToEdit) {
			setForm(entitieToEdit);
		} else {
			setForm(initialForm);
		}
	}, [entitieToEdit]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (form.name === "") {
			alert("Debe colocar nombre de entidad para crear");
			return;
		}
		setLoading(true);
		if (form.id === null) {
			createEntitie(form);
		} else {
			updateEntitie(form);
		}

		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setEntitieToEdit(null);
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<article className="titulo1">
					{entitieToEdit ? "Editar Entidad" : "Agregar Nueva Entidad"}
				</article>
				<form onSubmit={handleSubmit} className="form-procesos">
					<article className="title2-account">{company}</article>
					<article className="title2-account">Tipo de entidad: {type}</article>

					{loading ? (
						<Loader />
					) : (
						<div>
							{entitieToEdit ? (
								<div>
									<article className="titulo8">Nombre de la Entidad:</article>
									<input
										type="text"
										name="name"
										placeholder="Nombre de la Entidad"
										onChange={handleChange}
										value={form.name}
										className="input1"
										required
									/>
								</div>
							) : (
								<input
									type="text"
									name="name"
									placeholder="Nombre de la Entidad"
									onChange={handleChange}
									value={form.name}
									className="input1"
									required
								/>
							)}

							<br />
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={closeModal}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
