import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
	getDocs,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../../../config/firebaseConfig";
import { useModal } from "../../../../generalComponents/hooks/useModal";
import Loader from "../../../../generalComponents/Loader";
import Message from "../../../../generalComponents/Message";
import { InventoryTransferOutForm } from "./inventoryTransferOutForm";
import Modal from "../../../../generalComponents/Modal";
import { InventoryTransferOutTable } from "./inventoryTransferOutTable";

export const InventoryTransferOutApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [dataBase, setDataBase] = useState(null); //alamacena bse de datos de las transfeencias pendientes dede alamacén general
	const [generalInventory, setGeneralInventory] = useState(null);
	const [aviableInventory, setAviableInventory] = useState(null);
	const [modalAvialbe, setModalAvialbe] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	const askInventoryTransfer = (from) => {
		const nameCollection = "inventoryTransfer-" + company;
		const q = query(
			collection(db, nameCollection),
			where("from", "==", from),
			where("status", "==", "pendiente")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);

				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askInvetoryInTransit = async (from) => {
		let result = null;
		const nameCollection = "inventoryInTransit-" + company;
		const q = query(collection(db, nameCollection), where("from", "==", from));
		await getDocs(q)
			.then((querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				result = dataList;
			})
			.catch((error) => {
				result.error = error;
				result = [];
			});
		return result;
	};

	const askGeneralInventory = async () => {
		setLoading(true);
		const pendingTransfer = await askInvetoryInTransit("warehouse");
		const nameCollection = "inventoryGeneral-" + company;
		const q = query(collection(db, nameCollection));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setGeneralInventory(dataList);
				if (pendingTransfer.length === 0) {
					setAviableInventory(dataList);
				} else {
					pendingTransfer.map((productPending) => {
						const productToModify = dataList.filter(
							(product) => product.refInventory === productPending.refInventory
						);
						const newAviability = dataList.filter(
							(product) => product.refInventory !== productPending.refInventory
						);
						productToModify[0].amount =
							productToModify[0].amount - productPending.amount;
						newAviability.push(productToModify[0]);
						dataList = newAviability;
					});

					setAviableInventory(dataList);
				}

				setLoading(false);
			},
			(error) => {
				setGeneralInventory(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askInventoryTransfer("warehouse");
		askGeneralInventory();
	}, []);

	const clickCrear = () => {
		setModalAvialbe(true);
		openModal1();
	};

	return (
		<div>
			{/* <p>Aquí se gestionan las transferencias de iventario de salida</p> */}
			<article className="titulo11">Traslados de Salida ({company})</article>
			{aviableInventory && modalAvialbe && (
				<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
					<InventoryTransferOutForm
						aviableInventory={aviableInventory}
						closeModal={closeModal1}
					/>
				</Modal>
			)}
			{loading ? (
				<Loader />
			) : (
				<div>
					{error && <Message mensaje={error} bgColor="#dc3545" />}
					<button onClick={clickCrear} className="btn-crear-proceso">
						Crear Traslado
					</button>
					{dataBase ? (
						<div>
							<InventoryTransferOutTable dataBase={dataBase} />
						</div>
					) : (
						<article className="titulo2">
							No hay Traslados de Salida pendientes para mostrar...
						</article>
					)}
				</div>
			)}
		</div>
	);
};
