import { initializeApp } from "@firebase/app";
import React, { useState, useEffect } from "react";
import {
	query,
	onSnapshot,
	orderBy,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { Redirect, useParams } from "react-router-dom";
import { useModal } from "../../generalComponents/hooks/useModal";
import Modal from "../../generalComponents/Modal";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import "../../styles/RegProcesos.css";
import {
	addRegProcess,
	askDataKeyValue3Simple,
} from "../../../utils/actions/firebase/firebaseActions";
import { firebaseConfig } from "../../../config/firebaseConfig";
import NavbarBpw from "../../generalComponents/NavbarBpw";
import RegProcessAdminA01Table from "./RegProcessAdminA01Table";

export const RegProcessAdminA01App = () => {
	let { company } = useParams();
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [dataBase, setDataBase] = useState(null); //procesos registrados
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [verDetalle, setVerDetalle] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const email = localUser.email;
	const area = localUser.area;
	const [algo, setAlgo] = useState(null);
	const [processes, setProcesses] = useState(null);
	const [options, setOptions] = useState(null);

	const preguntaRegProcesos = async () => {
		setLoading(true);
		const q = query(
			collection(db, "processRegister"),
			// where("agency", "==", localUser.agency),
			// where("area", "==", localUser.area),
			orderBy("date", "desc")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				console.log("error:", error);
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		preguntaRegProcesos();
	}, []);

	const createRegProcess = async (data) => {
		setLoading(true);
		const response = await addRegProcess(data, company);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		setLoading(false);
	};

	const [isOpenModal1, openModal1, closeModal1] = useModal(false); //uno para cada modal que se utilice

	const askProcess = async () => {
		setLoading(true);
		const nameCollection = "process";
		const key2 = "area";
		const value2 = area;
		const key3 = "release";
		const value3 = "si";
		const key4 = "active";
		const value4 = "si";

		const response = await askDataKeyValue3Simple(
			nameCollection,
			key2,
			value2,
			key3,
			value3,
			key4,
			value4
		);
		if (response.statusResponse === true) {
			setProcesses(response.data);
			// console.log("*** datos:", response.data);
			// console.log(">>>>> Procesess: ", processes);
			setOptions([]);

			// console.log("*** options:", options);
			var op = [];
			response.data.map((proceso) => {
				op.push({ label: proceso.process, value: proceso.process });
				// console.log("hace un push", proceso.process);
			});
			setOptions(op);
			// console.log("---- options:", options);
			setLoading(false);
		} else {
			setProcesses([]);
			setOptions([]);
			setError(response.error);

			setLoading(false);
		}
	};
	const clickCrear = async () => {
		await askProcess();
		// setProcessToEdit(null);
		// setAlgo(true);
		openModal1();
	};

	const irVerDetalle = () => {
		setVerDetalle(true);
	};
	return (
		<div>
			{localUser.type === "1" ? (
				<div>
					<article>
						<NavbarBpw />
						{/* <p>Aquí se gestionan listas de procesos</p> */}
						<h3 className="titleProcess">
							Gestión de Procesos Registrados ({company})
						</h3>
						{options && (
							<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
								{/* <CrudRegProcesosForm
									createRegProcess={createRegProcess}
									company={company}
									agency={localUser.agency}
									creator={email}
									area={area}
									closeModal={closeModal1}
									processes={processes}
									options={options}
								/> */}
							</Modal>
						)}

						{loading ? (
							<Loader />
						) : (
							<div>
								{/* <button onClick={clickCrear} className="btn-crear-proceso">
										Crear Proceso
									</button> */}

								{error && <Message mensaje={error} bgColor="#dc3545" />}
								{dataBase ? (
									<div>
										<RegProcessAdminA01Table
											regProcess={dataBase}
											irVerDetalle={irVerDetalle}
											company={company}
										/>
									</div>
								) : (
									<h3>No hay procesos para mostrar</h3>
								)}
							</div>
						)}
					</article>
					{verDetalle === true && (
						<Redirect to="/supervisor-company/reg_procesos/ver_orden" />
					)}
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};
