import React, { useState, useEffect } from "react";
import {
	askDataKeyValue2Simple,
	askDataKeyValue3Simple,
} from "../../../utils/actions/firebase/firebaseActions";
import Loader from "../../generalComponents/Loader";

const DetalleRegProcesosFormAsign = ({
	localUser,
	closeModal,
	assignment,
	updateUsuarioAsignado,
	setRefresh,
}) => {
	// const initialForm = {
	// 	id: assignment.id,
	// 	empresa: assignment.empresa,
	// 	sucursal: assignment.sucursal,
	// 	assignment: assignment.assignment,
	// 	numProceso: assignment.numProceso,
	// 	proceso: assignment.proceso,
	// 	etapa: assignment.etapa,
	// 	estado: assignment.estado,
	// };
	const initialForm = assignment;

	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [users, setUsers] = useState([]);

	const preguntaUsuariosAsignar = async () => {
		setLoading(true);
		let response = null;
		const nameCollection = "users";
		const key = "company";
		const value = localUser.company;
		let key2 = "agency";
		let value2 = localUser.agency;
		if (localUser.area === "Gestión") {
			//company,type
			key2 = "type";
			value2 = "3";
			response = await askDataKeyValue2Simple(
				nameCollection,
				key,
				value,
				key2,
				value2
			);
		} else {
			const key3 = "area";
			const value3 = localUser.area;
			response = await askDataKeyValue3Simple(
				nameCollection,
				key,
				value,
				key2,
				value2,
				key3,
				value3
			);
		}

		if (response.statusResponse === true) {
			setUsers(response.data);
			setLoading(false);
		} else {
			setUsers([]);
			setError(response.error);
			setLoading(false);
		}
	};

	useEffect(() => {
		preguntaUsuariosAsignar();
	}, []);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (
			form.assignment === "" ||
			form.assignment === "Seleccione un correo para asignar"
		) {
			alert(
				"Debe selecionar un Correo para asingar el responsable de la Etapa del proceso"
			);
			return;
		}
		if (form.assignment === assignment.assignment) {
			setLoading(false);
			closeModal();
			handleReset();
			return;
		}
		setLoading(true);
		updateUsuarioAsignado(form);
		handleReset();
		setLoading(false);
		closeModal();
		setRefresh(true);
	};

	const handleReset = (e) => {
		setForm(initialForm);
	};

	return (
		<div>
			<div className="cont1">
				<h3 className="titulo1">
					Seleccione el Usuario responsable de la Etapa
				</h3>
				<form onSubmit={handleSubmit} className="form-cuentas">
					{/* <h2>Empresa: {localUser.empresa}</h2>
					<h2>Sucursal: {localUser.sucursal}</h2> */}
					<h2>
						Proceso: {assignment.process} ({assignment.processNumber})
					</h2>
					<h2>Etapa: {assignment.stage}</h2>
					{loading ? (
						<Loader />
					) : (
						<div>
							<select
								name="assignment"
								onChange={handleChange}
								value={form.assignment}
								className="input1"
							>
								<option defaultValue>Seleccione un correo para asignar</option>
								{users.map((user) => (
									<option key={user.id} value={user.email}>
										{user.email}
									</option>
								))}
							</select>
							{/* <br /> */}
							<hr />

							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={closeModal}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default DetalleRegProcesosFormAsign;
