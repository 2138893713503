import React, { useState, useEffect } from "react";
import { initializeApp } from "@firebase/app";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../config/firebaseConfig";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import { StudentsPendingTable } from "./StudentsPendingTable";
import { StudentsHistorialTable } from "./StudentsHistorialTable";
import { ViewCourseApp } from "../viewCourses/ViewCourseApp";

export const StudentsApp = () => {
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const app = initializeApp(firebaseConfig);
	const db2 = getFirestore(app);
	const [studentCourses, setStudentCourses] = useState(null);
	const [selectedCourse, setSelectedCourse] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const askSutudentCourses = () => {
		setLoading(true);
		const collectionName = "coursesAsignment-" + company;

		const key2 = "student";
		const value2 = localUser.email;
		const q = query(collection(db2, collectionName), where(key2, "==", value2));
		onSnapshot(q, (querySnapshot) => {
			const dataList = [];
			querySnapshot.forEach((doc) => {
				dataList.push(doc.data());
			});
			setStudentCourses(dataList);
			// console.log("result =", dataList);
			setLoading(false);
		});
	};

	useEffect(() => {
		askSutudentCourses();
	}, [setSelectedCourse]);

	return (
		<div>
			{selectedCourse ? (
				<ViewCourseApp
					selectedCourse={selectedCourse}
					setSelectedCourse={setSelectedCourse}
				/>
			) : (
				<div>
					{loading && <Loader />}
					{error && <Message mensaje={error} bgColor="#dc3545" />}
					<h3 className="titleProcess">CURSOS PENDIENTES </h3>
					{studentCourses &&
						(studentCourses.lenght === 0 ? (
							<p>... No tiene cursos pendientes</p>
						) : (
							<StudentsPendingTable
								studentCourses={studentCourses}
								setSelectedCourse={setSelectedCourse}
								// deleteData={deleteArea}
							/>
						))}

					<hr />
					<hr />
					<h3 className="titleProcess">HISTORIAL DE CURSOS</h3>
					{studentCourses &&
						(studentCourses.lenght === 0 ? (
							<p>... No tiene cursos pendientes</p>
						) : (
							<StudentsHistorialTable studentCourses={studentCourses} />
						))}
				</div>
			)}
		</div>
	);
};
