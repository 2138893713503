import React from "react";
import { Link, Redirect } from "react-router-dom";
import NavbarBpw4 from "../components/generalComponents/NavbarBpw4";
import imagen from "../components/generalComponents/images/logoBPW.png";

const Worker = () => {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	return (
		<div>
			{localUser.type === "4" ? (
				<div>
					<NavbarBpw4 />
					<div className="containerIcon">
						<h2>B I E N V E N I D O</h2>
						<br />
						<br />
						<br />
						<img src={imagen} className="imgStart" />
					</div>
					{/* <Redirect to="/worker-company/assignments" /> */}
					{/* <Link to="/">Inico</Link>
					<Link to="/supervisor-company/reg_procesos" className="enlaces">
						Supervisión Procesos
					</Link>
					<Link to="/users">Informes</Link> */}
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};

export default Worker;
