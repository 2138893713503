import React from "react";

export const StudentsPendinglTableRow = ({ el, setSelectedCourse }) => {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const fechita1 = new Date();
	const fechaActual1 = fechita1.toLocaleDateString();
	const date1 = new Date(el.initialDate.seconds * 1000).toLocaleDateString();
	const date2 = new Date(el.finalDate.seconds * 1000).toLocaleDateString();

	const buttonAction = (el) => {
		setSelectedCourse(el);
		// openModal1();
	};
	return (
		<tr className="fila-tabla-cuenstas">
			<td className="colTableDocuments1">{el.course}</td>
			<td className="colTableDocuments1">{el.status}</td>
			<td className="colTableDocuments1">{Math.round(el.calification)}</td>
			<td className="colTableDocuments1">{date1}</td>
			<td className="colTableDocuments1">{date2}</td>
			<td className="colTableDocuments1">
				<button onClick={() => buttonAction(el)} className="btnEditDocuments">
					Ingresar
				</button>
			</td>
		</tr>
	);
};
