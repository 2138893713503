import React from "react";

export const EmployeesTableRow = ({
	el,
	setDocumentToEdit,
	setShowEmploye,
}) => {
	const buttonAction = (el) => {
		setDocumentToEdit(el);
		setShowEmploye(true);
	};

	return (
		<tr className="fila-tabla-cuenstas">
			<td className="colTableDocuments0">{el.document}</td>
			<td className="colTableDocuments2">{el.name}</td>
			<td className="colTableDocuments1">{el.email}</td>
			<td className="colTableDocuments1">{el.phone}</td>
			<td className="colTableDocuments1">{el.area}</td>
			<td className="colTableDocuments1">{el.status}</td>

			<td className="colTableDocuments2">
				<button onClick={() => buttonAction(el)} className="btnViewDocuments">
					Ver
				</button>
			</td>
		</tr>
	);
};
