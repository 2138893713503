import { initializeApp } from "@firebase/app";
import React, { useState, useEffect } from "react";
import {
	query,
	onSnapshot,
	collection,
	getFirestore,
	where,
} from "@firebase/firestore";
import Loader from "../../generalComponents/Loader";
import { useModal } from "../../generalComponents/hooks/useModal";
import Modal from "../../generalComponents/Modal";
import "../../styles/HumanResources.css";
import { firebaseConfig } from "../../../config/firebaseConfig";
import { RequestsFrom } from "./requestsFrom";
import { RequestsTable } from "./requestsTable";
import { RequestsEditForm } from "./requestsEditForm";

export const RequestsApp = ({ employeeData }) => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [dataBase, setDataBase] = useState(null);
	const [adminRequests, setAdminRequests] = useState(false);
	const [documentToEdit, setDocumentToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);
	const [isOpenModal2, openModal2, closeModal2] = useModal(false);

	useEffect(() => {
		askPermisions();
		askRequests();
	}, []);

	const askPermisions = () => {
		setLoading(true);
		const nameCollection = "noveltiesViewPermisions-" + company;
		const q = query(
			collection(db, nameCollection),
			where("typePermision", "==", "RequestsView")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				if (dataList[0].requestsViewPermision.includes(localUser.email)) {
					setAdminRequests(true);
				}
				setLoading(false);
			},
			(error) => {
				setError(error);
				setLoading(false);
			}
		);
	};

	const askRequests = () => {
		setLoading(true);
		const nameCollection = "requestsEmployees-" + company;
		const q = query(collection(db, nameCollection));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const clickCrear = () => {
		openModal1();
	};

	return (
		<div>
			{loading ? (
				<Loader />
			) : (
				<div>
					<article className="titulo9">Gestión de Solicitudes</article>
					<div>
						<button onClick={clickCrear} className="btn-create-document">
							Hacer una Solicitud
						</button>

						{/* {!adminRequests && (
							<button onClick={clickCrear} className="btn-create-document">
								Hacer una Solicitud
							</button>
						)} */}

						<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
							<RequestsFrom
								employeeData={employeeData}
								closeModal1={closeModal1}
								user={localUser.email}
								company={company}
								documentToEdit={documentToEdit}
								setDocumentToEdit={setDocumentToEdit}
							/>
						</Modal>

						<Modal isOpen={isOpenModal2} closeModal={closeModal2}>
							<RequestsEditForm
								employeeData={employeeData}
								closeModal2={closeModal2}
								user={localUser.email}
								company={company}
								documentToEdit={documentToEdit}
								setDocumentToEdit={setDocumentToEdit}
							/>
						</Modal>
						{dataBase && dataBase.length > 0 ? (
							<RequestsTable
								data={dataBase}
								adminRequests={adminRequests}
								localUser={localUser}
								openModal2={openModal2}
								setDocumentToEdit={setDocumentToEdit}
							/>
						) : (
							<article className="titulo3">
								No hay Solicitudes para mostrar...
							</article>
						)}
					</div>
				</div>
			)}
		</div>
	);
};
