import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import {
	query,
	where,
	collection,
	getFirestore,
	getDocs,
	updateDoc,
	addDoc,
	doc,
} from "@firebase/firestore";
import {
	getDownloadURL,
	ref,
	uploadBytesResumable,
	deleteObject,
} from "@firebase/storage";
import { firebaseConfig, storage } from "../../../config/firebaseConfig";
import {
	askDataKeyValue,
	updateCompliance,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import Loader from "../../generalComponents/Loader";
import { act } from "@testing-library/react";
import Message from "../../generalComponents/Message";

export default function FormResponseAssignments({
	closeModal1,
	activityToEdit,
	setActivityToEdit,
	company,
}) {
	// console.log("****    al iniciar activityToEdit: ", activityToEdit);
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	// console.log("actividad para editar: ", activityToEdit);
	// const initialForm = {
	// 	response: "",
	// 	comment: "",
	// };
	const [updatedForm, setUpdatedForm] = useState(null);
	// setUpdatedForm(activityToEdit);
	// setUpdatedForm(JSON.parse(JSON.stringify(activityToEdit)));
	const responseType = activityToEdit.responseType;
	const initialForm = activityToEdit;
	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const [closedAnswers, setClosedAnswers] = useState(null);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const [error, setError] = useState(null);
	const [fileToRec, setFileToRec] = useState(null);
	const [storageRef, setStorageRef] = useState(null);
	const [progressLoad, setProgressLoad] = useState(null);

	const askClosedAnswers = async () => {
		setLoading(true);
		const nameCollection = "closedAnswers";
		const key = "responseType";
		const value = responseType; //hacer referencia a activityToEdit.responseType
		const response = await askDataKeyValue(nameCollection, key, value);

		if (response.statusResponse === true) {
			setClosedAnswers(response.data);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (responseType !== "Abierta") {
			askClosedAnswers();
		}
		// Realiza una copia profunda de activityToEdit
		const copiedActivityToEdit = JSON.parse(JSON.stringify(activityToEdit));
		setForm(copiedActivityToEdit);
		setUpdatedForm(copiedActivityToEdit);
	}, [activityToEdit]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (form.response === "" || form.response === "Seleccione una Respuesta") {
			alert("Debe dar una Respuesta...");
			return;
		}
		setLoading(true);
		const fechita = new Date();
		const actualDate = fechita.toLocaleDateString();
		const actualHour = fechita.toLocaleTimeString();

		form.date = actualDate;
		form.hour = actualHour;
		form.userChanges = localUser.email;
		const nameCollection = "activitiesRegister-" + company;
		if (form.id === null) {
			const q = query(
				collection(db, nameCollection),
				where("activity", "==", form.activity),
				where("stage", "==", form.stage),
				where("processNumber", "==", form.processNumber)
			);
			await getDocs(q).then((querySnapshot) => {
				form.id = querySnapshot.docs[0].id;
				console.log("res: ", querySnapshot.docs[0].id);
			});
		}
		const changes = form;
		console.log("va a actualizar con form: ", form);

		await updateDocumentById(nameCollection, form.id, changes);
		console.log(">>>< va a actualizar el cumplimiento....");
		await updateCompliance(changes, company);
		setActivityToEdit(null);
		handleReset();
		setLoading(false);
		setLoading(false);
		closeModal1();
	};

	const handleReset = (e) => {
		setForm(updatedForm);
		// setActivityToEdit(null);
	};

	const handleClose = (e) => {
		console.log("updatedForm: ", updatedForm);

		if (form.nameFile && !form.urlFile) {
			setForm(updatedForm);
		}
		// setForm(activityToEdit);
		setActivityToEdit(null);
		closeModal1();
	};

	const handleSelectFile = (e) => {
		setLoading(true);
		const file = e.target.files[0];
		// console.log("tamaño del archivo: ", file.size);
		setError(null);
		if (file.size > 20000000) {
			// console.log("excede el máximo permitido");
			setError("Tamaño de archivo supera 20 MB ");
			setLoading(false);
			return;
		} else {
			setFileToRec(file);
			setError(null);
		}

		const storageRef = ref(
			storage,
			"resource/" +
				company +
				"/process/" +
				form.processNumber +
				"/" +
				form.id +
				"/" +
				file.name
		);
		setStorageRef(storageRef);
		form["nameFile" + e.target.name] = file.name;
		// console.log("Que hay en form: ", form);
		setLoading(false);
	};

	const LoadFile = async () => {
		setLoading(true);
		const nameCollection = "activitiesRegister-" + company;
		const uploadTask = uploadBytesResumable(storageRef, fileToRec);
		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.floor(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgressLoad(progress);

				switch (snapshot.state) {
					case "paused":
						console.log("Upload is paused");
						break;
					case "running":
						console.log("Upload is running");
						break;
				}
			},
			(error) => {
				console.log(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref)
					.then(async (downloadURL) => {
						form["urlFile"] = downloadURL;
						// form.document = docId;
						if (form.id === null) {
							const q = query(
								collection(db, nameCollection),
								where("activity", "==", form.activity),
								where("stage", "==", form.stage),
								where("processNumber", "==", form.processNumber)
							);
							await getDocs(q).then((querySnapshot) => {
								form.id = querySnapshot.docs[0].id;
								console.log("res: ", querySnapshot.docs[0].id);
							});
						}
						// if (form.id === null) {
						// 	await addDoc(collection(db, nameCollection), form).then(
						// 		async (res) => {
						// 			if (res.id) {
						// 				const docRef = doc(db, nameCollection, res.id);
						// 				await updateDoc(docRef, {
						// 					id: res.id,
						// 				});
						// 			}
						// 		}
						// 	);
						// } else {}
						updateDocumentById(nameCollection, form.id, form);
					})
					.finally(() => {
						setLoading(false); // Establecer loading a false después de finalizar la carga del archivo
					});
			}
		);
	};

	const deleteFile = async () => {
		setLoading(true);
		const nameCollection = "activitiesRegister-" + company;
		const filePath = form["urlFile"];
		let isDelete = window.confirm(`¿Estás seguro de eliminar este Documento?`);
		if (isDelete) {
			setLoading(true);
			setError("");
			const desertRef = ref(storage, filePath);
			deleteObject(desertRef)
				.then(async () => {
					form["nameFile"] = "";
					form["urlFile"] = "";
					await updateDocumentById(nameCollection, form.id, form);
					// console.log("File deleted successfully");
					setLoading(false);
				})
				.catch((error) => {
					setError(error);
					// console.log("Uh-oh, an error occurred!");
					setLoading(false);
				});
		} else {
			setLoading(false);
			return;
		}
		setLoading(false);
	};

	if (activityToEdit) {
		return (
			<div>
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="title2-account">{company}</h2>

					<p className="title2-account">Actividad: {activityToEdit.activity}</p>
					<p className="title2-account">
						Descripción: {activityToEdit.descriptionActivity}
					</p>
					{loading ? (
						<Loader />
					) : (
						<div>
							<label className="titulo1">Respuesta:</label>
							{closedAnswers ? (
								<select
									name="response"
									onChange={handleChange}
									value={form.response}
									className="input0"
								>
									<option defaultValue>Seleccione una Respuesta</option>
									{closedAnswers.map((linea) => (
										<option
											key={linea.responseOption}
											value={linea.responseOption}
										>
											{linea.responseOption}
										</option>
									))}
								</select>
							) : (
								<div>
									<input
										type="text"
										name="response"
										placeholder="Respuesta"
										onChange={handleChange}
										value={form.response}
										className="input0"
									/>
								</div>
							)}
							<input
								type="text"
								name="comment"
								placeholder="Comentario"
								onChange={handleChange}
								value={form.comment}
								className="input0"
							/>

							<table className="tabla-procesos">
								<thead>
									<tr className="fila-tabla-procesos">
										<th className="titleSearchColumn01">Documento</th>
										<th className="titleSearchColumn01">Ver </th>
										<th className="titleSearchColumn01">Subir / Borrar</th>
									</tr>
								</thead>
								<tbody>
									<tr className="fila-tabla-cuenstas">
										<td className="colTableDocuments0">Evidencia</td>
										<td className="colTableDocuments0">
											{form["urlFile"] ? (
												<a
													href={form["urlFile"]}
													target="_blank"
													rel="noreferrer"
												>
													<p>{form["nameFile"]}</p>
													{/* <button className="btnViewDocuments">Ver</button> */}
												</a>
											) : (
												<div></div>
											)}
										</td>

										<td className="colTableDocuments0">
											{form["nameFile"] ? (
												form["urlFile"] ? (
													<div>
														<button
															className="btnDeleteDocuments"
															onClick={() => deleteFile(doc)}
														>
															Borrar
														</button>
													</div>
												) : (
													<button
														className="btnViewDocuments"
														onClick={() => LoadFile(doc)}
													>
														Subir
													</button>
												)
											) : (
												<div className="input-container">
													<label htmlFor="fileInput">Seleccionar Archivo</label>
													<input
														type="file"
														accept=".pdf, .jpg, .jpeg, .png, .mp4"
														// name={doc}
														id="fileInput"
														onChange={handleSelectFile}
													/>
													{error && (
														<Message mensaje={error} bgColor="#dc3545" />
													)}
													{/* <input
														type="file"
														accept="image/*"
														capture="camera"
														id="fileInput"
														onChange={handleSelectFile}
													/> */}
												</div>
											)}
										</td>
									</tr>
								</tbody>
							</table>
							{form["nameFile"] ? (
								form["urlFile"] && (
									<input
										type="submit"
										value="Enviar"
										className="btn-send-reg-asign"
									/>
								)
							) : (
								<input
									type="submit"
									value="Enviar"
									className="btn-send-reg-asign"
								/>
							)}

							{/* <input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/> */}
							<input
								type="reset"
								value="Cerrar"
								onClick={handleClose}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		);
	} else {
		return (
			<div>
				<p>No hay información</p>
			</div>
		);
	}
}
