import React from "react";
import { Link } from "react-router-dom";

export const NovletiesTableRow = ({
	el,
	setDocumentToEdit,
	// setDocumentToDelete,
	openModal1,
	// openModal2,
	deleteDocument,
	setShowNovelties,
}) => {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const buttonAction = (el) => {
		setDocumentToEdit(el);
		setShowNovelties(true);
	};

	const buttonAction1 = (el) => {
		setDocumentToEdit(el);
		openModal1();
	};
	const buttonAction3 = (el) => {
		// console.log(">>>>el:", el);
		// setDocumentToEdit(el);
		// openModal2();
	};

	const buttonAction2 = (el) => {
		// setDocumentToDelete(el);
		deleteDocument(el);
	};

	return (
		<tr className="fila-tabla-cuenstas">
			<td className="colTableDocuments0">{el.dateCreation}</td>
			<td className="colTableDocuments1">{el.employeeName}</td>
			<td className="colTableDocuments1">{el.employeeDocument}</td>
			<td className="colTableDocuments1">{el.noveltyName}</td>
			<td className="colTableDocuments1">{el.dateInitial}</td>
			<td className="colTableDocuments1">{el.dateFinal}</td>

			{localUser.type === "3" && localUser.area === "Recursos Humanos" && (
				<td className="colTableDocuments1">
					{/* <button onClick={() => buttonAction(el)} className="btnEditDocuments">
						Editar
					</button> */}
					<button onClick={() => buttonAction(el)} className="btnViewDocuments">
						Ver
					</button>
				</td>
			)}
			{localUser.type === "3" && localUser.area === "Recursos Humanos" && (
				<td className="colTableDocuments1">
					<button
						onClick={() => buttonAction2(el)}
						className="btnDeleteDocuments"
					>
						Borrar
					</button>
				</td>
			)}
		</tr>
	);
};
