import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	orderBy,
	getDocs,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../config/firebaseConfig";
import { Redirect } from "react-router";
import {
	askDataKeyValue,
	askDataKeyValue2,
	askDataKeyValue3Simple,
	askDataKeyValue4Simple,
} from "../../../utils/actions/firebase/firebaseActions";
import Loader from "../../generalComponents/Loader";
import NavbarBpw2 from "../../generalComponents/NavbarBpw2";
import Chart from "react-google-charts";

export const ViewIndicator = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [error, setError] = useState(null);
	const [goBack, setGoBack] = useState(false);
	const [listProcess, setListProcess] = useState(null);
	const [loading0, setLoading0] = useState(false);
	const [loading, setLoading] = useState(false);
	const [complianceProcess, setComplianceProcess] = useState(null);
	const [listStagesOriginal, setListStagesOriginal] = useState(null);
	const [showStages, setShowStages] = useState(false);
	const [listStagesCompliance, setListStagesCompliance] = useState([]);
	const [stagesComplianceToShow, setStagesComplianceToShow] = useState(null);
	const [listStagesComlianceGraph, setListStagesComlianceGraph] = useState([
		["Etapa", "", { role: "style" }],
	]);
	const [form01, setForm01] = useState(true);
	const [form02, setForm02] = useState(false);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const company = localStorage.getItem("usuariosEmpresa");
	const processToIndicator = localStorage.getItem("processToIndicator");
	const initialDate = localStorage.getItem("initialDate");
	const finalDate = localStorage.getItem("finalDate");
	let datex1 = new Date(initialDate);
	let datex2 = new Date(finalDate);

	const askListProcess = async () => {
		setLoading0(true);
		const q1 = query(
			collection(db, "processRegister-" + company),
			where("process", "==", processToIndicator),
			where("status", "==", "cerrado"),
			where("date", ">=", datex1),
			where("date", "<=", datex2),
			orderBy("date", "asc")
		);

		await getDocs(q1)
			.then((querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setListProcess(dataList);
			})
			.catch((error) => {
				console.log(error);
				setError(error);
			});
		setLoading0(false);
	};

	const askListStages = async () => {
		setLoading(true);
		const nameCollecion = "stages-" + company;
		const key2 = "process";
		const value2 = processToIndicator;
		const response = await askDataKeyValue(nameCollecion, key2, value2);
		if (response.statusResponse === true) {
			const originalSatages = response.data;
			setListStagesCompliance([]);
			setListStagesOriginal(response.data);
			let stagesComplianceSum = 0;
			let stagesQuantity = 0;
			for (const stage of originalSatages) {
				for (const proc of listProcess) {
					const response2 = await askDataKeyValue3Simple(
						"assignments-" + company,
						key2,
						value2,
						"processNumber",
						proc.processNumber,
						"stage",
						stage.stage
					);
					const response3 = response2.data[0];
					stagesComplianceSum = stagesComplianceSum + response3.complianceStage;
					stagesQuantity = stagesQuantity + 1;
				}
				const newStages = {
					stage: stage.stage,
					stageComplianceSum: stagesComplianceSum,
					stageQuantity: stagesQuantity,
				};
				const complianceGraph = (stagesComplianceSum * 100) / stagesQuantity;
				let colorBar = "grey";
				if (complianceGraph < 65) {
					if (complianceGraph < 50) {
						colorBar = "red";
					} else {
						colorBar = "gold";
					}
				} else {
					colorBar = "green";
				}
				listStagesCompliance.push(newStages);
				const newStagesGraph = [stage.stage, complianceGraph, colorBar];
				listStagesComlianceGraph.push(newStagesGraph);
				stagesComplianceSum = 0;
				stagesQuantity = 0;
			}
			setLoading(false);
			setShowStages(true);
		} else {
			setListStagesCompliance(null);
			setLoading(false);
		}

		setStagesComplianceToShow(listStagesCompliance);
	};

	const complianceProcessCalculate = async () => {
		setLoading0(true);
		if (listProcess) {
			let complianceSum = 0;
			listProcess.map(
				(item) => (complianceSum = complianceSum + item.complianceProcess)
			);
			try {
				let calculate = (complianceSum * 100) / listProcess.length;
				setComplianceProcess(calculate);
			} catch (error) {
				setComplianceProcess(0);
			}
		}
		setLoading0(false);
	};

	useEffect(() => {
		askListProcess();
	}, []);

	const goAgain = () => {
		setGoBack(true);
	};

	const showIndicatorsProcess = async () => {
		await complianceProcessCalculate();
		setForm01(false);
		setForm02(true);
	};

	const showIndicatorsStages = async () => {
		await askListStages();
		setForm02(false);
	};

	const options = {
		title: "CUMPLIMIENTO DE LAS ETAPAS",
		chartArea: { width: "50%" },
		hAxis: {
			title: "Cumplimientos [%] ",
			minValue: 0,
		},
		vAxis: {
			title: "Etapas",
		},
	};

	return (
		<div>
			{localUser.type === "2" ? (
				<div>
					<NavbarBpw2 />
					{loading0 ? (
						<Loader />
					) : (
						<div>
							<h3 className="titleProcess">INDICADORES ({company})</h3>

							<h2 className="titulo1">Proceso: {processToIndicator}</h2>
							<h3 className="titulo4">
								Fecha inicial: {initialDate} -------- Fecha final: {finalDate}
							</h3>
							{listProcess && (
								<h3 className="titulo4">
									cantidad de processos en el rango de fecha seleccionado:{"  "}
									{listProcess.length}
								</h3>
							)}
							{form01 && (
								<form>
									<div className="contReturn">
										<div className="contReturn01">
											<label className="titulo4">
												Haga click para ver el cumplimiento del Proceso:{" "}
											</label>
											<input
												type="reset"
												value="Ver"
												onClick={showIndicatorsProcess}
												className="btnViewCompliance"
											/>
										</div>
										<div className="contReturn02"></div>
									</div>
								</form>
							)}

							{complianceProcess && (
								<div>
									<br />
									<div className="contentCompliance01">
										<h2 className="titulo3">
											Cumplimiento del proceso: {Math.round(complianceProcess)}{" "}
											%
										</h2>
									</div>
									{form02 && (
										<form>
											<div className="contReturn">
												<div className="contReturn01">
													<label className="titulo4">
														Haga click para ver el cumplimiento de cada etapa
														del Proceso:{" "}
													</label>
													<input
														type="reset"
														value="Ver"
														onClick={showIndicatorsStages}
														className="btnViewCompliance"
													/>
												</div>
												<div className="contReturn02"></div>
											</div>
										</form>
									)}
								</div>
							)}
							{loading && <Loader />}
							{showStages && stagesComplianceToShow && (
								<div>
									{console.log(
										"en el componente para graficar:",
										listStagesComlianceGraph
									)}

									<Chart
										// chartType="ColumnChart"
										chartType="BarChart"
										width="100%"
										height="400px"
										data={listStagesComlianceGraph}
										options={options}
									/>

									{/* <table className="tabla-procesos">
										<thead>
											<tr className="fila-tabla-procesos">
												<th className="titulo-tabla-procesos2">Etapa</th>
												<th className="titulo-tabla-procesos1">Cumplimiento</th>
											</tr>
										</thead>
										<tbody>
											{stagesComplianceToShow.map((stageCompliance) => (
												<tr className="fila-tabla-cuenstas">
													<td className="col-tabla-procesos2">
														{stageCompliance.stage}
													</td>
													<td className="col-tabla-procesos1">
														{Math.round(
															(stageCompliance.stageComplianceSum * 100) /
																stageCompliance.stageQuantity
														)}
														%
													</td>
												</tr>
											))}
										</tbody>
									</table> */}
								</div>
							)}

							{goBack && <Redirect to="/indicators" />}
						</div>
					)}
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};
