import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../../config/firebaseConfig";
import Loader from "../../../generalComponents/Loader";
import Message from "../../../generalComponents/Message";
import NavbarBpw3 from "../../../generalComponents/NavbarBpw3";
import NavbarBpw4 from "../../../generalComponents/NavbarBpw4";
import NavbarBpw2 from "../../../generalComponents/NavbarBpw2";
import { GeneralInventoryInitial } from "./generalInventoryInitial";

import "../../../styles/Inventory.css";
import { InventoryReceiptApp } from "./inventoryReceipt/inventoryReceiptApp";
import { InventoryShowApp } from "./inventoryShow/inventoryShowApp";
import { InventoryTransferOutApp } from "./inventoryTransferOut/inventoryTransferOutApp";
import { InventoryTransferInApp } from "./inventoryTransferIn/inventoryTransferInApp";
import { InventoriesApp } from "../masterInventory/inventoriesApp";
import { TransformationPermitApp } from "../transformationPermit/transformationPermitApp";
import { ParticularShowApp } from "../particularInventory/particularShow/particularShowApp";
import { ParticularTransferOutApp } from "../particularInventory/particularTransferOut/particularTransferOutApp";
import { ParticularTransferInApp } from "../particularInventory/particularTransferIn/particularTransferInApp";
import { TransformationApp } from "../transformation/transformationApp";
import { InventoryExitApp } from "./inventoryExit/inventoryExitApp";
import { FormulasTransformationApp } from "../formulas/formulasTransformationApp";

export const GeneralInventoryUser3App = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading0, setLoading0] = useState(false);
	const [option, setOption] = useState(null);
	const [permitedGeneral, setPermitedGeneral] = useState(false);
	const [permitedMaster, setPermitedMaster] = useState(false);
	const [permitedTransf, setPermitedTransf] = useState(false);
	const [permitedFormulas, setPermitedFormulas] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const askPermisions = () => {
		setLoading0(true);
		const collectionName = "inventoryPermisions-" + company;

		const q = query(collection(db, collectionName));
		onSnapshot(q, (querySnapshot) => {
			const dataList = [];
			querySnapshot.forEach((doc) => {
				dataList.push(doc.data());
			});
			const email = localUser.email;
			try {
				let generalPermisions = dataList.filter(
					(item) => item.typePermision == "General"
				)[0].inventoryGeneralPermision;
				if (generalPermisions.includes(email)) {
					setPermitedGeneral(true);
				} else {
					setPermitedGeneral(false);
				}
			} catch (error) {}

			try {
				let masterPermisions = dataList.filter(
					(item) => item.typePermision == "Master"
				)[0].inventoryMasterPermision;
				if (masterPermisions.includes(email)) {
					setPermitedMaster(true);
				} else {
					setPermitedMaster(false);
				}
			} catch (error) {}

			try {
				let transfPermisions = dataList.filter(
					(item) => item.typePermision == "Transf"
				)[0].inventoryTransfPermision;
				if (transfPermisions.includes(email)) {
					setPermitedTransf(true);
				} else {
					setPermitedTransf(false);
				}
			} catch (error) {}

			try {
				let formulasPermisions = dataList.filter(
					(item) => item.typePermision == "Formulas"
				)[0].inventoryFormulasPermision;
				if (formulasPermisions.includes(email)) {
					setPermitedFormulas(true);
				} else {
					setPermitedFormulas(false);
				}
			} catch (error) {}

			setLoading0(false);
		});
	};

	useEffect(() => {
		askPermisions();
	}, []);

	const changeButton = (option) => {
		setOption(option);
	};

	return (
		<div>
			{localUser.type === "2" ? (
				<NavbarBpw2 />
			) : localUser.type === "3" ? (
				<NavbarBpw3 />
			) : (
				localUser.type === "4" && <NavbarBpw4 />
			)}

			<article>
				{loading ? (
					<Loader />
				) : (
					<div>
						{error && <Message mensaje={error} bgColor="#dc3545" />}

						<div>
							<div className="containerInventory01">
								<div className="containerInventory02">
									{permitedMaster && (
										<div>
											{option === "Inventario Maestro" ? (
												<button
													className="buttonInventory03	"
													onClick={() => changeButton("Inventario Maestro")}
												>
													Inventario Maestro
												</button>
											) : (
												<button
													className="buttonInventory02	"
													onClick={() => changeButton("Inventario Maestro")}
												>
													Inventario Maestro
												</button>
											)}
										</div>
									)}
									{permitedGeneral && (
										<div>
											{option === "Almacén General" ? (
												<button
													className="buttonInventory03	"
													onClick={() => changeButton("Almacén General")}
												>
													Almacén General
												</button>
											) : (
												<button
													className="buttonInventory02	"
													onClick={() => changeButton("Almacén General")}
												>
													Almacén General
												</button>
											)}
										</div>
									)}

									{permitedGeneral && (
										<div>
											{option === "Ingresos" ? (
												<button
													className="buttonInventory03"
													onClick={() => changeButton("Ingresos")}
												>
													Ingresos
												</button>
											) : (
												<button
													className="buttonInventory02"
													onClick={() => changeButton("Ingresos")}
												>
													Ingresos
												</button>
											)}
										</div>
									)}

									{permitedGeneral && (
										<div>
											{option === "Salidas" ? (
												<button
													className="buttonInventory03"
													onClick={() => changeButton("Salidas")}
												>
													Salidas
												</button>
											) : (
												<button
													className="buttonInventory02"
													onClick={() => changeButton("Salidas")}
												>
													Salidas
												</button>
											)}
										</div>
									)}

									{permitedGeneral && (
										<div>
											{option === "Traslados - Salida" ? (
												<button
													className="buttonInventory03	"
													onClick={() => changeButton("Traslados - Salida")}
												>
													Traslados - Salida
												</button>
											) : (
												<button
													className="buttonInventory02	"
													onClick={() => changeButton("Traslados - Salida")}
												>
													Traslados - Salida
												</button>
											)}
										</div>
									)}

									{permitedGeneral && (
										<div>
											{option === "Traslados - Entrada" ? (
												<button
													className="buttonInventory03	"
													onClick={() => changeButton("Traslados - Entrada")}
												>
													Traslados - Entrada
												</button>
											) : (
												<button
													className="buttonInventory02	"
													onClick={() => changeButton("Traslados - Entrada")}
												>
													Traslados - Entrada
												</button>
											)}
										</div>
									)}

									{permitedTransf && (
										<div>
											{option === "Autorizar Cambios" ? (
												<button
													className="buttonInventory03	"
													onClick={() => changeButton("Autorizar Cambios")}
												>
													Autorizar Cambios
												</button>
											) : (
												<button
													className="buttonInventory02	"
													onClick={() => changeButton("Autorizar Cambios")}
												>
													Autorizar Cambios
												</button>
											)}
										</div>
									)}

									{permitedFormulas && (
										<div>
											{option === "Fórmulas" ? (
												<button
													className="buttonInventory03	"
													onClick={() => changeButton("Fórmulas")}
												>
													Fórmulas
												</button>
											) : (
												<button
													className="buttonInventory02	"
													onClick={() => changeButton("Fórmulas")}
												>
													Fórmulas
												</button>
											)}
										</div>
									)}

									{option === "Inventario" ? (
										<button
											className="buttonInventory03"
											onClick={() => changeButton("Inventario")}
										>
											Inventario Propio
										</button>
									) : (
										<button
											className="buttonInventory02"
											onClick={() => changeButton("Inventario")}
										>
											Inventario Propio
										</button>
									)}

									{option === "Salidas Propias" ? (
										<button
											className="buttonInventory03	"
											onClick={() => changeButton("Salidas Propias")}
										>
											Salidas Propias
										</button>
									) : (
										<button
											className="buttonInventory02	"
											onClick={() => changeButton("Salidas Propias")}
										>
											Salidas Propias
										</button>
									)}
									{option === "Entradas Propias" ? (
										<button
											className="buttonInventory03	"
											onClick={() => changeButton("Entradas Propias")}
										>
											Entradas Propias
										</button>
									) : (
										<button
											className="buttonInventory02	"
											onClick={() => changeButton("Entradas Propias")}
										>
											Entradas Propias
										</button>
									)}

									{option === "Cambio de Inventario" ? (
										<button
											className="buttonInventory03	"
											onClick={() => changeButton("Cambio de Inventario")}
										>
											Cambio de Inventario
										</button>
									) : (
										<button
											className="buttonInventory02	"
											onClick={() => changeButton("Cambio de Inventario")}
										>
											Cambio de Inventario
										</button>
									)}
								</div>

								<div className="containerInventory03">
									{option === null && <GeneralInventoryInitial />}
									{option === "Inventario Maestro" && <InventoriesApp />}
									{option === "Almacén General" && <InventoryShowApp />}
									{option === "Ingresos" && (
										<InventoryReceiptApp setOption={setOption} />
									)}
									{option === "Salidas" && (
										<InventoryExitApp setOption={setOption} />
									)}
									{option === "Traslados - Salida" && (
										<InventoryTransferOutApp />
									)}
									{option === "Traslados - Entrada" && (
										<InventoryTransferInApp />
									)}
									{option === "Autorizar Cambios" && (
										<TransformationPermitApp />
									)}
									{option === "Fórmulas" && <FormulasTransformationApp />}
									{option === "Inventario" && <ParticularShowApp />}
									{option === "Salidas Propias" && <ParticularTransferOutApp />}
									{option === "Entradas Propias" && <ParticularTransferInApp />}
									{option === "Cambio de Inventario" && <TransformationApp />}
								</div>
							</div>
						</div>
					</div>
				)}
			</article>
		</div>
	);
};
