import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	onSnapshot,
	collection,
	getFirestore,
	orderBy,
} from "@firebase/firestore";
import { Redirect } from "react-router";
import { firebaseConfig } from "../../../../config/firebaseConfig";
import { useModal } from "../../../generalComponents/hooks/useModal";
import {
	addDatakey1,
	askDataKeyValue,
	deleteDocumentById,
	updateDocumentById,
} from "../../../../utils/actions/firebase/firebaseActions";
import Modal from "../../../generalComponents/Modal";
import Loader from "../../../generalComponents/Loader";
import Message from "../../../generalComponents/Message";
import { FormulasManagementTable } from "./formulasManagementTable";
import { FormulasManagementForm } from "./formulasManagementForm";

const FormulasManagementApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [dataBase, setDataBase] = useState(null); //listado de Fórmulas de Producción
	const [productionFormulaToEdit, setProductionFormulaToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [modalAvialbe, setModalAvialbe] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const nameCollection = "productionFormulas-" + company;

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	const askProductionFormulas = async () => {
		setLoading(true);
		const q = query(
			collection(db, nameCollection),
			orderBy("creationDate", "desc")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				console.log("error: ", error);
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askProductionFormulas();
	}, []);

	const clickCrear = () => {
		setProductionFormulaToEdit(null);
		setModalAvialbe(true);
		openModal1();
	};

	async function createProductionFormula(data) {
		const date = new Date();
		const actualDate = date.toLocaleDateString();
		data.creationDate = actualDate;
		setLoading(true);
		const response = await addDatakey1(
			nameCollection,
			data,
			"productionFormula",
			data.productionFormula
		);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		await askProductionFormulas();
		setLoading(false);
	}

	async function updateProductionFormula(data) {
		setLoading(true);
		// console.log("data a modificar:", data);
		const docId = data.id;
		const changes = data;
		const key = "productionFormula";
		const value = data.productionFormula;
		// console.log("value2", value2);
		const response = await askDataKeyValue(nameCollection, key, value);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				if (response.data[0].id != docId) {
					setError("Esta Orden ya existe ya existe");
				} else {
					await updateDocumentById(nameCollection, docId, changes);
				}
			}
		}
		// await askAccounts();
		setLoading(false);
	}

	async function deleteProductionFormula(data) {
		const docId = data.id;
		let isDelete = window.confirm(
			`¿Estás seguro de eliminar esta Orden de Producción ?`
		);
		if (isDelete) {
			setLoading(true);
			setError("");
			await deleteDocumentById(nameCollection, docId)
				.then((res) => {
					console.log("respuesta", res);
				})
				.catch((error) => {
					console.log("error", error);
					setError(error);
				});
			setError(null);
			setLoading(false);
		} else {
			return;
		}
	}

	return (
		<div>
			{localUser.type === "2" || localUser.type === "3" ? (
				<div>
					<article>
						<article className="titulo9">
							Formulas de producción ({company})
						</article>
						{modalAvialbe && (
							<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
								<FormulasManagementForm
									company={company}
									createProductionFormula={createProductionFormula}
									updateProductionFormula={updateProductionFormula}
									productionFormulaToEdit={productionFormulaToEdit}
									setProductionFormulaToEdit={setProductionFormulaToEdit}
									closeModal={closeModal1}
								/>
							</Modal>
						)}
						{loading ? (
							<Loader />
						) : (
							<div>
								{error && <Message mensaje={error} bgColor="#dc3545" />}
								{dataBase ? (
									dataBase.length >= 0 ? (
										<div>
											<button
												onClick={clickCrear}
												className="btn-create-document"
											>
												Nueva Fórmula de Producción
											</button>
											<FormulasManagementTable
												data={dataBase}
												setProductionFormulaToEdit={setProductionFormulaToEdit}
												deleteProductionFormula={deleteProductionFormula}
												openModal={openModal1}
												setModalAvialbe={setModalAvialbe}
											/>
										</div>
									) : (
										<h3 className="alertEmpty">
											No hay ProductionFormulaes para mostrar
										</h3>
									)
								) : (
									<h3 className="alertEmpty">Buscando...</h3>
								)}
							</div>
						)}
					</article>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};

export default FormulasManagementApp;
