import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
	askDataKeyValue,
	askDataKeyValue2Simple,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import Loader from "../../generalComponents/Loader";

export const ProductionOrdersTableRow = ({
	el,
	setProductionOrderToEdit,
	openModal,
	deleteProductionOrder,
	setModalAvialbe,
}) => {
	const [loading, setLoading] = useState(false);
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const email = localUser.email;
	const nameCollection = "productionOrders-" + company;

	const editButton = (el) => {
		console.log("el:", el);
		setProductionOrderToEdit(el);
		setModalAvialbe(true);
		openModal();
	};
	const date1 = new Date(el.promiseDate.seconds * 1000).toLocaleDateString();

	const deleteButton = (el) => {
		deleteProductionOrder(el);
	};

	const changeStatus = async (newStatus) => {
		setLoading(true);
		const changes = el;

		if (newStatus === "cerrado") {
			console.log("changes: ", changes);
			const resp1 = await askDataKeyValue2Simple(
				"processRegister-" + company,
				"productionOrder",
				changes.productionOrder,
				"status",
				"abierto"
			);
			console.log("procesos abiertos: ", resp1.data);
			const processes = resp1.data;
			await Promise.all(
				processes.map(async (process) => {
					const resp2 = await askDataKeyValue(
						"assignments-" + company,
						"processNumber",
						process.processNumber
					);
					if (resp2.statusResponse === true) {
						const assignments = resp2.data;
						await Promise.all(
							assignments.map(async (assignment) => {
								//deterner reloj si está corriendo...
								if (assignment.statusActingTime === "si") {
									const fechita = new Date();
									const fechitaMlSeconds = fechita.getTime(); // para pasar a milisegundos
									const eventsTime = assignment.eventsTime;
									const totalEventsTime = eventsTime.length - 1;
									eventsTime[totalEventsTime].f = fechitaMlSeconds; //cambia el valor final de tiempo del evento último
									eventsTime[totalEventsTime].stopCause = "superior";
									eventsTime[totalEventsTime].comment = email;
									const timeMls =
										eventsTime[totalEventsTime].f -
										eventsTime[totalEventsTime].i;
									assignment.statusActingTime = "pausado";
									assignment.eventsTime = eventsTime;
									assignment.totalTime = assignment.totalTime + timeMls;
								}

								assignment.status = newStatus;
								const docId = assignment.id;
								await updateDocumentById(
									"assignments-" + company,
									docId,
									assignment
								);
							})
						);
					} else {
						console.log("error: ", resp2.error);
						setLoading(false);
					}
					process.status = newStatus;
					const docId = process.id;
					updateDocumentById("processRegister-" + company, docId, process);
				})
			);
		}
		changes.status = newStatus;
		const docId = el.id;
		await updateDocumentById(nameCollection, docId, changes);
		setLoading(false);
	};

	const buttonChangeStatus = async () => {
		let newStatus = "";
		if (el.status === "abierto") {
			newStatus = "cerrado";
		} else {
			newStatus = "abierto";
		}
		await changeStatus(newStatus);
	};

	if (loading === true) {
		return <Loader />;
	} else {
		return (
			<tr className="fila-tabla-cuenstas">
				{/* <td className="colTableDocuments0">{el.area}</td> */}
				<td className="colTableDocuments1">{el.productionOrder}</td>
				<td className="colTableDocuments1">{el.client}</td>
				<td className="colTableDocuments1">{el.date1}</td>
				<td className="colTableDocuments1">{date1}</td>
				<td className="colTableDocuments1">{el.status}</td>
				<td className="colTableDocuments2">
					{el.status === "abierto" ? (
						<button
							onClick={() => buttonChangeStatus()}
							className="btnCloseRegprocesos"
						>
							Cerrar
						</button>
					) : (
						<button
							onClick={() => buttonChangeStatus()}
							className="btnOpenRegprocesos"
						>
							Abrir
						</button>
					)}
				</td>

				<td className="colTableDocuments2">
					<button onClick={() => editButton(el)} className="btnEditDocuments">
						Editar
					</button>
					{/* borrar solo se habilita para super administrador */}
					{/* <button
						onClick={() => deleteButton(el)}
						className="btnDeleteDocuments"
					>
						Borrar
					</button> */}

					<Link to={"/production-orders/order/" + el.productionOrder}>
						<button className="btnViewDocuments">Ver</button>
					</Link>
				</td>
			</tr>
		);
	}
};
