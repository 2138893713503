import { initializeApp } from "@firebase/app";
import React, { useState, useEffect } from "react";
import {
	query,
	onSnapshot,
	collection,
	getFirestore,
	where,
} from "@firebase/firestore";
import { ref, deleteObject } from "@firebase/storage";
import {
	deleteDocumentById,
	delFile,
} from "../../../utils/actions/firebase/firebaseActions";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import { useModal } from "../../generalComponents/hooks/useModal";
import Modal from "../../generalComponents/Modal";

import "../../styles/HumanResources.css";
import { firebaseConfig, storage } from "../../../config/firebaseConfig";
import { NoveltiesForm } from "./noveltiesForm";
import { NoveltiesTable } from "./noveltiesTable";
import { SearchNoveltiesApp } from "./searchNoveltiesApp";
import { ShowNoveltiesApp } from "./showNoveltiesApp";

export const NoveltiesApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [dataBase, setDataBase] = useState(null);
	const [listEmployees, setListEmployees] = useState(null);
	const [documentToEdit, setDocumentToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [listNovelties, setListNovelties] = useState(null);
	const [showSearchNovelties, setShowSearchNovelties] = useState(false);
	const [showNovelties, setShowNovelties] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	useEffect(() => {
		askUltimateNovelties();
		askEmployees();
		askNoveltiesList();
	}, []);

	const askUltimateNovelties = () => {
		const fechita = new Date();
		const year = fechita.getFullYear();
		const month = fechita.getMonth() + 1; // Los meses son indexados desde 0, así que sumamos 1
		const day = fechita.getDate();
		const twoDigits = (number) => (number < 10 ? `0${number}` : number);
		const actualDate = `${year}-${twoDigits(month)}-${twoDigits(day)}`;
		const q = query(
			collection(db, "noveltiesEmployees-" + company),
			where("dateCreation", "==", actualDate)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askEmployees = async () => {
		setLoading(true);
		const q = query(collection(db, "employees-" + company));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((doc) => {
					const { document, email, name, status } = doc.data(); // Obtén solo los campos deseados del documento
					return { document, email, name, status, id: doc.id }; // Agrega el ID del documento si es necesario
				});
				setListEmployees(dataList);
				setLoading(false);
			},
			(error) => {
				setListEmployees(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askNoveltiesList = () => {
		const nameCollection = "entitiesRRHH-" + company;
		setLoading(true);
		const q = query(collection(db, nameCollection));
		onSnapshot(q, (querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			setListNovelties(
				convertEntity(dataList.filter((item) => item.type == "NOVEDADES"))
			);
			setLoading(false);
		});
	};

	const convertEntity = (list) => {
		let selectEntity = [];
		list.map((item) => {
			const lineList = {
				value: item.name,
				label: item.name,
			};
			selectEntity.push(lineList);
		});
		return selectEntity;
	};

	const deleteDocument = async (data) => {
		if (data.noveltyName === "Retiro") {
			setError("Los retrios no se pueden eliminar...");
			return;
		}
		const nameCollection = "noveltiesEmployees-" + company;
		const docId = data.id;
		let isDelete = window.confirm(`¿Estás seguro de eliminar este Documento?`);
		if (isDelete) {
			setLoading(true);
			if (data.Doc_Novedad) {
				await delFile(data.Doc_Novedad);
			}
			if (data.Otro_doc_novedad) {
				await delFile(data.Otro_doc_novedad);
			}

			setError("");
			await deleteDocumentById(nameCollection, docId)
				.then((res) => {
					console.log("succesfully erase...");
				})
				.catch((error) => {
					console.log("error", error);
					setError(error);
				});
			setError(null);
			setLoading(false);
		} else {
			return;
		}
	};

	const closeModal = () => {};

	const clickCrear = () => {
		openModal1();
	};

	const clickGoSearch = () => {
		setShowSearchNovelties(true);
	};

	if (showNovelties) {
		return (
			<div>
				<ShowNoveltiesApp
					setShowNovelties={setShowNovelties}
					data={documentToEdit}
					setDocumentToEdit={setDocumentToEdit}
					openModal1={openModal1}
					documentToEdit={documentToEdit}
				/>
				{listEmployees && listNovelties && (
					<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
						<NoveltiesForm
							listEmployees={listEmployees}
							listNovelties={listNovelties}
							closeModal1={closeModal1}
							user={localUser.email}
							company={company}
							documentToEdit={documentToEdit}
							setDocumentToEdit={setDocumentToEdit}
						/>
					</Modal>
				)}
			</div>
		);
	} else {
		return (
			<div>
				<div>
					<article>
						{/* <p>Aquí se gestionan listas de procesos</p> */}
						<h2 className="titleProcess">NOVEDADES</h2>
						{listEmployees && listNovelties && (
							<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
								<NoveltiesForm
									listEmployees={listEmployees}
									listNovelties={listNovelties}
									closeModal1={closeModal1}
									user={localUser.email}
									company={company}
									documentToEdit={documentToEdit}
									setDocumentToEdit={setDocumentToEdit}
								/>
							</Modal>
						)}

						{error && <Message mensaje={error} bgColor="#dc3545" />}

						{loading ? (
							<Loader />
						) : (
							<div>
								{showSearchNovelties ? (
									<div>
										<SearchNoveltiesApp
											setShowSearchNovelties={setShowSearchNovelties}
											listEmployees={listEmployees}
											listNovelties={listNovelties}
											company={company}
											setDocumentToEdit={setDocumentToEdit}
											openModal1={openModal1}
											setShowNovelties={setShowNovelties}
											deleteDocument={deleteDocument}
										/>
									</div>
								) : (
									<div>
										{localUser.type === "3" &&
											localUser.area === "Recursos Humanos" && (
												<button
													onClick={clickCrear}
													className="btn-create-document"
												>
													Crear Novedad
												</button>
											)}
										<button
											onClick={clickGoSearch}
											className="btn-create-document"
										>
											Buscar Novedades
										</button>
										{dataBase && dataBase.length > 0 ? (
											<NoveltiesTable
												documents={dataBase}
												setDocumentToEdit={setDocumentToEdit}
												deleteDocument={deleteDocument}
												openModal1={openModal1}
												setShowNovelties={setShowNovelties}
											/>
										) : (
											<article className="titulo2">
												No hay Novedades hoy...
											</article>
										)}
									</div>
								)}
							</div>
						)}
					</article>
				</div>
			</div>
		);
	}
};
