import React, { useEffect, useState } from "react";
import Loader from "../generalComponents/Loader";
import "../styles/MenuProcesos.css";
import { storage } from "../../config/firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { askDataKeyValue2Simple } from "../../utils/actions/firebase/firebaseActions";
import Message from "../generalComponents/Message";
import ProgressBar from "../generalComponents/ProgressBar";

const CrudDocumentsForm = ({
	dataBase2,
	createDocument,
	updateDocument,
	documentToEdit,
	setDocumentToEdit,
	company,
	closeModal,
	setError,
	isOpenModal1,
}) => {
	const initialForm = {
		id: null,
		name: "",
		description: "",
		version: "",
		date: "",
		area: "",
		resource: "",
		fileName: "",
	};
	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const [areas, setAreas] = useState(dataBase2);
	const [error2, setError2] = useState(null);
	const [progressLoad, setProgressLoad] = useState(-1);
	const [storageRef, setStorageRef] = useState(null);
	const [fileToRec, setFileToRec] = useState(null);

	useEffect(() => {
		if (documentToEdit) {
			setForm(documentToEdit);
		} else {
			setForm(initialForm);
		}
		setAreas(dataBase2);
	}, [documentToEdit, isOpenModal1]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleChangeFile = (e) => {
		//fue una primera versión que ya se ha mejorado
		console.log("recibe e: ", e);
		console.log("recibe e.target.name: ", e.target.name);
		setLoading(true);
		const file = e.target.files[0];
		console.log("tamaño del archivo: ", file.size);

		if (file.size > 20000000) {
			// console.log("excede el máximo permitido");
			setError2("Tamaño de archivo supera 20 MB ");
			setLoading(false);
			return;
		} else {
			setError2(null);
		}

		const storageRef = ref(
			storage,
			"resource/" + company + "/documents/" + file.name
		);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.floor(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgressLoad(progress);

				switch (snapshot.state) {
					case "paused":
						console.log("Upload is paused");
						break;
					case "running":
						console.log("Upload is running");
						break;
				}
			},
			(error) => {
				console.log(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					console.log("File available at", downloadURL);
					setForm({
						...form,
						[e.target.name]: downloadURL,
					});
				});
			}
		);
		setLoading(false);
	};

	const handleSelectFile = (e) => {
		setLoading(true);
		const file = e.target.files[0];
		// console.log("tamaño del archivo: ", file.size);
		if (file.size > 20000000) {
			// console.log("excede el máximo permitido");
			setError("Tamaño de archivo supera 20 MB ");
			setLoading(false);
			return;
		} else {
			setFileToRec(file);
			setError(null);
		}

		const storageRef = ref(
			storage,
			"resource/" + company + "/documents/" + file.name
		);
		setStorageRef(storageRef);
		form.fileName = file.name;

		setLoading(false);
	};

	const loadFile = async () => {
		return new Promise((resolve, reject) => {
			const uploadTask = uploadBytesResumable(storageRef, fileToRec);

			uploadTask.on(
				"state_changed",
				(snapshot) => {
					const progress = Math.floor(
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100
					);
					setProgressLoad(progress);

					switch (snapshot.state) {
						case "paused":
							console.log("Upload is paused");
							break;
						case "running":
							console.log("Upload is running");
							break;
					}
				},
				(error) => {
					console.log(error);
					reject(error);
				},
				async () => {
					try {
						const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
						console.log("Si entra a grabar en resource-documents", downloadURL);
						form.resource = downloadURL;
						resolve(downloadURL);
					} catch (error) {
						console.error("Error al obtener la URL de descarga", error);
						reject(error);
					} finally {
						// Puedes hacer cualquier limpieza aquí si es necesario
					}
				}
			);
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!form.area) {
			alert("Debe seleccionar una area...");

			return;
		}

		if (!form.name) {
			alert("Debe colocar un nombre de documento...");
			return;
		}
		if (!form.description) {
			alert("Debe llenar el campo de descripción del documento...");
			return;
		}
		if (!form.version) {
			alert("Debe llenar el campo de Versión del documento...");
			return;
		}
		if (!form.fileName) {
			alert("Debe seleccionar un archivo para subir..");
			return;
		}

		const nameCollection = "documents-list-" + company;
		const key2 = "area";
		const value2 = form.area;
		const key3 = "name";
		const value3 = form.name;
		const response = await askDataKeyValue2Simple(
			nameCollection,
			key2,
			value2,
			key3,
			value3
		);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				setError("Este Documento ya existe");
			} else {
				setLoading(true);
				try {
					await loadFile();
				} catch (error) {
					setError("Error durante la carga del archivo", error);
					return;
				}
				const fechita = new Date();
				const year = fechita.getFullYear();
				const month = fechita.getMonth() + 1; // Los meses son indexados desde 0, así que sumamos 1
				const day = fechita.getDate();
				const twoDigits = (number) => (number < 10 ? `0${number}` : number);
				const actualDate = `${year}-${twoDigits(month)}-${twoDigits(day)}`;
				form.date = actualDate;

				if (form.id === null) {
					createDocument(form);
				} else {
					updateDocument(form);
				}
			}
		}

		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setDocumentToEdit(null);
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo1">
					{documentToEdit ? "Editar Documento" : "Agregar Nuevo Documento"}
				</h3>
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="title2-account">Empresa: {company}</h2>
					{loading ? (
						<Loader />
					) : (
						<div>
							<label className="labelSelect">Seleccione un Area</label>
							<select
								name="area"
								onChange={handleChange}
								value={form.area}
								className="input1"
							>
								<option defaultValue>Area</option>
								{areas &&
									areas.map((area) => (
										<option key={area.area} value={area.area}>
											{area.area}
										</option>
									))}
							</select>
							<input
								type="text"
								name="name"
								placeholder="Nombre del Documento"
								onChange={handleChange}
								value={form.name}
								className="input0"
							/>
							<input
								type="text"
								name="description"
								placeholder="Descripción del Documento"
								onChange={handleChange}
								value={form.description}
								className="input0"
							/>
							<label>Versión</label>

							<input
								type="text"
								name="version"
								placeholder="Versión"
								onChange={handleChange}
								value={form.version}
								className="input0"
							/>

							{documentToEdit ? (
								<div>{/* <p>{form.resource}</p> */}</div>
							) : (
								<div>
									{form.fileName !== "" ? (
										<label className="titulo5">Archivo: {form.fileName}</label>
									) : (
										<input
											type="file"
											accept=".pdf"
											name="resource"
											id=""
											// value={form.resource}
											onChange={handleSelectFile}
											className="input0"
										/>
									)}
									{(progressLoad > 0) & (progressLoad < 100) && (
										<ProgressBar
											bgcolor={"green"}
											fontcolor={"white"}
											progress={progressLoad}
											height={"30px"}
										/>
									)}
									{progressLoad !== 100 ? (
										progressLoad >= 0 && (
											<p className="loadingFile">Cargando...{progressLoad} %</p>
										)
									) : (
										<p className="loadedFile">Archivo Cargado</p>
									)}
								</div>
							)}

							<hr />
							{error2 && <Message mensaje={error2} bgColor="#dc3545" />}
							<hr />
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={closeModal}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default CrudDocumentsForm;
