import React, { useState } from "react";
import { Link } from "react-router-dom";
import imagen from "./images/logoBPW.png";

export default function NavbarBpw2() {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const ModuleKeyProcess = localStorage.getItem("ModuleKeyProcess");
	const ModuleKeyDocuments = localStorage.getItem("ModuleKeyDocuments");
	const ModuleKeyCapacitation = localStorage.getItem("ModuleKeyCapacitation");
	const ModuleKeyEvaluation = localStorage.getItem("ModuleKeyEvaluation");

	const [visible01, setVisible01] = useState(false);
	const [visibleProduction, setVisibleProduction] = useState(false);
	const [visibleInventroy, setVisibleInventroy] = useState(false);
	const [visibleConfiguration, setVisibleConfiguration] = useState(false);

	function openConfiguration() {
		setVisibleConfiguration(true);
	}
	function closeConfiguration() {
		setVisibleConfiguration(false);
	}

	function openProduction() {
		setVisibleProduction(true);
	}
	function closeProduction() {
		setVisibleProduction(false);
	}
	function openInventory() {
		setVisibleInventroy(true);
	}
	function closeInventrory() {
		setVisibleInventroy(false);
	}
	function openData() {
		setVisible01(true);
	}
	function closeData() {
		setVisible01(false);
	}

	return (
		<div className="navbar">
			<div className="div01Navbar">
				<img src={imagen} className="icono" />
			</div>

			{ModuleKeyProcess === "si" && (
				<div onMouseLeave={closeConfiguration}>
					<div className="linkNavbar" onMouseEnter={openConfiguration}>
						Configurar
					</div>
					{visibleConfiguration === true && (
						<div className="dropdownMenu">
							<div className="div01Navbar01">
								<Link to="/process-list" className="linkNavbar">
									Procesos
								</Link>
							</div>
							<div className="div01Navbar01">
								<Link to="/clients" className="linkNavbar">
									Clientes
								</Link>
							</div>
							<div className="div01Navbar01">
								<Link to="/production-management" className="linkNavbar">
									Producción
								</Link>
							</div>

							<div className="div01Navbar01">
								<Link to="/general-inventory" className="linkNavbar">
									Inventarios
								</Link>
							</div>
							<div className="div01Navbar01">
								<Link to="/time-management" className="linkNavbar">
									Tiempos
								</Link>
							</div>
						</div>
					)}
				</div>
			)}

			{ModuleKeyDocuments === "si" && (
				<div className="div01Navbar01">
					<Link to="/documents" className="linkNavbar">
						Documentos
					</Link>
				</div>
			)}
			{ModuleKeyProcess === "si" && (
				<div onMouseLeave={closeData}>
					<div className="linkNavbar" onMouseEnter={openData}>
						Data
					</div>
					{visible01 === true && (
						<div className="dropdownMenu">
							<div className="div01Navbar01">
								<Link to="/indicators" className="linkNavbar">
									Indicadores
								</Link>
							</div>
							<div className="div01Navbar01">
								<Link to="/DataBase" className="linkNavbar">
									DataBase
								</Link>
							</div>
						</div>
					)}
				</div>
			)}

			{ModuleKeyCapacitation === "si" && (
				<div className="div01Navbar01">
					<Link to="/admin_training_main_company" className="linkNavbar">
						Cursos
					</Link>
				</div>
			)}
			<div className="div01Navbar01">
				<Link to="/admin_user" className="linkNavbar">
					{/* {localUser.email} */}
					Usuario
				</Link>
			</div>
			<div className="div01Navbar01">
				<Link to="/" className="closeButton">
					Cerrar
				</Link>
			</div>
		</div>
	);
}
