import React, { useEffect, useState } from "react";
import CrudRegAssignmentsTableRow from "./CrudRegAssignmentsTableRow";

const CrudRegAssignmentsTable = ({ regAssignments, goSeeDetail, company }) => {
	const [regAssignmentsFilter, setRegAssignmentsFilter] =
		useState(regAssignments);

	useEffect(() => {
		setRegAssignmentsFilter(regAssignments);
	}, [regAssignments]);

	function handleSearch(e) {
		if (e.target.value === "") {
			return setRegAssignmentsFilter(regAssignments);
		}
		setRegAssignmentsFilter(
			regAssignments.filter(
				(item) =>
					item.process.toLowerCase().includes(e.target.value) ||
					item.processNumber.toLowerCase().includes(e.target.value) ||
					item.stage.toLowerCase().includes(e.target.value)
			)
		);
	}

	return (
		<div>
			{regAssignmentsFilter.length > 0 ? (
				<div>
					<h3 className="titulo3">Area: {regAssignments[0].area}</h3>
					<h3 className="titulo3">Sucursal: {regAssignments[0].agency}</h3>
					<h3 className="titulo1">
						Lista de Tareas Asignadas al usuario: {regAssignments[0].assignment}
					</h3>
					<hr className="erraseResponsive" />
				</div>
			) : (
				<h3 className="titulo3">
					No tiene asignaciones en el momento, revise con su supervisor....
				</h3>
			)}
			<div className="document-search">
				<h2 className="title-search">Buscar</h2>
				<input
					type="search"
					name=""
					id=""
					onChange={handleSearch}
					className="search-box"
				/>
			</div>

			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						<th className="titulo-tabla-procesos">Fecha</th>
						<th className="titulo-tabla-procesos"># Proceso</th>
						<th className="titulo-tabla-procesos">Nombre Proceso</th>
						<th className="titulo-tabla-procesos">Nombre Etapa</th>
						<th className="titulo-tabla-procesos">Status</th>
						<th className="titulo-tabla-procesos">Acción</th>
					</tr>
				</thead>
				<tbody>
					{regAssignmentsFilter.length > 0 ? (
						regAssignmentsFilter.map((el) => (
							<CrudRegAssignmentsTableRow
								key={el.id}
								el={el}
								goSeeDetail={goSeeDetail}
								company={company}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default CrudRegAssignmentsTable;
