import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import Loader from "../../generalComponents/Loader";
import {
	addDoc,
	doc,
	updateDoc,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig, storage } from "../../../config/firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import { updateDocumentById } from "../../../utils/actions/firebase/firebaseActions";
import Message from "../../generalComponents/Message";

export const RequestsFrom = ({
	employeeData,
	closeModal1,
	user,
	documentToEdit,
	setDocumentToEdit,
	company,
}) => {
	const initialForm = {
		id: null,
		employeeDocument: employeeData.document,
		employeeName: employeeData.name,
		dateCreation: "",
		userCreation: "",
		userUpdate: "",
		dateUpdate: "",
		Doc_Request: "",
		name_Doc_Request: "",
		request: "",
		commentAproval: "",
		aprovalStatus: "Pendiente",
	};

	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [fileToRec, setFileToRec] = useState(null);
	const [storageRef, setStorageRef] = useState(null);
	const [progressLoad, setProgressLoad] = useState(-1);

	useEffect(() => {
		if (documentToEdit) {
			setForm(documentToEdit);
		} else {
			setForm(initialForm);
		}
	}, [documentToEdit]);

	const obtenerFechaActual = () => {
		const fechaActual = new Date();
		const dia = fechaActual.getDate().toString().padStart(2, "0");
		const mes = (fechaActual.getMonth() + 1).toString().padStart(2, "0"); // Meses en JavaScript van de 0 a 11
		const anio = fechaActual.getFullYear();
		return `${anio}-${mes}-${dia}`;
	};

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSelectFile = (e) => {
		setLoading(true);
		const file = e.target.files[0];
		// console.log("tamaño del archivo: ", file.size);

		if (file.size > 20000000) {
			// console.log("excede el máximo permitido");
			setError("Tamaño de archivo supera 20 MB ");
			setLoading(false);
			return;
		} else {
			setFileToRec(file);
			setError(null);
		}

		if (form.employeeDocument) {
			const storageRef = ref(
				storage,
				"resource/" +
					company +
					"/employees/" +
					form.employeeDocument +
					"/requests/" +
					e.target.name +
					"/" +
					file.name
			);
			setStorageRef(storageRef);
			form.name_Doc_Request = file.name;
		} else {
			alert("debe seleccionar primero un empleado...");
		}

		setLoading(false);
	};

	const loadFile = async () => {
		return new Promise((resolve, reject) => {
			const uploadTask = uploadBytesResumable(storageRef, fileToRec);

			uploadTask.on(
				"state_changed",
				(snapshot) => {
					const progress = Math.floor(
						(snapshot.bytesTransferred / snapshot.totalBytes) * 100
					);
					setProgressLoad(progress);

					switch (snapshot.state) {
						case "paused":
							console.log("Upload is paused");
							break;
						case "running":
							console.log("Upload is running");
							break;
					}
				},
				(error) => {
					console.log(error);
					reject(error);
				},
				async () => {
					try {
						const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
						// console.log("Si entra a grabar doc_novedad", downloadURL);
						form.Doc_Request = downloadURL;
						resolve(downloadURL);
					} catch (error) {
						console.error("Error al obtener la URL de descarga", error);
						reject(error);
					} finally {
						// Puedes hacer cualquier limpieza aquí si es necesario
					}
				}
			);
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!form.employeeName) {
			alert("Debe sleccionar un empleado...");
			return;
		}
		setLoading(true);
		const fechita = new Date();
		// const actualDate = fechita.toLocaleDateString();
		const year = fechita.getFullYear();
		const month = fechita.getMonth() + 1; // Los meses son indexados desde 0, así que sumamos 1
		const day = fechita.getDate();
		const twoDigits = (number) => (number < 10 ? `0${number}` : number);
		const actualDate = `${year}-${twoDigits(month)}-${twoDigits(day)}`;
		const nameCollection = "requestsEmployees-" + company;
		if (form.id === null) {
			if (form.name_Doc_Request) {
				try {
					await loadFile();
				} catch (error) {
					setError("Error durante la carga del archivo", error);
				}
			}
			form.userCreation = user;
			form.dateCreation = actualDate;
			try {
				const res = await addDoc(collection(db, nameCollection), form);
				if (res.id) {
					const docRef = doc(db, nameCollection, res.id);
					await updateDoc(docRef, {
						id: res.id,
					});
				}
			} catch (error) {
				setError("Error al agregar el documento", error);
			}
		} else {
			if (storageRef) {
				await loadFile();
			}
			form.userUpdate = user;
			form.dateUpdate = actualDate;
			console.log(">> va a actualizar form: ", form);
			updateDocumentById(nameCollection, form.id, form);
			setDocumentToEdit(form);
		}
		setLoading(false);
		handleReset();
		closeModal1();
	};

	const handleReset = () => {
		setForm(initialForm);
		setStorageRef(null);
	};

	const closeForm = () => {
		if (form.id != null) {
			setDocumentToEdit(documentToEdit);
		}
		closeModal1();
	};

	const handleSelectList = async (selectedOption, { name }) => {
		const value = selectedOption.value;
		const employe = selectedOption.employe;
		// console.log("Employe: ", employe);
		// console.log("value:", value);
		setForm({
			...form,
			[name]: value,
		});
	};
	const handleSelectEmploye = async (selectedOption) => {
		const employe = selectedOption.employe;
		// console.log("Employe.name: ", employe.name);
		setForm({
			...form,
			employeeDocument: employe.document,
			employeeName: employe.name,
		});
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo1">
					{documentToEdit ? "Editar Solicitud" : "Hacer Solicitud"}
				</h3>
				<form onSubmit={handleSubmit} className="formRH">
					{loading ? (
						<Loader />
					) : (
						<div>
							<div className="containerFormRH">
								<label className="humanLabel0">Empleado *</label>
								<label className="humanInput0">{form.employeeName}</label>
								<br />
								<label className="humanLabel0">Solicitud *</label>
								<input
									type="text"
									name="request"
									placeholder="Solicitud ?"
									onChange={handleChange}
									value={form.request}
									className="humanInput0"
								/>
								<br />
								{form.name_Doc_Request ? (
									<label className="titulo8">
										Archivo a subir: {form.name_Doc_Request}
									</label>
								) : (
									<div className="input-container">
										<label htmlFor="fileInput">Seleccionar Archivo</label>
										<input
											type="file"
											accept=".pdf"
											name={doc}
											id="fileInput"
											onChange={handleSelectFile}
										/>
									</div>
								)}
							</div>

							{error && <Message mensaje={error} bgColor="#dc3545" />}
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							{!documentToEdit && (
								<input
									type="reset"
									value="Limpiar"
									onClick={handleReset}
									className="btn-clean-reg-asign"
								/>
							)}
							<input
								type="reset"
								value="Cerrar"
								onClick={closeForm}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
