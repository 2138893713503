import React, { useEffect, useState } from "react";
import Loader from "../../generalComponents/Loader";
import { askDataKeyValue } from "../../../utils/actions/firebase/firebaseActions";
import { ViewEvaluationApp } from "../viewEvaluation/ViewEvaluationApp";
import { ViewCoursesTable } from "./ViewCoursesTable";

export const ViewCourseApp = ({ selectedCourse, setSelectedCourse }) => {
	const company = localStorage.getItem("usuariosEmpresa");
	const [courseInfo, setCourseInfo] = useState(null);
	const [materialList, setMaterialList] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [onlyEvaluation, setOnlyEvaluation] = useState(false);

	const date1 = new Date(
		selectedCourse.initialDate.seconds * 1000
	).toLocaleDateString();
	const date2 = new Date(
		selectedCourse.finalDate.seconds * 1000
	).toLocaleDateString();

	const askMaterial = async (data, company) => {
		const materialDetails = [];

		data.map(async (material) => {
			const res1 = await askDataKeyValue(
				"coursesMaterial-" + company,

				"name",
				material
			);
			materialDetails.push(res1.data[0]);
			if (materialDetails.length === data.length) {
				setMaterialList(materialDetails);
			}
		});
	};

	const askCourseInformation = async () => {
		setLoading(true);
		const nameCollecion = "coursesList-" + company;
		const key2 = "nameCourse";
		const value2 = selectedCourse.course;
		const response = await askDataKeyValue(nameCollecion, key2, value2);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				setCourseInfo(response.data[0]);
				await askMaterial(response.data[0].materialCourse, company);
			} else {
				const value1Bpw = "Código M SAS";
				const responseBpw = await askDataKeyValue(
					"courseList-Código M SAS",
					key2,
					value2
				);
				if (responseBpw.statusResponse === true) {
					setCourseInfo(responseBpw.data[0]);
					await askMaterial(responseBpw.data[0].materialCourse, value1Bpw);
				} else {
					setCourseInfo(null);
					setError(responseBpw.error);
				}
				setCourseInfo(responseBpw.data[0]);
			}
		} else {
			setCourseInfo(null);
			setError(response.error);
		}
		setLoading(false);
	};

	useEffect(() => {
		askCourseInformation();
	}, [selectedCourse]);

	const buttonAction = () => {
		setSelectedCourse(null);
	};

	return (
		<div>
			{onlyEvaluation ? (
				<hr />
			) : (
				<div>
					<hr />
					<button onClick={() => buttonAction()} className="btnEditDocuments">
						Regresar
					</button>
					<hr />
					<h2 className="titulo7">
						Bienvendio al curso: {selectedCourse.course}
					</h2>
				</div>
			)}
			{loading ? (
				<Loader />
			) : (
				<div>
					{onlyEvaluation ? (
						<hr />
					) : (
						<div>
							{courseInfo && (
								<div>
									<h2 className="titulo6">
										Descripcion del curso: {courseInfo.descriptionCourse}
									</h2>
									<h2 className="titulo2">
										Habilitado desde: {date1} -- hasta: {date2}
									</h2>
									<h2 className="titulo6">
										Por favor seleccione el material del curso que va a
										estudiar, preferiblemente en el orden que aparece:
									</h2>
								</div>
							)}
							<hr />
							{materialList && <ViewCoursesTable materialList={materialList} />}
							<hr />
						</div>
					)}
					{materialList && (
						<ViewEvaluationApp
							courseInfo={courseInfo}
							setSelectedCourse={setSelectedCourse}
							setOnlyEvaluation={setOnlyEvaluation}
							selectedCourse={selectedCourse}
						/>
					)}
				</div>
			)}
		</div>
	);
};
