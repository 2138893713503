import React, { useEffect, useState } from "react";
import Loader from "../../generalComponents/Loader";
import { updateDocumentById } from "../../../utils/actions/firebase/firebaseActions";

export const AsignationFormEdit = ({ courseAsignmentToEdit, closeModal2 }) => {
	const initialForm = {
		id: courseAsignmentToEdit.id,
		assignedBy: courseAsignmentToEdit.assignedBy,
		course: courseAsignmentToEdit.course,
		status: courseAsignmentToEdit.status,
		creationDate: courseAsignmentToEdit.creationDate,
		initialDate: null,
		finalDate: null,
		student: courseAsignmentToEdit.student,
		calification: courseAsignmentToEdit.calification,
		califiactionDAte: courseAsignmentToEdit.califiactionDAte,
		intents: courseAsignmentToEdit.intents,
		maxIntents: courseAsignmentToEdit.maxIntents,
	};
	const company = localStorage.getItem("usuariosEmpresa");
	const [form, setForm] = useState(courseAsignmentToEdit);
	const [users, setUsers] = useState(null); //lista de estudiantes
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setForm(initialForm);
		// form.initialDate = null;
		// form.finalDate = null;
	}, [courseAsignmentToEdit]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleReset = (e) => {
		setForm(initialForm);
		// form.initialDate = null;
		// form.finalDate = null;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (form.initialDate === null) {
			alert("Debe seleccionar una fecha inicial para el Curso");
			return;
		}
		if (form.finalDate === null) {
			alert("Debe seleccionar una fecha final para el Curso");
			return;
		}
		if (form.initialDate > form.finalDate) {
			alert("La fecha final No puede ser anterior a la  Fecha inicial...");
			return;
		}
		setLoading(true);
		const fechita = new Date();
		const actualDate = fechita.toLocaleDateString();
		form.creationDate = actualDate;
		const res = new Date(form.initialDate);
		res.setDate(res.getDate() + 1);
		form.initialDate = res;
		const res2 = new Date(form.finalDate);
		res2.setDate(res2.getDate() + 1);
		form.finalDate = res2;
		form.intents = 0;
		// form.initialDate = new Date(form.initialDate);
		// form.finalDate = new Date(form.finalDate);
		console.log("para actualizar: ", form);
		await updateDocumentById("coursesAsignment-" + company, form.id, form);
		handleReset();
		setLoading(false);
		closeModal2();
	};

	return (
		<div>
			{courseAsignmentToEdit && form && (
				<div>
					<h3 className="titulo1">
						Edición de curso asignado (solo puede cambiar las fechas)
					</h3>
					<form onSubmit={handleSubmit} className="form-procesos">
						<h2 className="title2-account">{company}</h2>
						{loading ? (
							<Loader />
						) : (
							<div>
								<h2 className="titulo3">Usuario asignado: {form.student}</h2>
								<h2 className="titulo3">Curso asignado: {form.course}</h2>
								<h2 className="titulo3">
									<hr />
									Seleccione cuándo se debe realizar el curso
								</h2>

								<label className="title3-account">Fecha Inicial</label>
								<input
									type="date"
									name="initialDate"
									placeholder="Fecha Inicial"
									onChange={handleChange}
									value={form.initialDate}
									className="input1"
								/>
								<label className="title3-account">Fecha Final</label>
								<input
									type="date"
									name="finalDate"
									placeholder="Fecha final"
									onChange={handleChange}
									value={form.finalDate}
									className="input1"
								/>
								<hr />
								<input
									type="submit"
									value="Enviar"
									className="btn-send-reg-asign"
								/>
								<input
									type="reset"
									value="Limpiar"
									onClick={handleReset}
									className="btn-clean-reg-asign"
								/>
								<input
									type="reset"
									value="Cerrar"
									onClick={closeModal2}
									className="btn-close-reg-asign"
								/>
							</div>
						)}
					</form>
				</div>
			)}
		</div>
	);
};
