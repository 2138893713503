import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import Select from "react-select";
import {
	query,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../../../config/firebaseConfig";
import Loader from "../../../../generalComponents/Loader";
import {
	exitInventory,
	receiptInventory,
} from "../../../../../utils/actions/firebase/firebaseActions";
import Message from "../../../../generalComponents/Message";
import { InventoryExitTable } from "./inventoryExitTable";
import { InventoryReceiptTable } from "../inventoryReceipt/inventoryReceiptTable";

export const InventoryExitApp = ({ setOption }) => {
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const initialForm = {
		id: null,
		exitedProducts: [],
		date: null,
		user: localUser.email,
		docNumber: null,
		client: null,
		totalValue: 0,
		type: null,
	};

	const initialExitedProduct = {
		product: null,
		amount: 0,
		unitCost: 0,
		position: 0,
	};

	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [form, setForm] = useState(initialForm);
	const [productToAdd, setProductToAdd] = useState(initialExitedProduct);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [generalInventory, setGeneralInventory] = useState(null);
	const [selectInventory, setSelectInventory] = useState(null);
	const [clientsList, setClientsList] = useState(null);
	const [selectClient, setSelectClient] = useState(null);
	const [totalCost, setTotalCost] = useState(0);

	const askClients = async () => {
		setLoading(true);
		const q = query(collection(db, "clients-" + company));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setClientsList(dataList);
				convertSelectClient(dataList);
				setLoading(false);
			},
			(error) => {
				setClientsList(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askGeneralInventory = async () => {
		setLoading(true);
		const nameCollection = "inventoryGeneral-" + company;
		const q = query(collection(db, nameCollection));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setGeneralInventory(dataList);
				convertSelect(dataList);
				setLoading(false);
			},
			(error) => {
				setGeneralInventory(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askGeneralInventory();
		askClients();
		setProductToAdd(initialExitedProduct);
		setTotalCost(0);
	}, []);

	const convertSelectClient = (clients) => {
		let selectClientProv = [];
		clients.map((client) => {
			const lineClient = {
				value: client.client,
				label: client.client,
				id: client.id,
				target: { name: "client", value: client.client },
			};
			selectClientProv.push(lineClient);
		});
		setSelectClient(selectClientProv);
	};

	const convertSelect = (inventory) => {
		let selectClientProv = [];
		inventory.map((item) => {
			const lineClient = {
				value: item.refInventory,
				label:
					item.refInventory +
					" - " +
					item.description +
					" [" +
					item.amount +
					"]",
				id: item.id,
				measureUnit: item.measureUnit,
				description: item.description,
				generalFeatures: item.features,
				quantity: item.amount,
				unitCost: item.cost,
			};
			selectClientProv.push(lineClient);
		});
		setSelectInventory(selectClientProv);
	};

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (form.exitedProducts.length === 0) {
			alert("No ha ingresado ningún producto...");
			return;
		}
		//
		// let totalInvoice = 0;

		// form.exitedProducts.map((product) => {
		// 	totalInvoice = totalInvoice + product.amount * product.unitCost;
		// });
		const recReceiptInventory = async () => {
			setLoading(true);
			console.log("*** se va a grabar: ", form);
			const response = await exitInventory(form, company);
			if (response.statusResponse) {
				handleReset();
				setOption(null);
				alert("Se actualizó el inventario del Almacén General.....");
			} else {
				setError(response.error);
			}
			setLoading(false);
		};

		recReceiptInventory();
	};

	const handleReset = (e) => {
		setForm(initialForm);
	};

	const handleSelectChange = (e) => {
		setProductToAdd({
			...productToAdd,
			product: e,
			position: form.exitedProducts.length,
			unitCost: e.unitCost,
		});
	};

	const handleChangeProductAdd = (e) => {
		if (e.target.name === "amount") {
			setProductToAdd({
				...productToAdd,
				[e.target.name]: parseInt(e.target.value),
			});
		} else {
			setProductToAdd({
				...productToAdd,
				[e.target.name]: parseFloat(e.target.value),
			});
		}
	};

	const addProduct = () => {
		if (productToAdd.product === null) {
			alert("debe seleccionar un producto...");
			return;
		}
		if (productToAdd.amount === 0) {
			alert("debe colocar una cantidad de producto...");
			return;
		}
		if (productToAdd.amount > productToAdd.product.quantity) {
			alert("No puede sacar más unidades de las que tiene...");
			return;
		}
		let references = [];
		form.exitedProducts.map((product) => {
			references.push(product.product.value);
		});
		if (references.includes(productToAdd.product.value)) {
			alert("No puede repetir producto a sacar...");
			return;
		}

		form.exitedProducts.push(productToAdd);
		let totalCost = 0;
		form.exitedProducts.map((product) => {
			totalCost = totalCost + product.amount * product.product.unitCost;
		});
		setTotalCost(totalCost);

		setProductToAdd(initialExitedProduct);
	};

	const eraseProduct = (index) => {
		setTotalCost(
			totalCost -
				form.exitedProducts[index].amount *
					form.exitedProducts[index].product.unitCost
		);
		if (form.exitedProducts.length === 1) {
			setForm({
				...form,
				exitedProducts: [],
			});
		} else {
			const res = form.exitedProducts.filter((item) => item.position != index);
			setForm({
				...form,
				exitedProducts: res,
			});
		}
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo11">Salida de Almacén</h3>
				{error && <Message />}

				<form onSubmit={handleSubmit} className="form-inventory2">
					{loading ? (
						<Loader />
					) : (
						<div>
							<label className="titulo10">
								Datos de de la Factura, Remisión, ó dar de Baja
							</label>
							<table className="tabla-inventory">
								<thead>
									<tr>
										<th className="tabla-column-etiqueta">Cliente</th>
										<th className="tabla-column-etiqueta">Tipo</th>
										<th className="tabla-column-etiqueta">Número</th>
										<th className="tabla-column-etiqueta">
											Total de la Factura ó Remisión [$]
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="col-tabla-procesos1-">
											<Select
												className="input0"
												options={selectClient}
												name="client"
												onChange={handleChange}
												placeholder="Cliente"
											/>
										</td>
										<select
											name="type"
											onChange={handleChange}
											// value={form.type}
											className="input0"
										>
											<option value="">Tipo</option>
											<option value="Factura">Factura</option>
											<option value="Remisión">Remisión</option>
											<option value="Baja">Baja</option>
										</select>

										<td>
											<input
												required
												type="text"
												name="docNumber"
												placeholder="Documento"
												onChange={handleChange}
												value={form.docNumber}
												className="input0"
											/>
										</td>

										<td>
											<article className="titulo8">$ {totalCost}</article>
										</td>
									</tr>
								</tbody>
							</table>

							<hr className="hrWhite" />
							<label className="titulo10">Productos a Salir</label>
							{form.exitedProducts.length <= 0 ? (
								<article className="titulo11">
									...No hay productos Adicionados...
								</article>
							) : (
								<InventoryReceiptTable
									productsList={form.exitedProducts}
									eraseProduct={eraseProduct}
								/>
							)}
							<hr className="hrWhite" />

							<label className="titulo10">Adicionar productos</label>
							<table className="tabla-inventory">
								<thead>
									<tr>
										<th className="tabla-column-etiqueta">Producto</th>
										<th className="tabla-column-etiqueta">Cantidad</th>

										<th className="tabla-column-etiqueta"></th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td className="col-tabla-procesos1-">
											<Select
												className="input0"
												options={selectInventory}
												name="refProductionProduct"
												onChange={handleSelectChange}
												placeholder="Seleccione un producto"
											/>
										</td>
										<td className="col-tabla-procesos1-">
											<input
												required
												type="number"
												min="0"
												name="amount"
												placeholder="Cantidad"
												onChange={handleChangeProductAdd}
												value={productToAdd.amount}
												className="input0"
											/>
										</td>

										<td>
											<input
												// type="submit"
												value="Adicionar"
												className="btnShowPermisions"
												onClick={addProduct}
											/>
										</td>
									</tr>
								</tbody>
							</table>
							<hr className="hrWhite" />
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
